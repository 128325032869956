<template>
  <div class="docz-component">
    <div class="table-area" v-if="!showNewUserGroupModal">
      <table id="datatable" class="table table-hover non-editable"></table>
    </div>
    <div class="container">
<!--      <user-group-form v-if="selectedUserGroup != null && Object.keys(selectedUserGroup).length !== 0" :selected-user-group="selectedUserGroup" :all-profiles="profilesList" @show-all="showAllUserGroups"></user-group-form>-->
      <new-account-user-group
          v-if="showNewUserGroupModal"
          :account-id="accountId"
          :selected-user-group="selectedUserGroup"
          @closeUserGroupModal="closeUserGroupModal"
          @userGroupCreated="userGroupCreated">
      </new-account-user-group>
    </div>
  </div>

</template>

<script>
import $ from "jquery";
import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import UserGroupForm from "@/components/users/UserGroupForm.vue"
import NewAccountUserGroup from "@/components/usergroups/NewAccountUserGroup.vue"
import ProfileDal from "@/dal/profile_dal";
import UserGroupDal from "@/dal/user-group-dal"
import EntityDal from "@/dal/entity_dal";
import { useLoadStore } from "@/stores/load"
import { useAuthStore} from "@/stores/auth"
import Swal from "sweetalert2"

import UserGroupDashboards from "@/components/usergroups/UserGroupDashboards.vue"
import Vue from "vue";
import el from "vue2-datepicker/locale/es/el";

export default {
  name: "SystemUserGroups",
  components:{
    UserGroupForm,
    NewAccountUserGroup
  },
  props:{
    userGroupId: Number
  },
  data(){
    return{
      storeLoad: useLoadStore(),
      authStore: useAuthStore(),
      allUserGroupsData: null,
      selectedUserGroup: null,
      selectedUserGroupId: 0,//this.userGroupId,
      profilesList: null,
      showNewUserGroupModal : false,
      accountId: null,
      actionMode: ''
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getUserGroups(0)
  },
  methods:{
    getUserGroups(mode){
      if(this.authStore.getUserData.userType === "account-manager"){
        this.accountId = this.authStore.getUserData.accountId
        UserGroupDal.getAccountUserGroups(this.authStore.getUserData.accountId).then( data=>{
          this.createUserGroupsGrid(data, mode)
        })
      }else{
        UserGroupDal.getUserGroupsList().then((data) => {
          this.createUserGroupsGrid(data, mode)
        })
      }
    },

    createUserGroupsGrid(data, mode){
      this.allUserGroupsData = data.userGroups.data
      let dataColumns = []
      let dataRows = []

      if(data.userGroups.columnList){
        data.userGroups.columnList.forEach((title) => {
          dataColumns.push({
            title: title
                .split("_")
                .join(" ")
                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                ),
          });
        });
      }

      dataColumns.push({ title: "" }); // add column for buttons
      data.userGroups.data.forEach((row) => {
        let dataRow = [];
        data.userGroups.columnList.forEach((value) => {
          dataRow.push(row[value]);
        });
        dataRows.push(dataRow);
      });

      let that = this
      if (mode === 1) {
        $("#datatable").DataTable().clear().rows.add(dataRows).draw();
      } else {
        $("#datatable").DataTable({
          data: dataRows,
          columns: dataColumns,
          pageLength: 5,
          stateSave: false,
          columnDefs: [
            {
              targets: -1,
              width: "160px",
              render: function (data, type, row) {

                return that.authStore.getUserData.userType === 'account-manager' ?
                    `<button class="icon-btn grid-btn" value="edit" title="Edit User-Group"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn" value="users" title="Connected Users"><i class='fa fa-users' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn" value="entities" title="Connected Entities"><i class='fas fa-sitemap' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn" value="dashboards" title="Connected Dashboards"><i class='fa fa-bar-chart' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn" value="delete" title="Delete User-Group"><i class='fa fa-trash' style='font-size: 16px; color: #334249'></i></button>  `
                    :
                    `<button class="icon-btn grid-btn" value="edit" title="Edit User-Group"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn" value="users" title="Connected Users"><i class='fa fa-users' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn" value="dashboards" title="Connected Dashboards"><i class='fa fa-bar-chart' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn" value="delete" title="Delete User-Group"><i class='fa fa-trash' style='font-size: 16px; color: #334249'></i></button>  `;
              },
            },
            {
              targets: this.authStore.getUserData.userType === "account-manager" ? [4,5] : [4 , 5, 6],
              visible: false,
              searchable: false,
            }
          ],
          drawCallback: function (settings) {
            that.getProfiles()
            that.initButtons()
          },
          dom: "Blfrtip",
          buttons: [
            "copy",
            "csv",
            "print",
            "excel",
            {
              text: "New User-Group",
              action: function (e, dt, node, config) {

                that.proceedAction("add", 0);
              },
            },
          ],
          bDestroy: true,
        });
      }

      if(this.selectedUserGroupId !== 0){
        this.editUserGroup( this.userGroupId)
      }
      this.storeLoad.setVisible(false)
    },
    initButtons() {

      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(
              element.value,
              event.target.closest("tr").cells[0].innerText
          );
        });
      });
    },

    proceedAction(actionType,userGroupId){

      switch (actionType){
        case "add":
          this.actionMode = 'add'
          this.selectedUserGroup = null
          this.showNewUserGroupModal = true
          break;
        case "delete":
          this.deleteUserGroup(userGroupId)
          break;
        case 'edit':
          this.actionMode = 'edit'
          this.selectedUserGroup = this.allUserGroupsData.find(group => group.id == userGroupId) || {}
          this.showNewUserGroupModal = true

          //this.editUserGroup(userGroupId)
          break;
        case 'entities':
          this.connectEntities(userGroupId)
          break;
        case 'users':
          this.showConnectedUsers(userGroupId)
          break;
        case 'dashboards':
          this.connectDashboards(userGroupId)
          break
        default:
          break;
      }
    },

    editUserGroup(userGroupId) {
      this.selectedUserGroup = this.allUserGroupsData.find(group => group.id == userGroupId)
      this.selectedUserGroup = this.selectedUserGroup ? this.selectedUserGroup : {}
    },

    getProfiles(){
      let that = this
      ProfileDal.getAllProfiles()
          .then((data) => {
            that.profilesList = data.profiles.data.map( profile=> {return {value: profile.id , text: profile.profile_name}})
          })
    },
    connectEntities(userGroupId){
      this.storeLoad.setVisible(true)
      if(this.authStore.getUserData.userType === "account-manager"){
        EntityDal.getConnectedEntities(this.authStore.getUserData.accountId, this.authStore.getUserData.userGroupId, userGroupId)
            .then(data => {
              this.showEntitiesList(data, userGroupId)
            })
      }else{
        EntityDal.getAllEntities(this.authStore.getUserData.userId)
            .then(data => {
              this.showEntitiesList(data, userGroupId)
            })
      }
    },

    showEntitiesList(data, userGroupId){
      let that = this
      let entitiesList = ''
      data.entities.data.forEach(entity => {
        entitiesList += `<label class="swal2-checkbox" style="display: -webkit-inline-box;">
<input style="width:20px" type="checkbox" ${entity.is_connected ? 'checked' : ''} class="entity-cb" value="${entity.id}">
<span class="swal2-label">${entity.entity_name} : ${entity.entity_id}</span>
</label>`
      })
      Swal.fire({
        title: 'Connected Entities :',
        width: '650px',
        html:
            '<label for="document-name" class="swal2-input-label"></label>'+
            `<div  class="list-group connected-users">${entitiesList}</div>`,
        showCancelButton: true,
        willOpen: () => {
          let connectedUsersButtons = document.getElementsByClassName('connected-user')
          Array.prototype.forEach.call(connectedUsersButtons, function(btn) {
            btn.addEventListener('click',(event)=>{
              let userId = event.currentTarget.getAttribute("user_id")
              Swal.clickConfirm()
              that.$emit("openUser", userId);
            })
          });
        },
        preConfirm(inputValue) {
          if(inputValue){
            const entityCbs = Swal.getPopup().getElementsByClassName('entity-cb')
            let connectedEntities = []
            Array.from(entityCbs).forEach(cb => {
              if(cb.checked){
                connectedEntities.push(cb.value)
              }
            })
            EntityDal.connectEntityToBookkeeper(connectedEntities.join('#'), userGroupId)
                .then(data => {
                  if(data.result === 'SUCCESS'){
                    Swal.fire('Entity Connections', 'Connected successfully', 'success')
                  }else{
                    Swal.fire('Entity Connections', 'Failed to connect . Please contact your system administrator', 'error')
                  }
                })
          }
        }
      })
      this.storeLoad.setVisible(false)
    },

    deleteUserGroup(userGroupId){
      let that = this
      Swal.fire({
        title: 'Delete User-Group',
        html: `Please approve to delete the user-group #${userGroupId}`,
        showCancelButton: true,
        showConfirmButton: true
      })
          .then(result => {
            if(result.isConfirmed){
              this.storeLoad.setVisible(true)
              UserGroupDal.deleteUserGroup(userGroupId)
                  .then(data => {
                    this.storeLoad.setVisible(false)

                    if(data.result === 'SUCCESS'){
                      let deletionResult = data.data[0]
                      if(deletionResult.res === 0){
                        Swal.fire(deletionResult.description)
                      }else{
                        Swal.fire(deletionResult.description)
                            .then(() => {
                              that.getUserGroups(1)
                            })
                      }
                    }else{
                      Swal.fire({
                        title: 'User group delete',
                        html: data.message,
                        icon: "error"
                      })
                    }
                  })
            }
          })
    },

    showConnectedUsers(userGroupId){
      this.storeLoad.setVisible(true)
      UserGroupDal.getUsersConnectedToUserGroup(userGroupId).then(data => {
        let that = this
        let usersList = ''
        data.users.data.forEach((user,i) => {
          usersList +=`<button type="button" user_id="${user.id}" class="list-group-item list-group-item-action connected-user">${user.user_name} : ${user.user_description}</button>`
        })
        Swal.fire({
          title: 'Connected Users :',
          width: '650px',
          html:
              '<label for="document-name" class="swal2-input-label"></label>'+
              `<div  class="list-group connected-users">${usersList}</div>`,
          showCancelButton: true,
          willOpen: () => {
            let connectedUsersButtons = document.getElementsByClassName('connected-user')
            Array.prototype.forEach.call(connectedUsersButtons, function(btn) {
              btn.addEventListener('click',(event)=>{
                let userId = event.currentTarget.getAttribute("user_id")
                Swal.clickConfirm()
                that.$emit("openUser", userId);
              })
            });
          }
        })

        this.storeLoad.setVisible(false)
      })
    },



    showAllUserGroups(){
      this.selectedUserGroupId= 0
      this.selectedUserGroup = null
      this.getUserGroups(0)
    },

    userGroupCreated(){
      this.showNewUserGroupModal = false
      this.getUserGroups(0)
    },

    connectDashboards(userGroupId){
      let that = this
      this.storeLoad.setVisible(true)
      let userGroup = this.allUserGroupsData.find(group => group.id == userGroupId)
      let userGroupDashboardsInstance
      UserGroupDal.getUserGroupDashboards(userGroupId)
          .then(data => {
            this.storeLoad.setVisible(false)
            Swal.fire({
              title: userGroup.user_group_name + ' : connect dashboards',
              html: '<div class="connect-dashboards-div"></div>',
              width:800,
              showCancelButton: true,
              didOpen(evt){
                let userGroupDashboards = Vue.extend(
                    Vue.component('UserGroupDashboards', UserGroupDashboards)
                )
                userGroupDashboardsInstance = new userGroupDashboards({propsData: {screens: data.data}})
                userGroupDashboardsInstance.$mount()
                userGroupDashboardsInstance.$on('reopen-dashboards-list', (args)=> {
                  that.connectDashboards(userGroupId)
                })

                document.getElementsByClassName('connect-dashboards-div')[0].appendChild(userGroupDashboardsInstance.$el)
              }
            }).then((result)=> {
              if(result.isConfirmed){
                this.storeLoad.setVisible(true)
                let dashboardConfig = userGroupDashboardsInstance.saveConfiguration()
                UserGroupDal.connectUserGroupDashboards(userGroupId, dashboardConfig)
                    .then(data => {
                      this.storeLoad.setVisible(false)
                      if(data.result === 'SUCCESS'){
                        Swal.fire(userGroup.user_group_name + ' : connect dashboards' , 'Dashboards connected successfully', 'success')
                            .then(()=> {
                              this.getUserGroups(1)
                            })
                      }else{
                        Swal.fire(userGroup.user_group_name + ' : connect dashboards' , 'Dashboards connection failed', 'error')
                      }
                    })
              }

            })
          })
    },

    closeUserGroupModal(){

      this.getUserGroups(0)
      this.showNewUserGroupModal = false
    },

    goBack() {
      this.$emit("goBack");
    }
  },
}
</script>

<style scoped>

</style>