<template>
  <section class="WizardTabs-section">
    <figure class="tab tabs-bar-labels">
      <button v-for="(tab,index) in wizardTabs"
              :ref="'wizard-tab'+tab.id"
              :key="tab.id" class="tablinks"
              :value="tab.id"
              @click="openTab($event,tab.name, index, tab.id)"
      >{{tab.name}}</button>
    </figure>
    <div class="content-tabs-area">
      <div v-for="tab in wizardTabs" class="tabcontent" :key="tab.id" :id="tab.name" :ref="tab.name"></div>
    </div>

<!--    <button @click="getWizardClassificationData">Get Data</button>-->

  </section>
</template>

<script >
import wizardDal from "@/dal/wizard_dal"
import {useWizardStore} from "@/stores/wizard"
import {useLoadStore} from "@/stores/load"

import {componentsManagerClass} from "@/components/docs/WizardElements/Components/componentsManager"
import {useClassificationStore} from "@/stores/classification"

export default {
  name: 'WizardTabs',
  props: {
    existingClassificationData: Array
  },
  data(){
    return {
      wizardTabs: [],
      currentTab : null,
      currentTabIndex: null,
      currentTabId: null,
      wizardStore : useWizardStore(),
      storeLoad: useLoadStore(),
      classificationStore: useClassificationStore(),
      componentsManager : new componentsManagerClass()
    }
  },

  mounted() {
    // this.componentsManager = new componentsManagerClass()
    wizardDal.getWizardTabs().then( data => {
      this.wizardTabs = data.data.map( x => {return {id: x.id , name: x.tab_name}})

    })
  },

  updated() {
      this.goToTab(2)
      if(this.existingClassificationData && this.existingClassificationData.length> 0){
        this.populateTabs()
      }
  },

  methods: {

    getWizardClassificationData(){
      console.log(this.classificationStore.getSelectedRowIds, this.classificationStore.getClassificationData)
    },

    goToTab(tabId){
      this.$refs[this.wizardTabs.find(x => x.id == tabId ).name][0].innerHTML = ''
      this.$refs['wizard-tab' + tabId][0].disabled = false
      this.$refs['wizard-tab' + tabId][0].click()
    },

    openTab(evt, tabName, tabIndex, tabId) {
      let i, tabcontent, tablinks;

      this.classificationStore.clearClassificationData(tabId)

      this.currentTabIndex = tabIndex
      this.currentTabId = tabId

      tabcontent = document.getElementsByClassName("tabcontent") // Get all elements with class="tabcontent" and hide them
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablinks") // Get all elements with class="tablinks" and remove the class "active"
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
        if(i>tabIndex && this.existingClassificationData.length === 0){
           tablinks[i].disabled = true
        }
      }

      document.getElementById(tabName).style.display = "block" // Show the current tab, and add an "active" class to the button that opened the tab
      this.currentTab = document.getElementById(tabName)
      evt.target.className += " active"
      this.$emit('wizard-tab-selected' , {tabId : parseInt(evt.target.value), hasChildren: document.getElementById(tabName).children.length === 0})
    },

    resetTabs(){
      let i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent") // Get all elements with class="tabcontent" and hide them
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].innerHTML = ''
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablinks") // Get all elements with class="tablinks" and remove the class "active"
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
        if(i > 0){
          tablinks[i].disabled = true
        }
      }
    },

    addComponentToTab(component, componentId){
      this.wizardStore.setNextComponentId(parseInt(component.next_component_id))
      let tabsLength = this.wizardTabs.length

      switch (component.component_type) {
        case 'radiobox':
          this.componentsManager.addRadioBoxComponent(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        case 'date-time picker':
          this.componentsManager.addDateTimePicker(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        case 'splitter':
          this.componentsManager.addSplitterComponent(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        case 'select':
          this.componentsManager.addSelectComponent(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        case 'select-with-splitter':
          this.componentsManager.addSelectWithSplitterComponent(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        case 'text-input':
          this.componentsManager.addTextInputComponent(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        case 'period':
          this.componentsManager.addPeriodComponent(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        case 'grid':
          this.componentsManager.addGridComponent(component, this.currentTab, this, component.component_type, componentId, tabsLength)
          break
        default:
          break
      }
    },

    populateTabs(){
      let that = this
      this.existingClassificationData.forEach(component => {
        this.currentTab =  document.getElementById(component.tabName)
        let tabsLength = this.wizardTabs.length

        switch (component.originalComponent.component_type) {
          case 'radiobox':
            this.componentsManager.addRadioBoxComponent(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          case 'date-time picker':
            this.componentsManager.addDateTimePicker(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          case 'splitter':
            this.componentsManager.addSplitterComponent(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          case 'select':
            this.componentsManager.addSelectComponent(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          case 'select-with-splitter':
            this.componentsManager.addSelectWithSplitterComponent(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          case 'text-input':
            this.componentsManager.addTextInputComponent(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          case 'period':
            this.componentsManager.addPeriodComponent(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          case 'grid':
            this.componentsManager.addGridComponent(component.originalComponent, this.currentTab, this, component.componentConfig.componentType, component.componentId, tabsLength, component.componentConfig)
            break
          default:
            break
        }
      })

      let existingTabs = this.existingClassificationData.map(x=> {return x.tabName})
      this.wizardTabs.forEach(tab=> {
          this.$refs["wizard-tab"+tab.id][0].disabled = !existingTabs.includes(tab.name)
      })
    }
  }
}
</script>

<style scoped>

</style>