import {ApiDal} from './api_dal'

class EntityDal extends ApiDal{

    async getAllEntities(createdByUserId){
        return this.callApi('post', '/api/entities/getAllEntities', 'entities', createdByUserId ? {createdByUserId} : {})
    }

    async getAccountCustomers(accountId){
        return this.callApi('post', '/api/entities/getAccountCustomers', 'entities', {accountId})
    }

    async getEntityCustomers(userId , entityId){
        return this.callApi('post', '/api/entities/getEntityCustomers', 'entities', {userId , entityId})
    }

    async getEntitySuppliers(userId , entityId){
        return this.callApi('post', '/api/entities/getEntitySuppliers', 'entities', {userId , entityId})
    }

    async getUserGroupEntities(accountId, userGroupId){
        return this.callApi('post', '/api/entities/getUserGroupEntities', 'entities', {accountId, userGroupId})
    }

    async getAccountEntities(accountId){
        return this.callApi('post', '/api/entities/getAccountEntities', 'entities', {accountId})
    }
    async getConnectedEntities(accountId, accountUserGroupId, bookKeeperUserGroupId){
        return this.callApi('post', '/api/entities/getConnectedEntities', 'entities', {accountId, accountUserGroupId, bookKeeperUserGroupId})
    }

    async connectEntityToBookkeeper(connectedEntities, bookKeeperUserGroupId){
        return this.callApi('post', '/api/entities/connectEntityToBookkeeper', 'entities', {connectedEntities, bookKeeperUserGroupId})
    }

    async getEntityDetails(entityId){
        return this.callApi('post', '/api/entities/getEntityDetails', 'entities', {entityId})
    }

    async updateEntityName(entityId, entityMame, entityNameEn ){
        return this.callApi('post', '/api/entities/updateEntityName', 'entities', {entityId, entityMame, entityNameEn})
    }

    async updateEntity(entity ){
        return this.callApi('post', '/api/entities/updateEntity', 'entities', {entity})
    }

    async checkEntity(vatNumber){
        return this.callApi('post', '/api/entities/checkEntity', 'entity', {vatNumber})
    }

    async addEntity(entityDetails){
        let dataParam = {
            entityId: entityDetails[0],
            entityMame: entityDetails[1],
            entityNameEn: entityDetails[2],
            entityType: entityDetails[3],
            status: entityDetails[4],
            description: entityDetails[5],
            target: entityDetails[6],
            createdDate: entityDetails[7],
            isGovernment: entityDetails[8],
            isLimited: entityDetails[9],
            isViolated: entityDetails[10],
            lastYearlyReport: entityDetails[11],
            cityName: entityDetails[12],
            streetName: entityDetails[13],
            houseNumber: entityDetails[14],
            zipCode: entityDetails[15],
            poBox: entityDetails[16],
            countryName: entityDetails[18],
            via: entityDetails[19],
            subStatus: entityDetails[20],
            entityRank: entityDetails[29],
            accountId: entityDetails[30],
            userId: entityDetails[31],
            userGroupId: entityDetails[32]
        }
        return this.callApi('post', '/api/entities/addEntity', 'entities', dataParam)
    }

    async addEntityIntegrationConf(entityId, integrationDetails){
        return this.callApi('post', '/api/entities/addEntityIntegrationConf', 'entities', {entityId, integrationDetails})
    }

    async getEntityIntegrationConf(entityId){
        return this.callApi('post', '/api/entities/getEntityIntegrationConf', null, {entityId})
    }
}

export default new EntityDal()