import Vue from "vue"
import DateTimePickerComponent from "@/components/docs/WizardElements/Components/DateTimePickerComponent.vue";
import GridComponent from "@/components/docs/WizardElements/Components/GridComponent.vue";
import PeriodComponent from "@/components/docs/WizardElements/Components/PeriodComponent.vue"
import RadioBoxComponent from "@/components/docs/WizardElements/Components/RadioBoxComponent.vue"
import SelectComponent from "@/components/docs/WizardElements/Components/SelectComponent.vue"
import SelectWithSplitterComponent from "@/components/docs/WizardElements/Components/SelectWithSplitterComponent.vue"
import SplitterComponent from "@/components/docs/WizardElements/Components/SplitterComponent.vue"
import TextInputComponent from "@/components/docs/WizardElements/Components/TextInputComponent.vue"

import {useClassificationStore} from "@/stores/classification"


export class componentsManagerClass{
    constructor() {
        this.classificationStore = useClassificationStore()
    }

    addRadioBoxComponent(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let RadioBox = Vue.extend(
            Vue.component('RadioBoxComponent', RadioBoxComponent)
        )
        let radioBoxInstance = new RadioBox({propsData:{title : component.component_name, nextComponentId: parseInt(component.next_component_id),  options : component.component_values.map((x,i)=>{return {id: i ,  name: x}}), existingValue: componentConfig ? componentConfig.data: null}})
        radioBoxInstance.$mount()
        radioBoxInstance.$on('component-value-changed', (args)=> {
            this.removeElements(args.radioBoxListId, currentTab)
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : radioBoxInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })

        this.removePreviousElements(currentTab)
        currentTab.appendChild(radioBoxInstance.$el);
    }

     addDateTimePicker(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let DateTimePicker = Vue.extend(
            Vue.component('DateTimePickerComponent', DateTimePickerComponent)
        )
        let dateTimePickerInstance = new DateTimePicker({propsData: {title:component.component_name, nextComponentId: parseInt(component.next_component_id) , nextButton: component.next_button, existingValue: componentConfig ? componentConfig.data: null}})
        dateTimePickerInstance.$mount()
        dateTimePickerInstance.$on('component-value-changed', (args)=> {
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : dateTimePickerInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })
        this.removePreviousElements(currentTab)

        currentTab.appendChild(dateTimePickerInstance.$el);
    }

     addSplitterComponent(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let Splitter = Vue.extend(
            Vue.component('SplitterComponent', SplitterComponent)
        )
        let splitterInstance = new Splitter({propsData: {title: component.component_name, nextComponentId: parseInt(component.next_component_id), existingValue: componentConfig ? componentConfig.data: null }})
        splitterInstance.$mount()
        splitterInstance.$on('component-value-changed', (args)=> {
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : splitterInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })
        this.removePreviousElements(currentTab)
        currentTab.appendChild(splitterInstance.$el);
    }

     addSelectComponent(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let Select = Vue.extend(
            Vue.component('SelectComponent', SelectComponent)
        )
        let selectInstance = new Select({propsData: {title: component.component_name, nextComponentId: parseInt(component.next_component_id) , nextButton: component.next_button, existingValue: componentConfig ? componentConfig.data: null }})
        selectInstance.$mount()
        selectInstance.$on('component-value-changed', (args)=> {
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : selectInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })
        currentTab.appendChild(selectInstance.$el)
    }

     addSelectWithSplitterComponent(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let SelectWithSplitter = Vue.extend(
            Vue.component('SelectWithSplitterComponent', SelectWithSplitterComponent)
        )
        let selectWithSplitterInstance = new SelectWithSplitter({propsData: {title: component.component_name, nextComponentId: parseInt(component.next_component_id) , currentTabId: currentTab.id, additionalDetails : JSON.parse(component.component_additional_details), existingValue: componentConfig ? componentConfig.data: null }})
        selectWithSplitterInstance.$mount()
        selectWithSplitterInstance.$on('component-value-changed', (args)=> {
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : selectWithSplitterInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })
        this.removePreviousElements(currentTab)
        currentTab.appendChild(selectWithSplitterInstance.$el)
    }

     addTextInputComponent(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let TextInput = Vue.extend(
            Vue.component('TextInputComponent', TextInputComponent)
        )
        let textInputInstance = new TextInput({propsData: {title: component.component_name, nextComponentId: parseInt(component.next_component_id), isLastTab: element.currentTabIndex === tabsLength -1, existingValue: componentConfig ? componentConfig.data: null  }})
        textInputInstance.$mount()
        textInputInstance.$on('component-value-changed', (args)=> {
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : textInputInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })
        this.removePreviousElements(currentTab)
        currentTab.appendChild(textInputInstance.$el)
    }

     addPeriodComponent(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let Period = Vue.extend(
            Vue.component('PeriodComponent', PeriodComponent)
        )
        let periodInstance = new Period({propsData: {title: component.component_name, nextComponentId: parseInt(component.next_component_id), existingValue: componentConfig ? componentConfig.data: null }})
        periodInstance.$mount()
        periodInstance.$on('component-value-changed', (args)=> {
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : periodInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })
        this.removePreviousElements(currentTab)
        currentTab.appendChild(periodInstance.$el)
    }

     addGridComponent(component, currentTab, element, type, componentId, tabsLength, componentConfig){
        let Grid = Vue.extend(
            Vue.component('GridComponent', GridComponent)
        )
        let gridInstance = new Grid({propsData: {title: component.component_name, nextComponentId: parseInt(component.next_component_id) , sourceName: 'v_accrued_expenses',  existingValue: componentConfig ? componentConfig.data: null}})
        gridInstance.$mount()
        gridInstance.$on('component-value-changed', (args)=> {
            this.classificationStore.addClassificationData({tabName: currentTab.id, tabId: element.currentTabId, tabIndex: element.currentTabIndex, componentType: type, componentId: componentId, componentConfig : gridInstance.getConfiguration(), originalComponent: component})
            this.getNextComponent(args, element)
        })
        this.removePreviousElements(currentTab)
        currentTab.appendChild(gridInstance.$el)
    }

     getNextComponent(args, element){
        element.$emit('component-value-changed', {nextComponentId: args.nextComponentId , componentValue: args.value, nextButton : args.nextButton, isLastTab: args.isLastTab})
    }

     removeElements(radioBoxListId, currentTab){
        let tab = document.getElementById(radioBoxListId).parentNode
        let index = 0
        Array.from(tab.children).forEach((el,i)=>{

            if(el.id === radioBoxListId){
                index = i
            }
        })

        let children = currentTab.children;
        for (let i = children.length - 1; i > index; i--) {
            currentTab.removeChild(children[i]);
        }
    }

     removePreviousElements(currentTab){
        /*
        let radioListWrapper = currentTab.getElementsByClassName('radio-list-wrapper')
        if(radioListWrapper.length > 0 ){
            let tab = radioListWrapper[radioListWrapper.length - 1].parentNode
            let lastRadio = 0
            Array.from(tab.children).forEach((el,i)=> {
                if(el.classList.contains('radio-list-wrapper')){
                    lastRadio = i
                }
            })

            let children = tab.children;
            for (let i = children.length - 1; i > lastRadio; i--) {
                // tab.removeChild(children[i]);
            }
        }

         */
    }
}