<template>
  <div style="width: 800px">
    <label>{{title}}</label>
    <table id="class-table" class="table table-hover table-striped non-editable"></table>
    <b-button variant="primary" ref="next-button" @click="next" v-if="!existingValue && selectedRows.length > 0">Next</b-button>
  </div>
</template>

<script>
import DocsDal from '@/dal/docs-dal'
import {useLoadStore} from "@/stores/load"
import $ from "jquery";
export default {
  name: 'GridComponent',
  props: {
    title : String,
    nextComponentId: Number,
    sourceName : String,
    existingValue: Number
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      selectedRows: this.existingValue ? this.existingValue :[],
      gridTable: null
    }
  },
  mounted() {
    let that = this
    this.storeLoad.setVisible(true)
    DocsDal.getClassificationTable(this.sourceName)
        .then(data => {
          this.storeLoad.setVisible(false)
          let dataColumns = data.columnList.map( column => {return {title: column}});
          let dataRows = [];

          data.data.forEach((row) => {
            let dataRow = [];
            data.columnList.forEach((field) => {
              dataRow.push(row[field]);
            });
            dataRows.push(dataRow);
          });

          that.gridTable = $("#class-table").DataTable({
            data: dataRows,
            columns: dataColumns,
            paging: false,
            scrollCollapse: true,
            scrollY: '200px',
            "drawCallback": function( settings ) {
              let table = this.api()
              table.rows().eq(0).each( function ( index ) {
                if(that.selectedRows.includes(index)) {
                  let row = table.row(index).node()
                  row.classList.toggle('selected')
                }
              } );
            }
          })

          that.gridTable.on('click', 'tbody tr', function (e) {
            e.currentTarget.classList.toggle('selected')
            let clickedIndex = that.gridTable.row( this ).index()
            if(that.selectedRows.includes(clickedIndex)){
              let index = that.selectedRows.indexOf(clickedIndex);
              if (index !== -1) {
                that.selectedRows.splice(index, 1);
              }
            }else{
              that.selectedRows.push(clickedIndex)
            }
          });
        })
  },
  methods: {
    next(){
      this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"]})
    },

    getConfiguration(){
      return {data: this.selectedRows}
    }
  }
}

</script>

<style scoped>

</style>