<template>
  <div class="select-area field-area">
    <figure class="select-field field-item">
      <label for="browser" class="select-label">{{title}}</label>
      <input list="elements" name="elements" id="element" class="data-list-input" ref="select-input" v-model="selectedOption">

      <datalist id="elements">
        <option v-for="item in dataOptions" :key="item.id" :value="item.value" :data-value="item.id">{{item.id}}</option>
      </datalist>
    </figure>

    <b-button variant="primary" ref="next-button" class="next-btn" @click="next" v-if="!existingValue && selectedOption != null && nextButton">Next</b-button>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    title : String,
    nextComponentId: Number,
    nextButton: Boolean,
    existingValue: String
  },
  data(){
    return {
      dataOptions: [{id: 0 , value : 'AAA'}, {id: 1, value: 'BBB'}],
      selectedOption: this.existingValue ? this.existingValue : null
    }
  },
  watch: {
    selectedOption: function (newVal, oldVal){
      if(newVal && !this.nextButton){
        this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"]})
      }
    }
  },
  methods: {
    next(){
      this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"]})
    },

    getConfiguration(){
      return {data: this.selectedOption}
    }
  }
}
</script>

<style scoped>
.select-container{
  /*text-align: left;*/
  /*padding: 10px;*/
}

.data-list-input{
  /*width:50%;*/
}

.select-label{
  /*margin-right: 10px;*/
}
</style>