
<template>
  <div ref="test123">
    <input type="text" style="width:80%" class="swal2-input" placeholder="Customer Name" v-model="customer.entity_name" >
    <input type="text" style="width:80%"  class="swal2-input" placeholder="Customer Name ( EN )" v-model="customer.entity_name_en">
    <label v-if="errorMessage" style="color: red">{{errorMessage}}</label>
  </div>
</template>

<script>
export default {
  name: 'EditCustomer',
  props: {
    selectedCustomer: Object
  },
  data() {
    return {
      customer: this.selectedCustomer,
      errorMessage: ''
    }
  },
  watch:{
    "customer.entity_name": function (newVal, oldVal) {
      this.errorMessage = newVal ? '' :  "Customer name can't be empty"
    }
  },
  methods: {
    getCustomer(){
      const customer_name = this.customer.entity_name
      const customer_name_en = this.customer.entity_name_en

      return this.errorMessage ? false : {customer_name, customer_name_en}
    }
  }
}
</script>

<style scoped>

</style>