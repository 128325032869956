<template>
  <div class="docz-component">
    <div class="table-area">
      <select class="bank_accounts" v-model="selectedBankAccount">
        <option v-for="acc in bankAccounts" :value="acc.id" :disabled="acc.id === 0" :selected="acc.id === 0">{{acc.name}}</option>
      </select>
<!--      <p v-if="transactionsData">{{fromDate}} - {{toDate}}</p>-->
      <table id="transactions-table" class="table table-hover non-editable"
      ></table>
    </div>
  </div>
</template>


<script>
import BanksDal from "@/dal/banks_dal"
import GlDal from "@/dal/gl_dal"
import {useLoadStore} from "@/stores/load"
import $ from "jquery"
import Swal from "sweetalert2"

export default {
  name: "BanksTransactions",
  props: {
    entityId: Number
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      fromDate: null,
      toDate: null,
      bankAccounts: [],
      selectedBankAccount: null,
      transactionsData: null,
      glAccounts: null
    }
  },
  mounted(){
    let nowDate = new Date()
    this.toDate = nowDate.getFullYear() + '-' + String(nowDate.getMonth() + 1).padStart(2, '0') + '-' + String(nowDate.getDate()).padStart(2, '0')
    let monthAgoDate = new Date()
    monthAgoDate.setMonth(monthAgoDate.getMonth() - 2)
    this.fromDate =  monthAgoDate.getFullYear() + '-' + String(monthAgoDate.getMonth() + 1).padStart(2, '0') + '-' + String(monthAgoDate.getDate()).padStart(2, '0')
    this.getBankAccounts()
  },

  watch: {
    selectedBankAccount: function (newVal, oldVal) {
      this.getGlAccounts()
    },
  },

  methods: {
    getGlAccounts(){
      this.storeLoad.setVisible(true)
      GlDal.getGlAccountsV2(this.entityId)
          .then(data => {
            this.glAccounts = data.data.map(x => {return {id: x.gl_account_id , name : x.gl_account_name, text: `${x.gl_id} - ${x.gl_account_name}`}})
            this.getAccountTransactions()
          })
    },
    getBankAccounts(){
      this.storeLoad.setVisible(true)
      BanksDal.getEntityBankAccounts(this.entityId)
          .then(data => {
            let accounts = data.data.map(x => {return {id: x.id , name: `${x.account_alias} - ${x.bank_account_id}` }});
            this.bankAccounts=[{id:0 , name: 'Select bank account ...'}].concat(...accounts)
            this.storeLoad.setVisible(false)
          })
    },

    getAccountTransactions(){
      BanksDal.getBankTransactionsData(this.entityId, this.selectedBankAccount, this.fromDate, this.toDate)
          .then(data => {
            this.transactionsData = data.data
            let dataColumns = [];
            let dataRows = [];
            data.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            })

            dataColumns.push({ title: "" });

            this.transactionsData.forEach((row) => {
              let dataRow = [];
              data.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            });



            let that = this;
            $("#transactions-table").DataTable({
              data: dataRows,
              columns: dataColumns,
              paging: false,
              scrollCollapse: true,
              scrollY: '700px',
              // pageLength: 10,
              //stateSave: true,
              "aaSorting": [],
              dom: "Blfrtip",
              columnDefs: [
                {
                  targets: [0,6,8],
                  visible: false,
                  searchable: false,
                },
                {
                  targets: -1,
                  width: "140px",
                  render: function (data, type, row) {
                    return row[8] === 4 ? '' : `<button class="icon-btn edit-btn" value="edit" row_id="${row[0]}"><i class='fa fa-edit' ></i></button>
                            <button class="icon-btn" value="approve" row_id="${row[0]}"><i class='fa fa-check-circle' ></i></button>
                            ${row[8] !== 3 ? '' : `<button class="icon-btn" value="record" row_id="${row[0]}"><i class='fa fa-arrow-circle-right' ></i></button>`}
                            <button class="icon-btn cancel-btn" value="cancel" row_id="${row[0]}" style="display: none"><i class='fa fa-times-circle' ></i></button>`;
                  },
                }
              ],
              drawCallback: function (settings) {
                that.initButtons()
              },
              createdRow: function(row, data, dataIndex) {
                $('td', row).eq(3).css('font-weight','bold')
                //$('td', row).eq(6).css('color', data[6].includes('(')?'#F95D5B':'#0EE25C')
                $('td', row).eq(3).css('text-align','left');//, data[6].includes('(')?'left':'right')
              },
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: `${this.fromDate} - ${this.toDate}`,
                  // className: 'btn btn-success',
                  action: function (e, dt, node, config) {
                    that.proceedAction("change-period")
                  },
                }
              ],
              bDestroy: true,
            });
          })
      this.storeLoad.setVisible(false)
    },

    initButtons(){
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {

        element.addEventListener("click", function (event) {
          let row = $(element).closest('tr')
          that.proceedAction(event.target.parentElement.value , event.target.parentElement.getAttribute('row_id') , row[0]);
        });

      });
    },

    proceedAction(action, rowId, row){
      switch(action){
        case "edit":
          this.editBankTransaction(rowId,row)
          break
        case "cancel":
          this.cancelBankTransaction(rowId,row)
          break;
        case "approve":
          this.approveBankTransaction(rowId, row)
          break
        case "record":
          this.recordBankTransaction(rowId, row)
          break;
        case "change-period":
          this.fetchBankTransactions()
          break;
        default:
          break
      }
    },

    editBankTransaction(rowId, row){
      let that = this
      if(row.getElementsByTagName("td")[5].children.length  === 0){
        const datalist = document.createElement("datalist")
        const datalistId = `datalist-${Date.now()}`
        datalist.id = datalistId;

        this.glAccounts.forEach(item => {
          if (item.id !== undefined && item.text !== undefined) {
            const option = document.createElement("option");
            option.value = item.text // Use text as the visible value
            option.setAttribute("data-id", item.id); // Optionally store the id as data
            datalist.appendChild(option);
          }
        });

        const inputElement = document.createElement("input")
        $(inputElement).css('height', '38px').css('border', '1px solid #cccccc')
        inputElement.setAttribute("list", datalistId)
        inputElement.value = row.getElementsByTagName("td")[5].innerHTML
        inputElement.addEventListener("keyup", function(event) {
          if (event.key === "Enter") {
            if(that.glAccounts.find(x => x.text === this.value)){
              that.approveBankTransaction(rowId, row)
            }
          }
        });
        row.getElementsByTagName("td")[5].setAttribute("original-value", inputElement.value)
        $(row.getElementsByTagName("td")[5]).empty().append(inputElement)
        $(row.getElementsByTagName("td")[5]).append(datalist)
        let btn = row.getElementsByClassName('edit-btn')[0]
        btn.disabled = true
        btn.style.opacity = '0.4'
        btn.style.cursor = 'arrow'

        let cancelButton = row.getElementsByClassName('cancel-btn')[0]
        //cancelButton.style.visibility = 'visible'
        $(cancelButton).show()
      }
    },

    approveBankTransaction(rowId, row){
      let that = this
      if(row.getElementsByTagName("td")[5].childNodes.length < 2){
        Swal.fire('Approve transaction' , 'Please set the Adjacent Gl Account Name value before saving' , 'error');
      }else {
        Swal.fire({
          title: "Adjacent Gl Account Name",
          text: "Please approve to save",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Save"
        }).then((result) => {
          if (result.isConfirmed) {
            let input = row.getElementsByTagName("td")[5].childNodes[0]
            this.storeLoad.setVisible(true)

            BanksDal.updateBankTransactionGlAccountId(rowId, this.glAccounts.find(x => x.text === input.value).id)
                .then(data => {
                  this.storeLoad.setVisible(false)
                  if(data.result === 'SUCCESS'){
                    Swal.fire('Adjacent Gl Account Name', 'Transaction updated successfully', 'success')
                        .then(() => {

                          let cancelButton = row.getElementsByClassName('cancel-btn')[0]
                          //cancelButton.style.visibility = 'hidden'
                          $(cancelButton).hide()

                          row.getElementsByTagName("td")[5].innerHTML = input.value;
                          row.getElementsByTagName("td")[6].innerHTML = data.data[0].transaction_status
                          let btn = row.getElementsByClassName('edit-btn')[0]

                          btn.disabled = false
                          btn.style.opacity = '1'
                          btn.style.cursor = 'hand'

                          if(btn.parentElement.getElementsByTagName('button').length === 3){
                            let button = document.createElement("button");
                            button.className = "icon-btn"
                            button.value = "record"
                            button.setAttribute("row_id", rowId)
                            let icon = document.createElement("i");
                            icon.className = "fa fa-arrow-circle-right"
                            button.appendChild(icon);

                            button.addEventListener("click", function (event) {
                              let row = $(button).closest('tr')
                              that.proceedAction('record' , rowId , row[0]);
                            });
                            btn.parentElement.appendChild(button);
                          }
                        })
                  }else{
                    Swal.fire('Error updating Gl Account Name', 'Update error', 'error')
                  }
                })
          }
        });
      }
    },

    recordBankTransaction(rowId, row){
      if(row.getElementsByTagName("td")[5].childNodes.length >= 2){
        Swal.fire('Approve transaction' , 'Please save the Adjacent Gl Account Name value before recording' , 'error');
      }else {
        Swal.fire({
          title: "Record Transaction",
          text: "Please approve to record the transaction",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Record",
        }).then((result) => {
          if (result.isConfirmed) {
            this.storeLoad.setVisible(true)
            BanksDal.updateBankTransactionStatusId(rowId , 4)
                .then(data => {
                  this.storeLoad.setVisible(false)
                  if(data.result === 'SUCCESS'){
                    Swal.fire('Record Transaction', 'Transaction recorded successfully', 'success')
                        .then(() => {
                          row.getElementsByTagName("td")[6].innerHTML = data.data[0].transaction_status
                          let btn = row.getElementsByClassName('edit-btn')[0]
                          btn.parentElement.innerHTML = ""
                        })
                  }else{
                    Swal.fire('Error recording transaction', 'Record error', 'error')
                  }
                })
          }
        })
      }
    },

    cancelBankTransaction(rowId, row){
      Swal.fire({
        title: 'Cancel the change',
        html: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          row.getElementsByTagName("td")[5].innerHTML = row.getElementsByTagName("td")[5].getAttribute("original-value")
          let btn = row.getElementsByClassName('edit-btn')[0]
          btn.disabled = false
          btn.style.opacity = '1'
          btn.style.cursor = 'hand'
          let cancelButton = row.getElementsByClassName('cancel-btn')[0]
          $(cancelButton).hide()
        }
      })
    },

    fetchBankTransactions(){
      Swal.fire({
        title: 'Select Dates',
        html: `
          <label for="start-date">Start Date:</label>
          <input type="date" id="start-date" class="swal2-input" >
          <label for="end-date">End Date:</label>
          <input type="date" id="end-date" class="swal2-input" value="${this.toDate}">
        `,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Submit',
        preConfirm: () => {
          const startDate = document.getElementById('start-date').value;
          const endDate = document.getElementById('end-date').value;

          if (!startDate || !endDate) {
            Swal.showValidationMessage('Please select both dates');
            return;
          }
          return { startDate, endDate };
        },
        didOpen: () => {
          document.getElementById('start-date').value = this.fromDate
          document.getElementById('end-date').value = this.toDate
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.fromDate = result.value.startDate
          this.toDate = result.value.endDate
          this.storeLoad.setVisible(true)
          this.getAccountTransactions()
        }
      });
    }

  }
}
</script>

<style scoped>
.bank_accounts{
    height: 30px;
    width: 400px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}
</style>