<template>
  <div class="range-area field-area">
    <div class="range-input field-item">
      <label>{{title}}</label>
      <figure class="theRange field-item">
        <label class="box-field">{{rangeValue}}</label>
        <b-form-input type="range" min="0" max="100" v-model="rangeValue" class="range-container" ref="range-input"></b-form-input>
      </figure>
    </div>
    <b-button v-if="!existingValue" variant="primary" ref="next-button" class="next-btn" @click="next" >Next</b-button>
  </div>
</template>

<script>
export default {
  name: 'SplitterComponent',
  props: {
    title : String,
    nextComponentId: Number,
    existingValue: [String,Number]
  },
  data() {
    return {
      rangeValue: this.existingValue ? this.existingValue : 0
    }
  },
  methods: {
    next(){
      this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"]})
    },

    getConfiguration(){
      return {data: this.rangeValue}
    }
  }
}
</script>

<style scoped>
.range-container{
  /*padding: 8px;*/
  /*text-align: left;*/
}

.range-input{
  /*padding: 0px !important;*/
  /*width: 50%;*/
  /*margin-left: 20px;*/
  /*margin-right: 20px;*/
}

.range-label{
  /*width: 50px;*/
  /*padding: 8px;*/
  /*margin-left:20px;*/
  /*border: 1px solid grey;*/
  /*border-radius: 4px;*/
}
</style>