<template>
  <figure class="loading-area">
    <div class="loading"></div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="v-logo">
      <path d="M72.83,1,32.46,59.42h21L95.22,1Zm-68,30.82L25.19,99l13-18-13-49.16Z"/>
    </svg>
  </figure>
</template>

<script>
export default {
    name: "Loading"
}
</script>