import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { createPinia, PiniaVuePlugin } from 'pinia'

import Relogin from "@/Relogin.vue";

const pinia = createPinia()
Vue.use(PiniaVuePlugin)
Vue.use(VueRouter)

Vue.config.productionTip = false
Vue.config.devtools = true

export const eventBus = new Vue()
export const bus = new Vue()

// Define your routes
const routes = [
  { path: '/relogin', component: Relogin }
];

// Create the router instance and pass the `routes` option
const router = new VueRouter({
  mode: 'history', // This removes the hash (#) from the URL
  routes
});




new Vue({
  pinia,
  router,  // Inject the router instance
  render: h => h(App),
}).$mount('#app')


