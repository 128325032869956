<template>
<div>
  <button @click="goBack()" v-if="documentType==='all'">
    <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
  </button>
  <b-card-group fluid v-if="documentType==='all'">
    <menu-entry v-for="item in menuItems"
                :title="item.title"
                :description="item.description"
                :img-name="item.imgName"
                :key="item.id"
                @entryClicked="entryClicked"
    ></menu-entry>
  </b-card-group>

  <documents-management :doc-type="documentType"  v-if="documentType!=='all'" @showDocsMenu="documentType = 'all'"></documents-management>
</div>
</template>

<script>
import DocumentsManagement from "@/components/bookkeepers/DocumentsManagement.vue"
import MenuEntry from "@/components/bookkeepers/MenuEntry.vue"
export default {
  name: "AccountantDocs",
  components: {
    DocumentsManagement,
    MenuEntry
  },
  data() {
    return {
      documentType: 'all',
      menuItems: [
        {id: 0, title: 'Invoices', description: 'Manage Your Invoices', imgName: 'invoice.png'},
        {id: 1, title: 'Receipts', description: 'Manage Your Receipts', imgName: 'receipt.png'},
        {id: 2, title: 'POs', description: 'Manage Your Orders', imgName: 'purchase_order.png'},
        {id: 3, title: 'Credit Invoices', description: 'Manage Credit Invoices', imgName: 'credit_invoice.png'},
        {id: 4, title: 'All Documents', description: 'Open All Documents', imgName: 'all_documents.png'}
      ]
    }
  },
  methods: {
    entryClicked(args){
      this.documentType = args
    },
    goBack(){
      this.$emit('goToBookKeeperMain')
    }
  }
}

</script>

<style scoped>

</style>