<template>
  <figure class="breadcrumb-nav-area">
    <ul class="innerNav">
      <li  v-for="(item,index) in (subMenuItems ? subMenuItems.sub_menus : [])"
           :key="index"
           :class="{currentActiveSection : clickedSubMenuIndex === index}"
      >
        <a @click="subMenuItemClicked(index,item)">{{item.sub_menu_text}}</a>
      </li>
    </ul>
  </figure>
</template>

<script>
export default {
  name: 'InnerNav',
  props: {
    subMenuItems: Object,
    showBreadCrumbs : true
  },
  methods:{
    subMenuItemClicked(index , item){
      this.clickedSubMenuIndex = index
      this.clickedSubMenu = item
    },

    resetSelection(){
      this.clickedSubMenuIndex = null
      this.clickedSubMenu = ''
    }
  },
  data(){

    return {
      subMenus: [],
      clickedSubMenu: '',
      clickedSubMenuIndex : null
    }
  },
  watch: {
    "clickedSubMenu": function( newVal, oldVal) {
      if(newVal !== ''){
        this.$emit('subMenuItemClicked', newVal)
      }

    },
    'subMenuItems': function (newVal, oldVal){
      this.clickedSubMenuIndex = null
      this.clickedSubMenu = {text: '' , data: {name: ''}}
    }
  },
}
</script>

<style scoped>

</style>