<template>
  <div class="Period-area DateTimePicker-area field-area">
    <figure class="DateTimePicker-input field-item">
      <label>{{title}}</label>
      <date-picker v-model="startDate" valueType="format" ref="start-data-picker" @change="calculatePeriod"></date-picker>
    </figure>

    <figure class="DateTimePicker-input field-item">
      <label>End Date</label>
      <date-picker v-model="endDate" valueType="format" ref="end-data-picker" @change="calculatePeriod"></date-picker>
    </figure>

    <figure class="field-item">
      <label>Term (month)</label>
      <label class="box-field">{{rangeValue}}</label>
    </figure>

    <b-button variant="primary" ref="next-button" v-if="endDate && startDate && !existingValue" @click="next" >Next</b-button>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import {YEARFRAC} from '@formulajs/formulajs'

export default {
  name: 'PeriodComponent',
  components: {
    DatePicker
  },
  props: {
    title : String,
    nextComponentId: Number,
    existingValue: Object
  },
  data(){
    return {
      startDate: this.existingValue ? this.existingValue.startDate : null,
      endDate: this.existingValue ? this.existingValue.endDate : null,
      rangeValue: this.existingValue ? this.existingValue.rangeValue :null
    }
  },
  methods: {
    next(){
      this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"]})
    },
    calculatePeriod(){
      if(this.startDate && this.endDate){
        this.rangeValue = Math.round(YEARFRAC(this.startDate, this.endDate, 0)*12 * 100) / 100
      }
    },
    getConfiguration(){
      return {data: {startDate: this.startDate , endDate: this.endDate, rangeValue: this.rangeValue}}
    }
  }

}

</script>


<style scoped>

.picker-div{
  padding: 10px;
}

.data-picker-container{
  text-align: left;
  padding: 10px;
}

.picker-label{
  /*margin-right: 10px;*/
  /*min-width: 100px;*/
}

.range-container{
  /*padding: 8px;*/
  /*text-align: left;*/
}

.range-label{
  /*width: 50px;*/
  /*padding: 8px;*/
  /*margin-left:20px;*/
  /*border: 1px solid grey;*/
  /*border-radius: 4px;*/
}
</style>