<template>

</template>

<script>
import userGroupDal from "@/dal/user-group-dal"
import profilesDal from "@/dal/profile_dal"
import accountsDal from "@/dal/account_dal"
import Swal from "sweetalert2"
import {useLoadStore} from "@/stores/load"
import { useAuthStore } from "@/stores/auth"
export default {
  props: {
    accountId: Number,
    actionMode: String,
    selectedUserGroup: Object
  },
  name: "NewAccountUserGroup",
  data(){
    return {
      storeLoad: useLoadStore(),
      storeAuth: useAuthStore(),
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    if(this.storeAuth.getUserData.userType === "account-manager"){
      let accountsData = {accounts: {data : [{id: this.storeAuth.getUserData.accountId, account_name: this.storeAuth.getUserData.accountName}]} }
      this.getProfiles(accountsData)
    }else{
      accountsDal.getAllAccounts()
          .then(accountsData => {
            this.getProfiles(accountsData)
          })
    }
  },
  methods: {
    getProfiles(accountsData){
      let that = this
      profilesDal.getAllProfiles()
          .then( data => {
            let accountOptions = `<option value="0" disabled selected>Select Account</option>`

            accountsData.accounts.data.forEach(account => {
              accountOptions+= `<option value="${account.id}" ${this.selectedUserGroup && this.selectedUserGroup.account_id === account.id ? 'selected' : ''}>${account.account_name}</option>`
            })

            let options = `<option value="0" disabled selected>Select Profile</option>`

            data.profiles.data.forEach(profile => {
              options += `<option value="${profile.id}" ${this.selectedUserGroup && this.selectedUserGroup.profile_id === profile.id ? 'selected': ''}>${profile.profile_name}</option>`
            })
            Swal.fire({
              title: `${this.actionMode ==='add' ? 'Add New' : 'Edit'} User Group`,
              html: `<label for="user-group-name" style="width: 80%; text-align: left">User Group Name :</label>
<input type="text" id="user-group-name" class="swal2-input" placeholder="User Group Name" style="width: 80%; margin-top: 10px" value="${this.selectedUserGroup ? this.selectedUserGroup.user_group_name: ''}">
<label for="profiles-select" style="width: 80%; text-align: left; margin-top: 20px">Profile Name :</label>
<select id="profiles-select" class="swal2-select" style="width: 80%; margin-top: 10px;">${options}</select>
<label for="accounts-select" style="width: 80%; text-align: left; margin-top: 20px">Account Name :</label>
<select id="accounts-select" class="swal2-select" style="width: 80%; margin-top: 10px;">${accountOptions}</select>`,
              showCancelButton: true,
              confirmButtonText: this.selectedUserGroup ? 'Update' : 'Add',
              didOpen(popup) {
                that.storeLoad.setVisible(false)
                document.getElementById('user-group-name').focus()
              },
              preConfirm: () => {
                const userGroupName = Swal.getPopup().querySelector('#user-group-name').value
                const profileId = Swal.getPopup().querySelector('#profiles-select').value
                const accountId = Swal.getPopup().querySelector('#accounts-select').value
                return { userGroupName, profileId, accountId }
              }
            }).then((result) => {
              if (result.isConfirmed) {
                if(this.selectedUserGroup){
                  userGroupDal.updateAccountUserGroup(this.selectedUserGroup.id, result.value.accountId, result.value.userGroupName, result.value.profileId)
                      .then(data => {
                        if (data.result === 'SUCCESS') {
                          Swal.fire({title: `User-Group  ${result.value.userGroupName} updated successfully`, icon: 'success'})
                              .then(() => {
                                this.$emit('userGroupCreated')
                              })
                        } else {
                          Swal.fire({title: `Failed to update user-group`, icon: 'error'})
                              .then(() => {
                                this.$emit('closeUserGroupModal')
                              })
                        }
                      })
                }else{
                  userGroupDal.addAccountUserGroup(result.value.accountId, result.value.userGroupName, result.value.profileId)
                      .then(data => {
                        if (data.result === 'SUCCESS') {
                          Swal.fire({title: `User-Group  ${result.value.userGroupName} created successfully`, icon: 'success'})
                              .then(() => {
                                this.$emit('userGroupCreated')
                              })
                        } else {
                          Swal.fire({title: `Failed to add new user-group`, icon: 'error'})
                              .then(() => {
                                this.$emit('closeUserGroupModal')
                              })
                        }
                      })
                }

              } else {

                this.storeLoad.setVisible(false)
                this.$emit('closeUserGroupModal')
              }
            })
          })
    }
  }
}
</script>

<style scoped>

</style>