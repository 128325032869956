<template>
  <div class="DateTimePicker-area field-area">
    <figure class="DateTimePicker-input field-item">
      <label>{{title}}</label>
      <date-picker v-model="selectedDate" valueType="format" ref="data-picker"  :lang="en" ></date-picker>
    </figure>

    <b-button variant="primary" ref="next-button" class="next-btn" v-if="!existingValue && selectedDate && nextButton" @click="next" >Next</b-button>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/en'
import en from "vue2-datepicker/locale/es/en";

export default {
  name: 'DateTimePickerComponent',
  computed: {
    en() {
      return en
    }
  },
  props: {
    title : String,
    nextComponentId: Number,
    nextButton: Boolean,
    existingValue: String
  },
  components: {
    DatePicker
  },
  watch: {
    selectedDate:  function (newVal, oldVal) {
      this.selectedDate = newVal

      if(newVal && !this.nextButton){
        this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null})
      }
    }
  },
  data() {
    return {
      selectedDate: this.existingValue ? this.existingValue : null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 7,
        },
      },
    };
  },
  methods: {
    next(){
      let args = {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"]}
      this.$emit('component-value-changed' , args)
    },
    getConfiguration(){
      return {data: this.selectedDate}
    }
  }

}

</script>

<style scoped>
.data-picker-container{
  text-align: left;
  padding: 10px;
}

.picker-label{
  /*margin-right: 10px;*/
}
</style>