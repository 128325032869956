<template>
<!--<div class="container">-->
  <b-card no-body>
    <b-tabs v-model="activeTab" @input="showGlUserGroup">
      <b-tab title="User Level #1" active >
        <b-card-text>
          <g-l-user-group :user-group-id="1" :user-defined-sub-groups="userDefinedSubGroups" :key="tab1Key" :entity-id="entityId" @user-level-groups-changed="userLevelGroupsChanged"></g-l-user-group>
        </b-card-text>
      </b-tab>
      <b-tab title="User Level #2">
        <b-card-text>
          <g-l-user-group :user-group-id="2" :user-defined-sub-groups="userDefinedSubGroups" :key="tab2Key" :entity-id="entityId" @user-level-groups-changed="userLevelGroupsChanged"></g-l-user-group>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
<!--</div>-->
</template>

<script >
import GLUserGroup from "@/components/gl/GLUserGroup.vue"
import GlDal from "@/dal/gl_dal"
import { useLoadStore } from "@/stores/load"

export default {
  name: "SettingsGlUserLevels",
  props: {
    entityId: Number
  },
  computed: {
    entityIdProperty() {
      return this.entityId
    }
  },

  watch:{
    entityIdProperty: {
      immediate: this,
      handler(newVal, oldVal) {
        this.getGlGroups()
      },
      deep: true  // Enable deep watching
    }
  },


  data(){
    return {
      storeLoad: useLoadStore(),
      activeTab: 0, // track the currently active tab
      tab1Key: 0,   // unique key for Tab 1's table
      tab2Key: 1,   // unique key for Tab 2's table
      renderComponent: true,
      userDefinedSubGroups: []
    }
  },
  components: {
    GLUserGroup
  },
  mounted() {
    this.getGlGroups()
  },
  methods: {

    getGlGroups(){
      this.storeLoad.setVisible(true)
      GlDal.getGlUserDefinedSubGroups(this.entityId)
          .then(data => {
            this.userDefinedSubGroups = data.data
            this.storeLoad.setVisible(false)
            this.showGlUserGroup(0)
          })
    },

    async showGlUserGroup(newTab){
      if (newTab === 0) {
        this.tab1Key += 1;  // change key for Tab 1's table
      } else if (newTab === 1) {
        this.tab2Key += 1;  // change key for Tab 2's table
      }
    },

    async userLevelGroupsChanged(args){
      this.userDefinedSubGroups = args
    }
  },

}
</script>

<style scoped>

</style>