<template>
  <b-card  :header="title">
    <template v-slot="img">
<!--      <img class="card-img img-fluid" :src="getImgUrl"/>-->
      <b-button @click="entryClicked" variant="primary">{{description}}</b-button>
    </template>
  </b-card>
</template>

<script>

export default {
  name: 'MenuEntry',
  props: {
    title: String,
    imgName: String,
    description: String
  },
  computed: {
    getImgUrl() {
      return require(`@/assets/images/${this.imgName}`);
    }
  },
  methods:{
    entryClicked(){
      this.$emit('entryClicked', this.title)
    }
  }
}

</script>

<style scoped>
.card-img, .card-img-top, .card-img-bottom {
  width: 25%;
  display: block;
  margin: auto;
  margin-bottom: 5px;
}

.card-header{
  color: white;
  background-color: darkgrey;
}
</style>