import { defineStore } from "pinia"

export const useWizardStore = defineStore({
    id: 'wizardStore',
    state: () => ({
        wizardState :{
            documentType : 1 ,
            lineType: null,
            tabId: null,
            componentId : 1
        }
    }),
    getters:{
        getWizardState:(state) => state.wizardState
    },
    actions: {
        initStore(){
            this.wizardState.lineType = 1
            this.wizardState.componentId = 1
        },
        setLineType(lineType){
            this.wizardState.lineType = lineType
        },

        setTabId(tabeId){
            this.wizardState.tabId = tabeId
        },

        setNextComponentId(componentId){
            this.wizardState.componentId = componentId
        }
    }
})