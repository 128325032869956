<template>
  <div class="docz-component">
    <div class="table-area" v-if="screenMode==='all'">
      <table id="datatable" class="table table-hover non-editable"></table>
    </div>
<!--    <profile-configuration v-if="screenMode==='config'" :selected-profile="selectedProfile" @backToProfiles="backToProfiles"></profile-configuration>-->
    <profile-config-tree v-if="screenMode==='config'" :profile-id="selectedProfile.id" :profile-name="selectedProfile.profile_name" :profile-config="profileConfig" @backToProfiles="backToProfiles"></profile-config-tree>
  </div>
</template>

<script>
import $ from "jquery";
import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import ProfileDal from "@/dal/profile_dal";
import Swal from "sweetalert2";

import {useLoadStore} from "@/stores/load"
import ProfileConfiguration from "@/components/profiles/ProfileConfiguration.vue"
import ProfileConfigTree from "@/components/profiles/ProfileConfigTree.vue"

import * as powerbiClient from 'powerbi-client';
import * as models from 'powerbi-models';
import Vue from "vue";

export default {
  components: {
    ProfileConfiguration,
    ProfileConfigTree
  },
  data(){
    return {
      textValue: '',
      screenMode: 'all',
      storeLoad: useLoadStore(),
      selectedProfile: null,
      allProfilesData: null,
      defaultProfile: [],
      profileConfig: null
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getAllProfiles()
  },
  methods: {
    getAllProfiles(){
      ProfileDal.getAllProfiles()
          .then( data =>{
            this.allProfilesData = data.profiles.data
            let dataColumns = []
            let dataRows = []
            data.profiles.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            });
            dataColumns.push({ title: "" }); // add column for buttons
            data.profiles.data.forEach((row) => {
              let dataRow = [];
              data.profiles.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            });

            let that = this
            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 10,
              columnDefs: [
                {
                  targets: -1,
                  width: "100px",
                  render: function (data, type, row) {
                    return `<button class="icon-btn grid-btn" value="edit"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                            <button class="icon-btn grid-btn" value="config"><i class='fa fa-cog' style='font-size: 16px; color: #334249'></i></button>
                          <button class="icon-btn grid-btn" value="delete"><i class='fa fa-trash' style='font-size: 16px; color: #334249'></i></button>  `;
                  },
                },
                {
                  targets: [3],
                  visible: false,
                  searchable: false,
                }
              ],
              drawCallback: function (settings) {
                that.initButtons()
              },
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: "New Profile",
                  action: function (e, dt, node, config) {
                    that.proceedAction( 'add', 0);
                  },
                },
              ],
              bDestroy: true,
            })
            this.storeLoad.setVisible(false)
          })
    },

    initButtons() {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(
              element.value,
              event.target.closest("tr").cells[0].innerText
          );
        });
      });
    },

    proceedAction(actionType,profileId) {
      this.selectedProfile =  this.allProfilesData.find( profile => profile.id == profileId)
      switch (actionType) {
        case 'edit':
          this.editProfile(profileId)
          break;
        case 'config':
          this.configProfile(profileId)
          break;
        case 'add':
          this.addNewProfile()
          break;
        case "delete":
          this.deleteProfile(profileId)
          break;
        default:
          break;
      }
    },

    addNewProfile(){
      Swal.fire({
        title: 'Create New Profile',
        html: `<input type="text" id="profile-name" class="swal2-input" placeholder="Profile Name">
               <input type="text" id="profile-description" class="swal2-input" placeholder="Profile Description">`,
        confirmButtonText: 'Create',
        showCancelButton: true,
        focusConfirm: false,
        preConfirm: () => {
          const profileName = Swal.getPopup().querySelector('#profile-name').value
          const profileDescription = Swal.getPopup().querySelector('#profile-description').value
          if (!profileName) {
            Swal.showValidationMessage(`Please enter profile name`)
          }
          return { profileName, profileDescription }
        }
      }).then((result) => {
        if(result.isConfirmed){
          this.storeLoad.setVisible(true)
          ProfileDal.addProfile(result.value.profileName, result.value.profileDescription)
              .then(data => {
                this.storeLoad.setVisible(false)
                if(data.result === 'SUCCESS'){
                  Swal.fire('New Profile', 'Created Successfully', 'success')
                      .then(data => {
                        this.storeLoad.setVisible(true)
                        this.getAllProfiles()
                      })
                }else{
                  Swal.fire('New Profile', 'Failed to Create', 'error')
                }
              })
        }
      })
    },

    editProfile(profileId){
      Swal.fire({
        title: 'Edit Profile',
        width: '500px',
        html: `<input type="text" id="profile-name" style="width:80%" class="swal2-input" placeholder="Profile Name" value="${this.selectedProfile.profile_name}" >
                 <input type="text" id="profile-description" style="width:80%"  class="swal2-input" placeholder="Profile Description"  value="${this.selectedProfile.profile_description || ''}">`,
        showCancelButton: true,
        confirmButtonText: 'Update',
        preConfirm: () => {
          const profile_name = Swal.getPopup().querySelector('#profile-name').value
          const profile_description = Swal.getPopup().querySelector('#profile-description').value

          return { profile_name , profile_description }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.storeLoad.setVisible(true)
          ProfileDal.updateProfile(profileId, result.value.profile_name, result.value.profile_description)
              .then(data => {
                this.storeLoad.setVisible(false)
                if(data.result === 'SUCCESS'){
                  Swal.fire({
                    html: 'Profile updated successfully',
                    icon: "success"
                  }).then(()=> {
                    this.storeLoad.setVisible(true)
                    this.getAllProfiles()
                  })
                }else{
                  Swal.fire({
                    html: 'Profile update failed , please contact your system administrator ',
                    icon: "error"
                  })
                }
              })
        }
      })
    },

    configProfile(profileId){
      this.selectedProfile =  this.allProfilesData.find( profile => profile.id == profileId)
      this.storeLoad.setVisible(true)

      ProfileDal.getProfileConfById(profileId)
          .then(data => {
            this.profileConfig = data.profile.data
            this.screenMode = 'config'
            this.storeLoad.setVisible(false)
          })
    },

    deleteProfile(profileId){
      this.selectedProfile =  this.allProfilesData.find( profile => profile.id == profileId)
      Swal.fire({
        title: 'Please approve to delete the profile :',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        html: `<p>${this.selectedProfile.profile_name}</p>`,
        focusConfirm: true,
      }).then( result => {
        if(result.isConfirmed) {
          this.storeLoad.setVisible(true)
          ProfileDal.deleteProfile(profileId)
              .then(data => {
                this.storeLoad.setVisible(false)
                Swal.fire({
                  title: this.selectedProfile.profile_name,
                  text: data.profile.message,
                  icon: data.profile.result ==='SUCCESS'? 'success': 'error'
                }).then(data => {
                  this.storeLoad.setVisible(true)
                  this.getAllProfiles()
                })
              })
        }
      })
    },

    goBack() {
      this.$emit("goBack");
    },

    backToProfiles(){
      this.screenMode = 'all'
      this.getAllProfiles()
    }
  }
}
</script>

<style scoped>

</style>