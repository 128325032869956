<template>
  <div class="docz-component">
    <b-tabs content-class="mt-3">
      <b-tab title="Loaded Files" active>
        <div class="table-area">
          <table id="datatable" class="table table-hover non-editable"
          ></table>
        </div>
      </b-tab>
      <b-tab title="Bank Transactions">
        <banks-transactions :entity-id="entityId" :key="entityId"></banks-transactions>
      </b-tab>
    </b-tabs>


  </div>
</template>

<script>
import BanksTransactions from "@/components/banks/BanksTransactions.vue"
import BanksDal from "@/dal/banks_dal"
import { useLoadStore } from "@/stores/load"
import $ from "jquery"
import Swal from "sweetalert2";
import { useEntityStore} from "@/stores/entity"
import readXlsxFile from "read-excel-file";
import JSZip from "jszip";
import * as XLSX from "@formulajs/formulajs"
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Vue from "vue";
import en from "vue2-datepicker/locale/es/en";

export default {
  name: 'BankDataOnBoarding',
  computed: {
    en() {
      return en
    }
  },
  props: {
    subMenuId: Number,
    entityId: Number
  },
  components: {
    BanksTransactions
  },
  watch: {
    entityId: function (newVal, oldVal) {
      this.getBankUploadedFiles()
    },
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      entityStore: useEntityStore(),
      allFilesData: [],
      selectedFileData: [],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 50,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
        dictDefaultMessage: 'Drag and drop your file here or click to upload' // Custom placeholder text
      }
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getBankUploadedFiles()
  },
  methods: {
    getBankUploadedFiles(){
      this.storeLoad.setVisible(true)
      BanksDal.getBankUploadedFiles(this.entityStore.getCurrentEntity)
      // AccountingDal.getAccountingUploadedFiles(this.entityStore.getCurrentEntity, this.subMenuId)
          .then(data => {
            this.storeLoad.setVisible(false)
            this.allFilesData = data.data
            let dataColumns = [];
            let dataRows = [];
            data.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            })

            dataColumns.push({ title: "" });

            this.allFilesData.forEach((row) => {
              let dataRow = [];
              data.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            });

            let that = this;
            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 5,
              //stateSave: true,
              "aaSorting": [],
              columnDefs: [
                {
                  targets: -1,
                  width: "80px",
                  render: function (data, type, row) {
                    return `<button class="icon-btn"><i class='fa fa-close' ></i></button>`;
                  },
                },
                {
                  targets: [1,3,6,7,11,13,14],
                  visible: false,
                  searchable: false,
                },
              ],
              createdRow: function(row, data, dataIndex) {
                let cellColor
                switch (true){
                  case data[14] < 0:
                    cellColor = '#ff5f5f'
                    break
                  case data[14] === 5:
                    cellColor = '#60bd60'
                    break
                  default:
                    cellColor = '#f5f569'
                    break
                }
                $('td', row).eq(5).css('background-color', cellColor);
              },
              drawCallback: function (settings) {
                that.initButtons()
              },
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: "Upload File",
                  action: function (e, dt, node, config) {
                    that.proceedAction("add", 0);
                  },
                },
                {
                  text: "Refresh Data",
                  action: function (e, dt, node, config) {
                    that.getBankUploadedFiles()
                  },
                },
              ],
              bDestroy: true,
            });
          })
    },

    initButtons(){
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction('delete', event.target.closest("tr").cells[0].innerText)
        });
      });
    },

    proceedAction(actionType, id){
      let that = this

      if(actionType === 'delete'){
        Swal.fire({
          title: 'Are you sure you want to delete the file?',
          confirmButtonText: 'Approve',
          showCancelButton: true,
          showConfirmButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            let selectedFile = that.allFilesData.filter(item => item.id == id)[0]
            that.storeLoad.setVisible(true)

            BanksDal.deleteBankUploadedFile(selectedFile.id,selectedFile.template_destination_table)
                .then(data => {
                  if(data.result === 'SUCCESS'){
                    that.storeLoad.setVisible(false)
                    Swal.fire('File deleted successfully')
                        .then(() => {
                          that.storeLoad.setVisible(true)
                          this.getBankUploadedFiles()
                        })
                  }else{
                    Swal.fire('Failed to delete the file . Please contact your system administrator')
                  }
                })
          }
        })
      }

      if(actionType === 'add'){
        that.storeLoad.setVisible(true)
        that.selectedFileData = []
        BanksDal.getBankEtlTemplates(that.entityStore.getCurrentEntity, that.subMenuId)
            .then( data => {
              let etlOptions = `<option value='' disabled selected>Select template ...</option>`
              let bankAccountOptions = `<option value='' disabled selected>Select bank account ...</option>`
              data.data.forEach(etl => {
                etlOptions += `<option value='${etl.id}' data-rows_in_header='${etl.rows_in_header}' data-bank_accounts='${JSON.stringify(etl.bank_accounts)}' allow_multiple_files='${JSON.stringify(etl.allow_multiple_files)}' >${etl.template_name}</option>`
              })
              that.storeLoad.setVisible(false)

              Swal.fire({
                title: "Select file template",
                customClass: 'swal-wide',
                html: `<select id="etl-options-select" class="swal2-select" style="width: 80%; margin-top: 10px;">${etlOptions}</select>
                        <select id="bank-accounts-options-select" class="swal2-select" style="width: 80%; margin-top: 10px; visibility: hidden" >${bankAccountOptions}</select>
                        <div style="width:100%" id="dropZoneContainer"></div>`,
                showCancelButton: true,
                didOpen(popup) {
                  let accountsEventListenerAdded = false
                  let confirmButton = Swal.getConfirmButton()
                  confirmButton.disabled = true
                  let etlOptionsSelect = Swal.getPopup().querySelector('#etl-options-select')
                  etlOptionsSelect.addEventListener("change", function(event) {
                    let bankAccountsOptionsSelect = Swal.getPopup().querySelector('#bank-accounts-options-select')
                    const rowsInHeader = event.target.options[event.target.selectedIndex].dataset.rows_in_header
                    const bank_accounts = event.target.options[event.target.selectedIndex].dataset.bank_accounts
                    $(bankAccountsOptionsSelect).empty()
                    $(dropZoneContainer).empty()
                    $(bankAccountsOptionsSelect).append(`<option value='' disabled selected>Select bank account ...</option>`)

                    JSON.parse(bank_accounts).forEach(acc => {
                      let opt = document.createElement('option');
                      opt.value = acc.id;
                      opt.innerHTML = `${acc.account_alias} (${acc.bank_account_id})`;
                      bankAccountsOptionsSelect.appendChild(opt);
                    })
                    bankAccountsOptionsSelect.style.visibility = 'visible'

                    if(!accountsEventListenerAdded){
                      accountsEventListenerAdded= true
                      bankAccountsOptionsSelect.addEventListener("change", function(event) {
                        if(bankAccountsOptionsSelect.value != '' && bankAccountsOptionsSelect.value != '0') {
                          let dropZoneContainer = document.getElementById('dropZoneContainer')
                          let DropZone = Vue.extend(
                              Vue.component('vue2Dropzone', vue2Dropzone)
                          )
                          let selectedTemplate = $("#etl-options-select").find(':selected')

                          that.dropzoneOptions.maxFiles = selectedTemplate.attr("allow_multiple_files").toLowerCase() === 'true' ? 100 : 1

                          let dropZoneInstance = new DropZone({propsData: {options: that.dropzoneOptions, id: "dropzone"}})
                          dropZoneInstance.$mount()
                          dropZoneInstance.$on('vdropzone-success', async (file) => {
                            await that.fileAdded(file, rowsInHeader)
                          })

                          dropZoneContainer.appendChild(dropZoneInstance.$el)
                        }
                      })
                    }
                  });

                },
                preConfirm: () => {
                  const selectedEtlId = Swal.getPopup().querySelector('#etl-options-select').value
                  const selectedBankAccountId = Swal.getPopup().querySelector('#bank-accounts-options-select').value
                  if(selectedEtlId === ''){
                    Swal.showValidationMessage('Please select the template');
                  }else if(selectedBankAccountId === ''){
                    Swal.showValidationMessage('Please select the bank account');
                  }else if(that.selectedFileData.length === 0){
                    Swal.showValidationMessage('Please select the file');
                  }else{
                    return { selectedEtlId , selectedBankAccountId}
                  }
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  //console.log(that.selectedFileData)
                  that.uploadBankDataFile(that.selectedFileData, result.value.selectedEtlId, result.value.selectedBankAccountId)
                }
              })
            })
      }
    },

    async fileAdded(file, rowsInHeader) {

      let that = this
      if (that.validateExcelFile(file)) {
        const isValidExcel = await that.isRealExcelFile(file)
        if (isValidExcel) {

          readXlsxFile(file).then((rows) => {

            const isValidExcelContent = that.validateExcelContentRows(rows)
            if (isValidExcelContent) {
              let fileReader = new FileReader()
              let base64

              fileReader.onload = async function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                base64 = base64.replace(`data:${file.type};base64,`, '')

                that.selectedFileData.push({
                  entityId: that.entityStore.getCurrentEntity,
                  fileName: file.name,
                  fileType: file.type,
                  fileData: base64
                })

                Swal.enableButtons()
              }
              fileReader.readAsDataURL(file)
            } else {
              $("#errorMessage").html('Invalid file content')
            }
            that.storeLoad.setVisible(false)
          })
        }else{
          Swal.fire('Upload error', 'Unsupported Excel file', "error")
        }
      } else if (that.validateCSVFile(file)) {

        const isValidCsv = await that.isRealCSVFile(file)
        if (isValidCsv) {
          const isValidCsvContent = await that.validateCSVContent(file)
          if (isValidCsvContent) {
            const fileReader = new FileReader();

            fileReader.onload = async function (fileLoadedEvent) {
              let fileContent = await file.text();
              if(rowsInHeader > 0){
                const lines = fileContent.split('\n');
                const filteredRows = lines.slice(rowsInHeader);
                fileContent = filteredRows.join('\n');
              }

              const encoder = new TextEncoder();
              const encodedContent = encoder.encode(fileContent);
              const base64Content = that.arrayBufferToBase64(encodedContent);

              that.selectedFileData.push({
                entityId: that.entityStore.getCurrentEntity,
                fileName: file.name,
                fileType: file.type,
                fileData: base64Content
              })

              Swal.enableButtons()
            }
            fileReader.readAsDataURL(file)
          }
        }
      } else{
        Swal.fire('Upload error', 'Unsupported CSV file', "error")
      }
    },

    uploadBankDataFile(uploadFileData, selectedEtlId, selectedBankAccountId){
      this.storeLoad.setVisible(true)
      let entityId = this.entityStore.getCurrentEntity

      BanksDal.uploadBankFiles(entityId, uploadFileData, 'banksdata', selectedEtlId, selectedBankAccountId)
            .then(data => {
              this.storeLoad.setVisible(false)
              if(data.result === 'SUCCESS'){
                Swal.fire(`Upload bank data ${uploadFileData.length === 1 ? 'file' : 'files'}`, `${uploadFileData.length === 1 ? 'File' : 'Files'} uploaded successfully`, 'success')
                    .then(() => {
                      this.getBankUploadedFiles()
                    })
              }else{
                Swal.fire('Upload bank data file', 'File upload failed . Please contact your system administrator', 'error')
              }
            })

      // uploadFileData.forEach(uploadedFile => {
      //   BanksDal.addBankUploadedFile(entityId, uploadedFile.fileName, uploadedFile.fileType, uploadedFile.fileData, 'banksdata', selectedEtlId, selectedBankAccountId)
      //       .then(data => {
      //         this.storeLoad.setVisible(false)
      //         if(data.result === 'SUCCESS'){
      //           Swal.fire('Upload bank data file', 'File uploaded successfully', 'success')
      //               .then(() => {
      //                 this.getBankUploadedFiles()
      //               })
      //         }else{
      //           Swal.fire('Upload bank data file', 'File upload failed . Please contact your system administrator', 'error')
      //         }
      //       })
      // })

    },

    csvToJson(csv) {
      const lines = csv.split('\n');
      const result = [];

      // Assuming first row contains headers
      const headers = lines[0].split(',');

      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(',');

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentLine[j]?.trim();
        }

        // Avoid adding empty lines
        if (Object.keys(obj).length && Object.values(obj).some(value => value !== '')) {
          result.push(obj);
        }
      }

      return result;
    },

    validateCSVFile(file) {
      const validMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      return validMimeTypes.includes(file.type) || fileExtension === 'csv';
    },

    validateExcelFile(file) {
      const validMimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      return validMimeTypes.includes(file.type) || ['xls', 'xlsx'].includes(fileExtension);
    },

    async isRealExcelFile(file) {
      try {
        const zip = await JSZip.loadAsync(file);
        // Check if required Excel files exist within the ZIP structure
        return zip.files['xl/workbook.xml'] !== undefined;
      } catch (error) {
        return false; // The file is not a valid ZIP/Excel file
      }
    },

    async isRealCSVFile(file) {
      try {
        const text = await file.text();
        // Check if the content has rows and columns separated by commas
        const lines = text.split('\n').filter(line => line.trim() !== '');

        // Basic CSV structure check: multiple rows and consistent column count
        const firstLine = lines[0];
        const numOfColumns = firstLine.split(',').length;

        for (let i = 1; i < lines.length; i++) {
          const columns = lines[i].split(',');
          if (columns.length < numOfColumns) {
            return false; // Inconsistent column count
          }
        }

        // Further validation could include ensuring no non-text binary data is present
        return true;
      } catch (error) {
        console.error('Error reading CSV file:', error);
        return false; // The file is not valid CSV
      }
    },

    async validateCSVContent(file) {
      // const text = await file.text();
      // const lines = text.split('\n');
      //
      // for (let line of lines) {
      //   if (this.containsMaliciousContent(line)) {
      //     return false;  // Found malicious content
      //   }
      // }

      return true;
    },

    validateExcelContentRows(rows) {
      /*
      for (const row of rows) {
        for(const cell of row){
          if (this.containsMaliciousContent(cell)) {
            console.log(`Invalid content row : ${row}`)
            return false;  // Found malicious content
          }
        }
      }*/

      return true
    },

    async validateExcelContent(file) {
      try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });

        // Loop through all sheets and their contents
        for (const sheetName of workbook.SheetNames) {
          const sheet = workbook.Sheets[sheetName];
          const sheetContent = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          for (const row of sheetContent) {
            for (const cell of row) {
              if (this.containsMaliciousContent(cell)) {
                return false;  // Found malicious content
              }
            }
          }
        }

        return true;
      } catch (error) {
        console.error('Error reading Excel content:', error);
        return false;
      }
    },

    containsMaliciousContent(content) {
      const maliciousPatterns = [
        /(\b(SELECT|INSERT|DELETE|UPDATE|DROP|ALTER|TRUNCATE|CREATE|EXEC)\b)/i,  // SQL keywords
        //    /--|\/\*|\*\/|;/g,  // SQL comment and query termination
        /<script[\s\S]*?>[\s\S]*?<\/script>/i,  // Script tags
        /(onload|onerror|onmouseover|onclick|onfocus)=/i,  // JavaScript events
        /['"](\s|\n)*?OR(\s|\n)+['"]/i,  // SQL injection via OR condition
        /['"](\s|\n)*?UNION(\s|\n)+SELECT/i,  // UNION-based SQL injection
        //     /[`~!#$%^&*(){}[\];<>,]/g  // Characters often associated with harmful input
      ];

      // Check if any pattern matches the content
      for (const pattern of maliciousPatterns) {
        if (pattern.test(content)) {
          console.log(`Invalid content : ${content}`)
          return true;
        }
      }

      return false;
    },

    arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    proceedActionOld(actionType, id){
      if(actionType === 'add'){
        let entityId = this.entityStore.getCurrentEntity
        this.storeLoad.setVisible(true)
        BanksDal.getEntityBankAccounts(entityId)
            .then(data => {
              this.storeLoad.setVisible(false)
              if(data.result === 'SUCCESS'){
                if(data.data.length > 0){
                  let bankEntityOptions = ''
                  data.data.forEach(row => {
                    bankEntityOptions += `<option value="${row.id}">${row.account_alias} - ${row.bank_account_id}</option>`
                  })
                  let uploadFileData
                  Swal.fire({
                    title: 'Upload new file',
                    html: `<label for="banksEntityId" style="width:100%; text-align: left">Choose bank account</label>
                 <select id="banksEntityId" style="border: 1px solid #cccccc; border-radius: 3px; height: 50px; margin-bottom: 10px;">
                    ${bankEntityOptions}
                 </select>
                 <input class="inputFile" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                 <label id="errorMessage" style="color: red"></label>`,
                    width:600,
                    showCancelButton: true,
                    didOpen(popup) {
                      let that = this
                      let fileInput = document.getElementsByClassName('inputFile')[0]
                      fileInput.addEventListener('change',function (event) {
                        $("#errorMessage").html('')
                        let fileToLoad = event.currentTarget.files[0]
                        let fileReader = new FileReader()
                        let base64
                        fileReader.onload = function(fileLoadedEvent) {
                          base64 = fileLoadedEvent.target.result;
                          base64 = base64.replace(`data:${fileToLoad.type};base64,`,'')
                          uploadFileData = {
                            entityId: entityId,
                            bankEntityId: $("#banksEntityId").val(),
                            fileName: fileToLoad.name ,
                            fileType: fileToLoad.type ,
                            fileData: base64
                          }
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(fileToLoad);

                      })
                    },
                    preConfirm(inputValue) {
                      if(document.getElementsByClassName('inputFile')[0].value === ''){
                        $("#errorMessage").html('Please select the file')
                        return false
                      }
                    }
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.storeLoad.setVisible(true)
                      BanksDal.addBankUploadedFile(entityId, uploadFileData.bankEntityId, uploadFileData.fileName, uploadFileData.fileType, uploadFileData.fileData, 'banksdata')
                          .then(data => {
                            this.storeLoad.setVisible(false)
                            if(data.result === 'SUCCESS'){
                              Swal.fire('Upload bank file', 'File uploaded successfully', 'success')
                                  .then(() => {
                                    this.getBankUploadedFiles()
                                  })
                            }else{
                              Swal.fire('Upload bank file', 'File upload failed . Please contact your system administrator', 'error')
                            }
                          })
                    }
                  })
                }
                else{
                  Swal.fire('Add new file', "This entity has no bank account . Please add at least one bank account to the entity and try again ", 'error')
                }
              }else{
                Swal.fire('Add new file', "Can't get bank accounts . Please contact your system administrator", 'error')
              }
            })
      }
    }

  }
}

</script>

<style scoped>

</style>