import axios from "axios";
import VueAxios from 'vue-axios'
import Vue from "vue";

axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

import env from '../env'

export class ApiDal {
    constructor() {
        if (!sessionStorage.getItem('tabId')) {
            sessionStorage.setItem('tabId', `tab-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`);
        }
        this.config = {
            method: 'post',
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'application/json',
                'tab-id': sessionStorage.getItem('tabId')
            },
        }
        this.url = env.getPortalUrl()
    }

    async callApi(method , api, keyName, dataParam){
        let resObject = {}
        this.config.method = method
        this.config.headers.jwtToken = sessionStorage.getItem('jwtToken')
        this.config.headers.appSessionId = sessionStorage.getItem('appSessionId')

        if(dataParam){
            this.config.data = JSON.stringify(dataParam)
        }

        this.config.url = `${this.url}${api}${method==='post' || !dataParam || Object.keys(dataParam).length === 0 ? '' : ('?'+new URLSearchParams(dataParam).toString())}`
        try{
            let res = await Vue.axios(this.config)
            if (res.status !== 200) {
                resObject.result = 'FAILED'
                if(keyName){
                    resObject[keyName] = null
                }
                return resObject
            }

            if(keyName){
                resObject[keyName] = res.data
                resObject.result = res.data.result
                return resObject
            }else{
                return res.data
            }

        }catch (err) {
            console.log(err)
            resObject.result = 'FAILED'
            resObject[keyName] = null
            return resObject
        }
    }
}