<template>
  <pbi-report :user-group-id="userGroupId" :screen-name="screenName"></pbi-report>
</template>

<script>
import PbiReport from "@/screens/PbiReport.vue"
export default {
  name: 'AnalyticsOverview',
  components:{
    PbiReport
  },
  props: {
    userGroupId: Number,
    screenName: String
  },

}
</script>

<style scoped>

</style>