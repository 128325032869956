<template>
  <div class="radioBox-area field-area" :id="radioBoxListId">
    <h4>{{title}}</h4>
    <ul class="radioBox-radio">
      <li v-for="option in options" :key="option.name">
        <input type="radio" :id="option.name" :value="option.name" :name="'radio-box-options-'+title" v-model="selectedValue" >
        <label :for="option.name" class="rb-label">{{option.name}}</label>
      </li>
    </ul>
  </div>
</template>

<script>
import {bus} from '@/main'

export default {
  name: 'RadioBoxComponent',
  props: {
    title: String,
    nextComponentId: Number,
    options: Array,
    existingValue: String
  },
  // mounted() {
  //   bus.$on('getConfiguration', this.getConfiguration)
  // },

  data(){
    return {
      selectedValue: this.existingValue ? this.existingValue : null,
      radioBoxListId: 'radio-box-component-'+this.title
    }
  },
  watch: {
    selectedValue: function (newVal, oldVal) {
      this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: newVal, radioBoxListId: this.radioBoxListId})
    }
  },
  methods: {
    getConfiguration(){
      return {data: this.selectedValue}
    }
  }
}

</script>


<style scoped>

.rb-label{
  /*font-size: 12px;*/
}

.main-wrapper{
  margin: 20px;
}

/* Style for horizontal radio buttons with labels on the left */
.horizontal-radio-list {
  list-style: none;
  display: flex;
}

.horizontal-radio-list li {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust the spacing between radio buttons */
}

.horizontal-radio-list label {
  margin-right: 5px; /* Adjust the spacing between label and radio button */
  white-space: nowrap; /* Prevent line breaks within labels */
}
.horizontal-radio-list input{
  margin-right: 10px;
}
</style>
