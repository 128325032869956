<template>
  <div class="docz-component">
    <div class="table-area datatable" ></div>
  </div>
</template>

<script>
import { useLoadStore } from "@/stores/load"
import { useGlAccountStore } from "@/stores/gl"
import GlDal from "@/dal/gl_dal"
import $ from "jquery"
import Swal from "sweetalert2";
import { useAuthStore } from "@/stores/auth"

export default {
  name: "GLAccounts",
  props: {
    allGlAccounts: Array,
    glAccountsConfigData: Array,
    entityId: Number
  },
  data() {
    return{
      storeLoad: useLoadStore(),
      authStore: useAuthStore(),
      glAccountsStore: useGlAccountStore(),
      glAccounts: null,
      selectedGlAccount: null,
      buttonsInitiated: false,
      reportTypes: this.removeDuplicates(this.glAccountsConfigData.map(x => {return {id: x.report_id, name: x.report_name}})),

      level1options: this.removeDuplicates(this.glAccountsConfigData.map(x => {return {id: x.user_level1_category_id, name: x.user_level1_category_name}})),
      // economicOptions: this.removeDuplicates(this.glAccountsConfigData.map(x => {return {id: x.economic_category_id, name: x.economic_category_name, report_id: x.report_id}})),

      cashFlowBuckets: null,
      directCashFlowBuckets: null,
      table: null,
      newRowAdded: false
    }
  },
  mounted() {
    this.reportTypes.unshift({id:0, name: ''})

    GlDal.getGlCashflowBuckets()
        .then(data => {
          this.cashFlowBuckets = data.data.filter(x => x.bucket_type == 'cashflow_bucket_categories')
          this.directCashFlowBuckets = data.data.filter(x => x.bucket_type == 'direct_cashflow_bucket_categories')
          this.cashFlowBuckets.unshift({id:0, name: ''})
          this.directCashFlowBuckets.unshift({id:0, name: ''})
          this.createGlAccountsGrid(this.allGlAccounts)
        })
  },

  methods:{
    createGlAccountsGrid(glAccounts){
      this.buttonsInitiated = false
      if(glAccounts){
        this.glAccounts = glAccounts
      }

      let dataColumns = [
        {title: 'ID', type: 'readonly', index: 0},
        {title: 'GL ID', type: 'readonly', index: 0},
        {title: 'Name', type: 'text', index: 1, alias: 'text_gl_name'},
        {title: 'Description', type: 'text', index: 2, alias: 'text_gl_description'},
        {title: 'Report', type: 'select', options: this.reportTypes, index: 3, alias: 'select_report_type'},
        {title: 'Account Type', type: 'readonly'},
        {title: 'Group', type: 'select', options: [{id:0, name: ''}], index: 4, alias: 'select_system_category'},     // System Category
        {title: 'Level #1', type: 'select', options: this.level1options, index: 5, alias: 'select_level_1'},
        {title: 'Level #2', type: 'select', options: [{id:0, name: ''}], index: 6, alias: 'select_level_2'},
        {title: 'Level #3', type: 'select', options: [{id:0, name: ''}], index: 7, alias: 'select_level_3'},
        {title: 'Economic', type: 'select', options: [{id:0, name: ''}], index: 8, alias: 'select_economic_category'},  // Economic Category
        {title: 'Is Editable', type: 'readonly'},
        {title: 'Status ID', type: 'readonly'},
        {title: 'Cash Flow', type: 'select', options: this.cashFlowBuckets, index: 9, alias: 'select_cash_flow'},
        {title: 'Cash Bucket', type: 'select', options: this.directCashFlowBuckets, index: 10, alias: 'select_cash_bucket'}, //Direct Cash Flow Bucket
        {title: ''},
      ]

      let dataRows = []
      this.glAccounts.forEach(row=> {
        dataRows.push([row.id , row.reference_id , row.gl_account_name, row.gl_account_description, row.report_name, row.account_type_name, row.system_category_name, row.user_level1_category_name, row.user_level2_category_name, row.user_level3_category_name, row.economic_category_name, row.is_editable, row.status_id, row.cashflow_bucket_category_name , row.direct_cashflow_bucket_category_name ])
      })

      let that = this
      $(".datatable").html("")
      $(".datatable").append('<table id="datatable" class="table table-hover table-striped non-editable" style="font-family: Arial"></table>')

      that.table =  $('#datatable').DataTable({
        data: dataRows,
        columns: dataColumns,
        paging: false,
        scrollCollapse: true,
        scrollY: '600px',
        pageLength: 40,
        "order": [],
        columnDefs: [
          {
            targets: -1,
            width: "80px",
            render: function (data, type, row) {
              return `<button class="icon-btn grid-btn edit_btn" value="edit_account" mode="read" status_id="${row[11]}"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn cancel_btn" style="visibility: hidden"><i class='fa fa-close' style='font-size: 16px; color: #dc0707' ></i></button>`;
            },
          },
          {
            targets: [0,5,11,12],
            visible: false,
            searchable: false,
          }
        ],

        drawCallback: function (settings) {
          that.storeLoad.setVisible(false)
          if(!that.buttonsInitiated){
            that.buttonsInitiated= true
            that.initButtons(dataColumns)
            that.addUnclassifiedCheckBox()
          }
        },

        dom: "Blfrtip",
        buttons: [
          "copy",
          "csv",
          "print",
          "excel",
          {
            text: "New GL Account",
            action: function (e, dt, node, config) {
              if(that.newRowAdded){
                Swal.fire("Please save the added tow before you add a new one")
              }else{
                that.addGlAccount();
              }
            },
          }
        ],
        bDestroy: true,
      })
    },

    initButtons(dataColumns){
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
        });
      });

      $(".datatable tbody").on("click",'.cancel_btn', function (event) {
        that.cancelChanges(this)
      })

      $(".datatable tbody").on('click','.edit_btn',function(){
        let row = $(this).closest('tr')

        if($(this).attr('mode') === 'read'){
          that.readTheData(row, dataColumns, this)
        }else{
          that.updateTheData(row)
        }
      })
    },

    readTheData(row, dataColumns, btn){
      let that = this;
      row.find('td').each(function(index, cell) {

        let column = dataColumns.find(x => x.index == index)
        if(typeof column !== 'undefined' && column.type === 'text'){
          $(cell).attr('original_value', $(cell).html());
          that.addTextInput(cell, column.alias)
        }else if (typeof column !== 'undefined' && column.type === 'select'){
          $(cell).attr('original_value', $(cell).html());
          that.addSelect(cell, column.options, column.alias)
        }
      });



      $(btn).html(`<i class='fa fa-check' style='font-size: 16px; color: #334249'></i>`)
      $(btn).attr('mode','edit')
      $(btn.nextElementSibling).css("opacity","1")
      btn.nextElementSibling.style.visibility = 'visible'
      const buttons = document.querySelectorAll('button[mode="read"]')
      buttons.forEach(button => {
        button.disabled = true
        button.style.opacity = "0.5"
      })
    },

    updateTheData(row){
      let that = this
      const validation = that.validateGlAccount()
      if(validation.validationResult === ''){
        let id = that.glAccounts.find(x => x.reference_id == row[0].cells[0].innerText) ? that.glAccounts.find(x => x.reference_id == row[0].cells[0].innerText).id : ''
        Swal.fire({
          title: id != '' ? 'Add the new account' : 'Update the account',
          html: '<p>Please approve to save the changes</p>',
          icon: 'question',
          showCancelButton: true
        }).then(result => {

          if(result.isConfirmed){
            that.storeLoad.setVisible(true)

            if(id != ''){

              GlDal.updateUserDefinedGlAccount(that.entityId, validation.level_1, validation.level_2, validation.level_3, validation.gl_account_name, validation.gl_account_description,
                  that.authStore.getUserData.userId, validation.economic_category, validation.cash_flow, validation.cash_bucket, id)
                  .then(data => {
                    that.storeLoad.setVisible(false)
                    if(data.result === 'SUCCESS'){
                      Swal.fire('Update GL Account', 'The GL Account updated successfully', 'success')
                          .then(()=> {
                            that.$emit('refresh-grid')
                          })
                    }else{
                      Swal.fire('Update GL Account', 'GL Account update failed . Please contact your system administrator', 'error')
                    }
                  })
            }else{
              GlDal.addUserDefinedGlAccount(that.entityId, validation.level_1, validation.level_2, validation.level_3, validation.gl_account_name, validation.gl_account_description,
                  that.authStore.getUserData.userId, validation.economic_category, validation.cash_flow, validation.cash_bucket).
              then(data => {
                that.storeLoad.setVisible(false)
                if(data.result === 'SUCCESS'){
                  that.newRowAdded = false
                  Swal.fire('Add new GL Account', 'The GL Account added successfully', 'success')
                      .then(()=> {
                        that.$emit('refresh-grid')
                      })
                }else{
                  Swal.fire('Update GL Account', 'GL Account update failed . Please contact your system administrator', 'error')
                }
              })
            }
          }
        })
      }else{
        Swal.fire({
          title: 'Validation Failed',
          html: `<p>${validation.validationResult}</p>`,
          icon : 'error'
        })
      }
    },

    cancelChanges(btn){
      let that = this;
      if(that.newRowAdded){
        Swal.fire({
          title: 'Cancel new row',
          html: '<p>Are you sure? This will cancel all changes?</p>',
          icon: 'question',
          showCancelButton: true
        }).then(result => {
          if(result.isConfirmed){
            that.table.row(':last').remove().draw(false)
            that.newRowAdded = false
            const buttons = document.querySelectorAll('button[mode="read"]')
            buttons.forEach(button => {
              button.disabled = false
              button.style.opacity = "1"
            })
            $('#datatable_filter').css("opacity","1")
            $('#datatable_filter').css('visibility', 'visible')
            $("#dataFilterCb").prop('disabled', false)
          }
        })
      }else{
        Swal.fire({
          title: 'Cancel all changes',
          html: '<p>Are you sure? This will cancel all changes?</p>',
          icon: 'question',
          showCancelButton: true
        }).then( result =>{
          if(result.isConfirmed){
            let row = $(btn).closest('tr')

            row.find('td').each(function(index, cell) {
              let original_value = $(this).attr('original_value');
              if(typeof original_value !== 'undefined' && original_value !== false){
                $(cell).html(original_value)
              }
            })

            $(btn.previousElementSibling).html(`<i class='fa fa-edit' style='font-size: 16px; color: #334249'></i>`)
            $(btn.previousElementSibling).attr('mode','read')
            $(btn).css("opacity","0")
            $(btn).css("transition","none")
            $(btn).css("visibility","hidden")

            const buttons = document.querySelectorAll('button[mode="read"]')
            buttons.forEach(button => {
              button.disabled = false
              button.style.opacity = "1"
            })
          }
        })
      }
    },

    validateGlAccount(){

      let validationResult = ''

      let gl_account_name = $('#text_gl_name').val()
      let gl_account_description = $('#text_gl_description').val()
      let report_name = $('#select_report_type').val()
      let system_category = $('#select_system_category').val()
      let level_1 = $('#select_level_1').val()
      let level_2 = $('#select_level_2').val()
      let level_3 = $('#select_level_3').val()
      let economic_category = $('#select_economic_category').val()
      let cash_flow = $('#select_cash_flow').val()
      let cash_bucket = $('#select_cash_bucket').val()

      if(cash_bucket == '0'){
        validationResult = "Please set cash-bucket category name"
      }

      if(cash_flow == '0'){
        validationResult = "Please set cash-flow category name"
      }

      if(economic_category == '0'){
        validationResult = "Please select economic category"
      }

      if(level_1 == '0'){
        validationResult = "Please select user level-1 category"
      }

      if(system_category == '0'){
        validationResult = "Please set group category name"
      }

      if(report_name == '0'){
        validationResult = "Please set report name"
      }

      if(gl_account_name == ''){
        validationResult = "Please set account name"
      }

      return {validationResult , gl_account_name, gl_account_description , report_name ,system_category ,level_1, level_2, level_3 ,economic_category ,cash_flow ,cash_bucket}
    },

    addTextInput(cell, text_alias){
      let textInput = document.createElement('input')
      textInput.type = 'text'
      textInput.id = text_alias
      textInput.value = cell.innerText
      $(cell).empty().append(textInput);
    },

    addSelect(cell, options, select_alias){
      let that = this
      let select = document.createElement('select')
      $(select).css('height', '38px').css('border', '1px solid #cccccc')
      select.id = select_alias

      $.each(options, function(index, value){
        let option = document.createElement("option")
        option.value = value.id
        option.text = value.name
        option.selected = option.text === cell.innerText
        $(select).append(option)
      })


      $(cell).empty().append(select);
      select.addEventListener("change", function() {
        that.glSelectionChanged(this, select_alias)
      })

      let select_report_type = document.getElementById('select_report_type')
      if(select_report_type.value != 0){
        that.glSelectionChanged(select_report_type, 'select_report_type', select_report_type.parentElement.nextElementSibling.getAttribute('original_value'))
      }

      let select_system_category = document.getElementById('select_system_category')
      if(select_system_category && select_system_category.value != 0){
        that.glSelectionChanged(select_system_category, 'select_system_category', select_system_category.parentElement.nextElementSibling.getAttribute('original_value'))
      }

      let select_level_1 = document.getElementById('select_level_1')
      if(select_level_1 && select_level_1.value != 0){
        that.glSelectionChanged(select_level_1, 'select_level_1', select_level_1.parentElement.nextElementSibling.getAttribute('original_value'))
      }

      let select_level_2 = document.getElementById('select_level_2')
      if(select_level_2 && select_level_2.value != 0){
        that.glSelectionChanged(select_level_2, 'select_level_2', select_level_2.parentElement.nextElementSibling.getAttribute('original_value'))
      }
    },

    glSelectionChanged(select, select_alias, selected_value){
      let sel = document.getElementById(select_alias)

      switch(select_alias){
        case 'select_report_type':
          this.storeLoad.setVisible(true)
          GlDal.getEconomyCategories(select.value)
              .then(data => {
                this.storeLoad.setVisible(false)
                this.addOptionsToSelect('select_economic_category', data.data, 'economic_category_name', document.getElementById('select_economic_category').parentElement.getAttribute('original_value'))
              })

          let systemCategories = this.removeDuplicates(
              this.glAccountsConfigData.filter(x=> x.report_name == $( `#${select_alias} option:selected` ).text())
                  .map(x => {
                    return {id: x.system_category_id, name: x.system_category_name}
                  })
          )
          this.addOptionsToSelect('select_level_1', [], '')
          this.addOptionsToSelect('select_level_2', [], '')
          this.addOptionsToSelect('select_level_3', [], '')
          this.addOptionsToSelect('select_system_category', systemCategories, 'name', selected_value)
          break

        case 'select_system_category':
          let userLevel1Categories = this.removeDuplicates(
              this.glAccountsConfigData.filter(x => x.system_category_name == sel.options[sel.selectedIndex].text)
                  .map( x => {
                    return {id: x.user_level1_category_id, name: x.user_level1_category_name}
                  })
          )
          this.addOptionsToSelect('select_level_2', [], '')
          this.addOptionsToSelect('select_level_3', [], '')
          this.addOptionsToSelect('select_level_1', userLevel1Categories, 'name', selected_value)
          break

        case 'select_level_1':
          let userLevel2Categories = this.removeDuplicates(
              this.glAccountsConfigData.filter(x => x.user_level1_category_name == sel.options[sel.selectedIndex].text)
                  .map( x => {
                    if(x.user_level2_category_id){
                      return {id: x.user_level2_category_id, name: x.user_level2_category_name}
                    }

                  })
          )
          this.addOptionsToSelect('select_level_3', [], '')
          this.addOptionsToSelect('select_level_2', userLevel2Categories, 'name', selected_value)
          break

        case 'select_level_2':
          let userLevel3Categories = this.removeDuplicates(
              this.glAccountsConfigData.filter(x => x.user_level2_category_name == sel.options[sel.selectedIndex].text)
                  .map( x => {
                    return {id: x.user_level3_category_id, name: x.user_level3_category_name}
                  })
          )
          this.addOptionsToSelect('select_level_3', userLevel3Categories, 'name', selected_value)
          break

        default:
          break;
      }

    },

    addOptionsToSelect(selectId, options, value_name, selected_value){
      $(`#${selectId} option:not(:first)`).remove();
      $.each(options, function(index, value){
        if(value){
          let option = document.createElement("option")
          option.value = value.id
          option.text = value[value_name]
          option.selected = option.text === selected_value
          $(`#${selectId}`).append(option)
        }
      })
    },

    addUnclassifiedCheckBox(){
      let dtButtonsDiv = document.querySelector('.dt-buttons')
      let children = dtButtonsDiv.childNodes
      let cbAlreadyAdded = false

      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeName.toLowerCase() === 'input' && children[i].type.toLowerCase() === 'checkbox') {
          cbAlreadyAdded = true
        }
      }

      if(!cbAlreadyAdded){
        let checkbox = document.createElement('input');
        checkbox.type = 'checkbox'
        checkbox.style.width="25px"
        checkbox.id = "dataFilterCb"
        checkbox.checked = true

        let label = document.createElement('label')
        label.htmlFor = 'dataFilterCb'
        label.style.color = '#8d76ef'
        label.appendChild(document.createTextNode('Unclassified accounts'))

        dtButtonsDiv.appendChild(checkbox)
        dtButtonsDiv.appendChild(label)

        let table = $('#datatable').DataTable();
        table.column(4).search('^$', true, false ).draw(false);

        checkbox.addEventListener('click', function() {
          if (this.checked) {
            table.column(4).search('^$', true, false ).draw();
          } else {
            table.search('').columns().search('').draw();
          }
        })
      }
    },

    addGlAccount(){
      this.newRowAdded = true

      $('#datatable_filter').css("opacity","0")
      $('#datatable_filter').css("transition","none")
      $('#datatable_filter').css('visibility', 'hidden')

      $("#dataFilterCb").prop('disabled', true)

      this.table.row.add([
        '', '' , '', '', '', 'Customer defined', '', '', '', '', '', 1, "1", '' , ''
      ]).draw(false)

      this.table.page('last').draw('page')


      let lastRow = this.table.rows().nodes().to$().last(); // Get the last row
      let lastCell = lastRow.find('td').last(); // Get the last cell of the last row
      let button = lastCell.find('button'); // Find the button in the last cell

      button[0].click();
    },

    manageUserLevels(){

    },

    removeDuplicates(arr){
      const seen = new Set();
      return arr.filter((obj) => {
        const stringified = JSON.stringify(obj)
        if (seen.has(stringified)) {
          return false
        }
        seen.add(stringified)
        return true
      })
    },

  }
}
</script>


<style scoped>
table.dataTable td {
  font-size: 2em;
}
</style>