<template>
  <figure class="support-area" :class="{ SupportIsOpen: isSupportOpen, isSupportResize: isSupportResize }">
    <button class="support-btn" @click="toggleSupport">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M3.86,31.45,24.68,100,37.9,81.62,24.67,31.45ZM73.3,0,32.1,59.61H53.55L96.14,0Z"/>
      </svg>
    </button>

    <div class="support-chat-area">
      <figure class="buttons-area">
        <button class="resize-support-btn" @click="toggleSize">◲</button>
        <button class="close-support-btn" @click="toggleSupport">&times;</button>
      </figure>
      <div class="support-chat">
        <div class="the-chat">
          <div class="chat-container">
            <div class="messages">
              <div
                  v-for="(msg, index) in messages"
                  :key="index"
                  :class="['message overflow-x-auto whitespace-pre-wrap', msg.isUser ? 'user-message' : 'bot-message', msg.isWaiting ? 'waiting-message' : '']"
              >
                <div v-html="msg.text" />
              </div>
            </div>
            <figure class="input-container">
              <figure class="sendMessage-area">
                <input
                    type="text"
                    v-model="userInput"
                    @keydown.enter="sendMessage"
                    placeholder="Ask me anything!"
                />
                <button class="sendMessage-btn" @click="sendMessage">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke-width="1" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="22" y1="2" x2="11" y2="14"></line>
                    <polygon points="22 2 15 22 11 14 2 10 22 2"></polygon>
                  </svg>
                </button>
              </figure>
              <span class="fileInput-btn">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                  <title>file</title>
                  <path d="M20 10.9696L11.9628 18.5497C10.9782 19.4783 9.64274 20 8.25028 20C6.85782 20 5.52239 19.4783 4.53777 18.5497C3.55315 17.6211 3 16.3616 3 15.0483C3 13.7351 3.55315 12.4756 4.53777 11.547L12.575 3.96687C13.2314 3.34779 14.1217 3 15.05 3C15.9783 3 16.8686 3.34779 17.525 3.96687C18.1814 4.58595 18.5502 5.4256 18.5502 6.30111C18.5502 7.17662 18.1814 8.01628 17.525 8.63535L9.47904 16.2154C9.15083 16.525 8.70569 16.6989 8.24154 16.6989C7.77738 16.6989 7.33224 16.525 7.00403 16.2154C6.67583 15.9059 6.49144 15.4861 6.49144 15.0483C6.49144 14.6106 6.67583 14.1907 7.00403 13.8812L14.429 6.88674" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <input type="file" @change="uploadFile" id="inputFile"/>
              </span>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </figure>
</template>

<script>
import AiSupportDal from "@/dal/ai_support_dal";
import {useEntityStore} from "@/stores/entity"
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // Choose a theme or style for syntax highlighting
import $ from "jquery"
import env from "@/env";

export default {
  data() {
    return {
      isSupportOpen: false,
      isSupportResize: false,
      userInput: '',
      messages: [],
      entityStore: useEntityStore(),
      connectConfig: {
        url: `${env.getPortalUrl()}/api/openai/callOpenAi`,
        method: "POST",
        stream: true
      },
      ollamaConnectConfig: {
        url: `${env.getPortalUrl()}/api/openai/callOllamaAi`,
        method: "POST",
        stream: true
      }

    };
  },

  updated() { // This will be triggered every time the component updates
    this.$nextTick(() => {
      $('pre code').each(function(i, block) {
        hljs.highlightBlock(block);
      });
    });
  },


  methods: {
    toggleSupport: function () {
      this.isSupportOpen = this.isSupportOpen === false;
    },

    toggleSize: function () {
      this.isSupportResize = this.isSupportResize === false;
    },


    async sendMessage() {
      if (!this.userInput.trim()) return;

      this.addMessage(this.userInput, true, false);  // Add the user message
      const placeholderIndex = this.messages.length;  // Placeholder message while waiting for the API response
      this.addMessage('...', false, true);

      try {
        let message = this.userInput
        this.userInput = '';

        let apiResponse = await AiSupportDal.callOllamaAi(message, this.entityStore.getCurrentEntity)

        let content = apiResponse.data.message.content

        const hasCodeBlock = content.includes("```");
        if (hasCodeBlock) {        // If the content has code block, wrap it in a <pre><code> element
          content = content.replace(/```([\s\S]+?)```/g, '<p><pre><code>$1</code></pre></p>');
        }

        content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // replace ** ** wrapped text to bold .
        this.updateMessage(placeholderIndex, content);

      } catch (error) {
        console.error(error)
        this.updateMessage(placeholderIndex, 'Error in fetching response');
      }
    },

    async uploadFile(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Add the file upload message
      this.addMessage(`File uploaded: ${file.name}`, true, false);

      // Placeholder message while waiting for the API response
      const placeholderIndex = this.messages.length;
      this.addMessage('...', false, true);

      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await this.callApi(formData);
        const formattedText = this.formatResponse(response.data.message);
        this.updateMessage(placeholderIndex, formattedText);
        this.updateMessage(placeholderIndex, content);
      } catch (error) {
        this.updateMessage(placeholderIndex, 'Error in fetching response');
      }
    },

    addMessage(text, isUser, isWaiting) {
      this.messages.push({ text, isUser, isWaiting });
    },

    updateMessage(index, newText) {
      this.$set(this.messages, index, { text: newText, isUser: false });
    },

    // formatResponse(text) {
    //   const tempDiv = document.createElement('div');  // Convert plain text into HTML with syntax highlighting
    //   tempDiv.innerHTML = text;
    //
    //   tempDiv.querySelectorAll('pre code').forEach((block) => { // Apply syntax highlighting to code blocks
    //     hljs.highlightBlock(block);
    //   });
    //
    //   return tempDiv.innerHTML;
    // },

    async callApi_old(data) {
      const messages = [{ text: data }]

      const response = await fetch(this.connectConfig.url, {
        method: this.connectConfig.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messages }),
      });

      const resp = await response.json();

      return new Promise((resolve) => {
        resolve({data: {message: resp.text}});
      });
    },

    async callApi(data) {
      const messages = [{ text: data }]

      const response = await fetch(this.ollamaConnectConfig.url, {
        method: this.ollamaConnectConfig.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messages }),
      });

      const resp = await response.json();

      return new Promise((resolve) => {
        console.log(resp)
        resolve({data: {message: resp.text}});
      });
    },
  },
};
</script>

<style scoped>

pre {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

code {
  font-family: 'Courier New', Courier, monospace;
}

.overflow-x-auto{
  overflow-x: auto;
}

.whitespace-pre-wrap{
  white-space: pre-wrap;
}

.pre-block {
  font-size: 20px;
  border: 2px solid grey;
  width: 450px;
  border-left: 12px solid green;
  border-radius: 5px;
  padding: 14px;
}




</style>
