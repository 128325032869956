import Vue from "vue";
import env from "@/env";
import {ApiDal} from "@/dal/api_dal";

class PowerBiDal extends ApiDal{
    async getPbiReportConnectionDetails(workspaceId,reportId){
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'application/json',
                'tab-id': sessionStorage.getItem('tabId')
            },
            data: JSON.stringify({workspaceId,reportId}),
            url: env.getPortalUrl() + "/api/profiles/getPbiReportConnectionDetails"
        }

        let res = await Vue.axios(config);
        let embedData = res.data

        return embedData
    }

    async getDashboardDetails(ugId, screenName){
        return this.callApi('post', '/api/dashboards/getDashboardDetails', null, {ugId, screenName})
    }
}

export default new PowerBiDal()