<template>
<div>

  <div class="parent">
    <div class="div1">
      <b-form-group id="input-group-vat-number" label="VAT Number :" label-for="input-vat-number">
        <b-form-input id="input-vat-number" v-model="entity.entity_id" placeholder="Enter VAT Number" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-entity-name" label="Entity Name :" label-for="input-account-name">
        <b-form-input id="input-entity-name" v-model="entity.entity_name" placeholder="Enter Company Name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-entity-name-en" label="Entity Name (EN) :" label-for="input-entity-name-en">
        <b-form-input id="input-entity-name-en" v-model="entity.entity_name_en" placeholder="Enter Company Name (EN )" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-description" label="Description :" label-for="input-description">
        <b-form-input id="input-description" v-model="entity.description" placeholder="Enter Description" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-created-date" label="Created Date :" label-for="input-created-date"  >
        <b-form-input id="input-created-date" disabled v-model="entity.created_date"  required ></b-form-input>
      </b-form-group>
    </div>
    <div class="div2">
      <b-form-group id="input-group-city-name" label="City Name:" label-for="input-city-name"  >
        <b-form-input id="input-city-name" v-model="entity.city_name" placeholder="Enter City Name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-street-name" label="Street Name:" label-for="input-street-name"  >
        <b-form-input id="input-street-name" v-model="entity.city_name" placeholder="Enter Street Name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-house-number" label="House Number :" label-for="input-house-number"  >
        <b-form-input id="input-house-number" v-model="entity.house_number" placeholder="Enter House Number" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-zip-code" label="ZIP Code :" label-for="input-zip-code"  >
        <b-form-input id="input-zip-code" v-model="entity.zip_code" placeholder="ZIP Code" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-po-box" label="PO Box :" label-for="input-po-box"  >
        <b-form-input id="input-po-box" v-model="entity.po_box" placeholder="PO Box" required></b-form-input>
      </b-form-group>
    </div>
  </div>


  <b-button type="submit" variant="primary" @click="updateEntity" class="form-btn">Update Entity</b-button>
  <b-button type="submit" variant="primary" @click="cancelUpdate" class="form-btn">Cancel</b-button>
</div>
</template>

<script >
import Swal from "sweetalert2"

export default {
  name: 'EntityForm',
  props: {
    selectedEntity: Object
  },
  data() {
    return {
        entity : this.selectedEntity
    }

  },
  methods: {
    updateEntity(){
      Swal.fire({
        title: 'Update Entity',
        html: 'Please approve',
        showConfirmButton: true,
        confirmButtonText: 'Confirm',
        showCancelButton: true
      })
          .then(result => {
            if(result.isConfirmed){
              this.$emit('update-entity', this.entity)
            }
          })

    },

    cancelUpdate(){
      Swal.fire({
        title: 'Cancel Update',
        html: 'All changes will be discarded . Please approve',
        showConfirmButton: true,
        confirmButtonText: 'Confirm',
        showCancelButton: true
      })
          .then(result => {
            if(result.isConfirmed){
              this.$emit('cancel-update', this.entity)
            }
          })
    }
  }

}
</script>

<style scoped>
.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }

.form-btn{
  margin: 10px;
}

</style>