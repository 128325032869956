<template>
  <div>
    <loading v-if="isLoading"></loading>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/auth"
import {useLoadStore} from "@/stores/load"
import Swal from 'sweetalert2'
import AuthDal from "@/dal/auth-dal"
import Loading from "@/components/Loading.vue"

export default {
  name: "Relogin",
  components: {
    Loading
  },
  data(){
    return {
      storeAuth: useAuthStore(),
      storeLoad: useLoadStore(),
      currentUser: '',
      isLoading: false
    }
  },
  mounted() {
    this.storeAuth.setAppMode('relogin')
    this.currentUser = this.$route.query.userId
    this.supportUserId = this.$route.query.supportUserId
    this.showOtp()

    //this.testConnection()

  },
  methods: {

    testConnection(){
      console.log(this.supportUserId)

      AuthDal.supportReLogin(this.supportUserId)
          .then(data => {
            console.log(data)
          })

    },

    showOtp(){
      Swal.fire({
        title: `<label>OTP Code <input type="text" id="otpCode" style="text-align: center; letter-spacing: 10px; "/></label>`,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Send',
        didOpen() {
          document.getElementById('otpCode').focus()
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          await AuthDal.validateOtp(this.currentUser, document.getElementById('otpCode').value, 'relogin')
              .then(data => {
                this.isLoading = false
                if (data.result === 'SUCCESS') {
                  Swal.fire('Relogin', data.result, 'success')
                      .then(() => {
                        this.isLoading = true
                        AuthDal.supportReLogin(this.supportUserId)
                            .then(data => {
                              this.isLoading = false

                              if(data.result === 'SUCCESS'){
                                let response = data.data[0]
                                this.storeAuth.setUserName(response.user_name)
                                this.storeAuth.setUserData({
                                  name: response.user_description ,
                                  otpEnabled: response.otp_enabled,
                                  phone: response.phone_number,
                                  userGroupId: response.user_group_id,
                                  profileId: response.profile_id,
                                  userId: response.user_id,
                                  userLevel: response.user_level_id,
                                  userName: response.user_name,
                                  userType: response.user_level_name,
                                  accountId: response.account_id,
                                  accountName: response.account_name,
                                  firstName: response.first_name,
                                  lastName: response.last_name})

                                this.storeAuth.setAuthorisation(true)
                                this.storeAuth.setAppMode('normal')
                              }else{
                                Swal.fire('Relogin', 'Relogin failed , please contact your system administrator', 'error')
                                    .then(()=> {
                                      window.close()
                                    })
                              }
                            })
                      })
                }else{
                  Swal.fire('Relogin', 'Relogin failed , please contact your system administrator', 'error')
                      .then(()=> {
                        this.showOtp()
                      })
                }
              })
        }
      })
    }
  }
}
</script>



<style scoped>

</style>