import { defineStore } from "pinia"

export const useLoadStore = defineStore({
    id: 'loadStore',
    state: () => ({
        loading: false
    }),
    getters:{
        isVisible:(state) => state.loading
    },
    actions:{
        setVisible(loadStatus){
            this.loading = loadStatus
        }
    }
})