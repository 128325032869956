<template>
  <div class="docz-component">
    <div class="table-area" v-if="selectedAccount == null">
      <div class="datatable"></div>
    </div>
  </div>
</template>

<script>

import AccountDal from "@/dal/account_dal"
import $ from "jquery"
import Swal from "sweetalert2"
import { useLoadStore } from "@/stores/load"
import { useAuthStore } from "@/stores/auth"
import UserGroupDal from "@/dal/user-group-dal"
import EntityDal from "@/dal/entity_dal";

export default {
  name: "Accounts",

  data(){
    return {
      storeLoad: useLoadStore(),
      storeAuth: useAuthStore(),
      selectedAccount: null,
      allAccounts: null
    }
  },

  mounted() {
    this.getAllAccounts()
  },

  methods: {
    getAllAccounts(){
      this.storeLoad.setVisible(true)
      AccountDal.getAllAccounts()
          .then(data => {
            this.allAccounts = data.accounts.data
            let dataColumns = []
            let dataRows = []
            data.accounts.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            });
            dataColumns.push({ title: "" }); // add column for buttons
            data.accounts.data.forEach((row) => {
              let dataRow = [];
              data.accounts.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            });
            let that = this
            $(".datatable").html("")
            $(".datatable").append('<table id="datatable" class="table table-hover table-striped non-editable"></table>')
            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 10,
              // scrollX: true,
              columnDefs: [
                {
                  targets: -1,
                  width: "160px",
                  render: function (data, type, row) {
                    return `<button class="icon-btn grid-btn" value="edit" title="Edit Account"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                            <button class="icon-btn grid-btn" value="user-groups" title="User Groups"><i class='fa fa-user-group' style='font-size: 16px; color: #334249'></i></button>
                            <button class="icon-btn grid-btn" value="entities" title="Connected Entities"><i class='fa fa-link' style='font-size: 16px; color: #334249'></i></button>
                            <button class="icon-btn grid-btn" value="relogin" title="Relogin To Account"><i class='fa fa-arrow-right' style='font-size: 16px; color: #334249'></i></button>
                          <button class="icon-btn grid-btn" value="delete" title="Delete Account"><i class='fa fa-trash' style='font-size: 16px; color: #334249'></i></button>`;
                  },
                },
                {
                  targets: [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,23,24,25],
                  visible: false,
                  searchable: false,
                }
              ],
              drawCallback: function (settings) {
                that.initButtons()
              },
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel"
              ],
              bDestroy: true,
            });
          })
    },
    initButtons() {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          let currentAccount = that.allAccounts.find(x=> x.id == event.target.closest("tr").cells[0].innerText)

          that.proceedAction(
              element.value,
              currentAccount.id,
              currentAccount.support_user_id
          );
        });
      });
      this.storeLoad.setVisible(false)
    },
    proceedAction(actionType,accountId, supportUserId){
      switch (actionType){
        case 'edit':
          this.editAccount(accountId)
          break;
        case 'user-groups':
          this.showAccountUserGroups(accountId)
          break;
        case 'entities':
          this.showConnectedEntities(accountId)
          break;
        case 'delete':
          break;
        case 'relogin':
          window.open(window.location.href+'relogin?userId='+this.storeAuth.getUserData.userId+'&supportUserId='+supportUserId, '_blank');
          break;
        default:
          break;
      }
    },

    editAccount(accountId){
      let accountName = this.allAccounts.find(x=> x.id == accountId).account_name
      let accountNameEn = this.allAccounts.find(x=> x.id == accountId).account_name_en
      // Swal.fire(`${accountId} : ${accountName}` )

      Swal.fire({
        title: 'Update Account',
        width: '500px',
        html: `<input type="text" id="account-name" style="width:80%" class="swal2-input" placeholder="Account Name" value="${accountName}" >
                 <input type="text" id="account-name-en" style="width:80%"  class="swal2-input" placeholder="Account Name - EN"  value="${accountNameEn || ''}">`,
        showCancelButton: true,
        confirmButtonText: 'Update',
        preConfirm: () => {
          const account_name = Swal.getPopup().querySelector('#account-name').value
          const account_name_en = Swal.getPopup().querySelector('#account-name-en').value

          return { account_name , account_name_en }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          AccountDal.updateAccountName(result.value.account_name , result.value.account_name_en, accountId)
              .then(data => {
                if(data.result === 'SUCCESS'){
                  Swal.fire('Update Account', 'Account Updated Successfully', 'success').then(
                      () => {
                        this.getAllAccounts()
                      }
                  )
                }else{
                  Swal.fire('Update Account', 'Failed to Updated Account', 'error')
                }

              })
        }
      })

    },

    showConnectedEntities(accountId){
      let accountName = this.allAccounts.find(x=> x.id == accountId).account_name
      this.storeLoad.setVisible(true)
      EntityDal.getAccountEntities(accountId)
          .then(data => {
            this.storeLoad.setVisible(false)

            let entities = ''
            data.entities.data.forEach(row => {
              entities +=`<p style="width:100%; text-align: left">${row.entity_id} : ${row.entity_name}</p>`
            })
            Swal.fire({
              title: `${accountName} connected entities :`,
              html: entities
            })
          })
    },

    showAccountUserGroups(accountId){
      let accountName = this.allAccounts.find(x=> x.id == accountId).account_name
      this.storeLoad.setVisible(true)
      UserGroupDal.getAccountUserGroups(accountId)
          .then(data => {

            this.storeLoad.setVisible(false)
            let userGroupsList = ''
            data.userGroups.data.forEach(row => {
              userGroupsList +=`<p>${row.user_group_name} : ${row.type_name}</p>`
            })
            Swal.fire({
              title: `${accountName} connected user-groups :`,
              html: userGroupsList
            })
          })
    }
  }

}
</script>

<style scoped>

</style>