<template>
  <div class="docz-component">
    <div class="table-area">
      <table id="datatable" class="table table-hover non-editable"
      ></table>
    </div>
  </div>
</template>

<script>
import AccountingDal from "@/dal/accounting_dal"
import {useLoadStore} from "@/stores/load"
import $ from "jquery"
import Swal from "sweetalert2";
import {useEntityStore} from "@/stores/entity"
import readXlsxFile from 'read-excel-file'
import JSZip from "jszip";
import * as XLSX from "@formulajs/formulajs"
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Vue from "vue";

export default {
  name: 'AccountingDataOnBoarding',
  props: {
    subMenuId: Number,
    entityId: Number
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      entityStore: useEntityStore(),
      allFilesData: [],
      selectedFileData: null,
      buttonsInitiated: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 50,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
        dictDefaultMessage: 'Drag and drop your priority file here or click to upload' // Custom placeholder text
      }
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getLedgerUploadedFiles()
  },

  watch: {
    entityId: function (newVal, oldVal) {
      this.getLedgerUploadedFiles()
    },
  },

  methods: {
    getLedgerUploadedFiles(){
      this.storeLoad.setVisible(true)
      AccountingDal.getAccountingUploadedFiles(this.entityStore.getCurrentEntity, this.subMenuId)
          .then(data => {
            this.storeLoad.setVisible(false)
            this.allFilesData = data.data
            let dataColumns = [];
            let dataRows = [];
            data.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            })

            dataColumns.push({ title: "" });

            this.allFilesData.forEach((row) => {
              let dataRow = [];
              data.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            });

            let that = this;
            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 5,
              //stateSave: true,
              "aaSorting": [],
              columnDefs: [
                {
                  targets: -1,
                  width: "80px",
                  render: function (data, type, row) {
                    return `<button class="icon-btn"><i class='fa fa-close' ></i></button>`;
                  },
                },
                {
                  targets: [1,3,6,7,10,12],
                  visible: false,
                  searchable: false,
                }
              ],
              drawCallback: function (settings) {
                  that.initButtons()
              },
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: "New File",
                  action: function (e, dt, node, config) {
                    that.proceedAction("add", 0);
                  },
                },
              ],
              bDestroy: true,
            });
          })
    },

    initButtons(){
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction('delete', event.target.closest("tr").cells[0].innerText)
        });
      });
    },

    proceedAction(actionType, id){
      let that = this

      if(actionType === 'delete'){
        Swal.fire({
          title: 'Are you sure you want to delete the file?',
          confirmButtonText: 'Approve',
          showCancelButton: true,
          showConfirmButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            let selectedFile = that.allFilesData.filter(item => item.id == id)[0]
            that.storeLoad.setVisible(true)

            AccountingDal.deleteAccountingUploadedFile(selectedFile.id,selectedFile.template_destination_table)
                .then(data => {
                  if(data.result === 'SUCCESS'){
                    that.storeLoad.setVisible(false)
                    Swal.fire('File deleted successfully')
                        .then(() => {
                          that.storeLoad.setVisible(true)
                          that.getLedgerUploadedFiles()
                        })
                  }else{
                    Swal.fire('Failed to delete the file . Please contact your system administrator')
                  }
                })
          }
        })
      }

      if(actionType === 'add'){
        that.storeLoad.setVisible(true)
        AccountingDal.getAccountingEtlTemplates(that.subMenuId)
            .then( data => {
              let etlOptions = `<option value='' disabled selected>Select template ...</option>`
              data.data.forEach(etl => {
                etlOptions += `<option value='${etl.id}'>${etl.template_name}</option>`
              })
              that.storeLoad.setVisible(false)

              Swal.fire({
                title: "Select file template",
                html: `<select id="etl-options-select" class="swal2-select" style="width: 80%; margin-top: 10px;">${etlOptions}</select>
                        <div style="width:452px" id="dropZoneContainer"></div>`,
                showCancelButton: true,
                didOpen(popup) {
                  Swal.disableButtons()
                  let etlOptionsSelect = Swal.getPopup().querySelector('#etl-options-select')
                  etlOptionsSelect.addEventListener("change", function() {
                    if(etlOptionsSelect.value != '' && etlOptionsSelect.value != '0') {
                      let dropZoneContainer = document.getElementById('dropZoneContainer')

                      let DropZone = Vue.extend(
                          Vue.component('vue2Dropzone', vue2Dropzone)
                      )
                      let dropZoneInstance = new DropZone({propsData: {options: that.dropzoneOptions, id: "dropzone"}})
                      dropZoneInstance.$mount()
                      dropZoneInstance.$on('vdropzone-success', async (file) => {
                        await that.fileAdded(file)

                      })
                      dropZoneContainer.appendChild(dropZoneInstance.$el)
                    }
                  });

                },
                preConfirm: () => {
                  const selectedEtlId = Swal.getPopup().querySelector('#etl-options-select').value
                  if(selectedEtlId === ''){
                    Swal.showValidationMessage('Please select the template');
                  }else if(!that.selectedFileData){
                    Swal.showValidationMessage('Please select the file');
                  }
                  else{
                    return { selectedEtlId }
                  }
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  that.uploadAccountingDataFile(that.selectedFileData, result.value.selectedEtlId)
                }
              })

            })
      }
    },

    async fileAdded(file) {
      let that = this
      if (that.validateExcelFile(file)) {
        const isValidExcel = await that.isRealExcelFile(file)
        if (isValidExcel) {

          readXlsxFile(file).then((rows) => {

            const isValidExcelContent = that.validateExcelContentRows(rows)
            if (isValidExcelContent) {
              let fileReader = new FileReader()
              let base64

              fileReader.onload = async function (fileLoadedEvent) {

                base64 = fileLoadedEvent.target.result;
                base64 = base64.replace(`data:${file.type};base64,`, '')

                that.selectedFileData = {
                  entityId: that.entityStore.getCurrentEntity,
                  fileName: file.name,
                  fileType: file.type,
                  fileData: base64
                }

                Swal.enableButtons()
              }
               fileReader.readAsDataURL(file)
            } else {
              $("#errorMessage").html('Invalid file content')
            }
            that.storeLoad.setVisible(false)
          })
        }
      } else if (that.validateCSVFile(file)) {
        const isValidCsv = await that.isRealCSVFile(file)
        if (isValidCsv) {
          const isValidCsvContent = await that.validateCSVContent(file)
          if (isValidCsvContent) {
            const reader = new FileReader();
            reader.onload = function (e) {
              const csv = e.target.result;
              const jsonArray = that.csvToJson(csv);
              console.log(jsonArray);
              that.storeLoad.setVisible(false)
            };
            reader.readAsText(file)
          }
        }
      }
    },

    uploadAccountingDataFile(uploadFileData, selectedEtlId){
      this.storeLoad.setVisible(true)
      AccountingDal.addAccountingUploadedFile(uploadFileData.entityId, null, uploadFileData.fileName, uploadFileData.fileType, uploadFileData.fileData, 'ledgerdata', selectedEtlId)
          .then(data => {
            this.selectedFileData = null
            this.storeLoad.setVisible(false)
            if(data.result === 'SUCCESS'){
              Swal.fire('Upload ledger file', 'File uploaded successfully', 'success')
                  .then(() => {
                    this.getLedgerUploadedFiles()
                  })
            }else{
              Swal.fire('Upload ledger file', 'File upload failed . Please contact your system administrator', 'error')
            }
          })
    },

    csvToJson(csv) {
      const lines = csv.split('\n');
      const result = [];

      // Assuming first row contains headers
      const headers = lines[0].split(',');

      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(',');

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentLine[j]?.trim();
        }

        // Avoid adding empty lines
        if (Object.keys(obj).length && Object.values(obj).some(value => value !== '')) {
          result.push(obj);
        }
      }

      return result;
    },

    validateCSVFile(file) {
      const validMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      return validMimeTypes.includes(file.type) || fileExtension === 'csv';
    },

    validateExcelFile(file) {
      const validMimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      return validMimeTypes.includes(file.type) || ['xls', 'xlsx'].includes(fileExtension);
    },

    async isRealExcelFile(file) {
      try {
        const zip = await JSZip.loadAsync(file);
        // Check if required Excel files exist within the ZIP structure
        return zip.files['xl/workbook.xml'] !== undefined;
      } catch (error) {
        return false; // The file is not a valid ZIP/Excel file
      }
    },

    async isRealCSVFile(file) {
      try {
        const text = await file.text();
        // Check if the content has rows and columns separated by commas
        const lines = text.split('\n').filter(line => line.trim() !== '');

        // Basic CSV structure check: multiple rows and consistent column count
        const firstLine = lines[0];
        const numOfColumns = firstLine.split(',').length;

        for (let i = 1; i < lines.length; i++) {
          const columns = lines[i].split(',');
          if (columns.length < numOfColumns) {
            return false; // Inconsistent column count
          }
        }

        // Further validation could include ensuring no non-text binary data is present
        return true;
      } catch (error) {
        console.error('Error reading CSV file:', error);
        return false; // The file is not valid CSV
      }
    },

    async validateCSVContent(file) {
      const text = await file.text();
      const lines = text.split('\n');

      for (let line of lines) {
        if (this.containsMaliciousContent(line)) {
          return false;  // Found malicious content
        }
      }

      return true;
    },

    validateExcelContentRows(rows) {
      /*
      for (const row of rows) {
        for(const cell of row){
          if (this.containsMaliciousContent(cell)) {
            console.log(`Invalid content row : ${row}`)
            return false;  // Found malicious content
          }
        }
      }*/

      return true
    },

    async validateExcelContent(file) {
      try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });

        // Loop through all sheets and their contents
        for (const sheetName of workbook.SheetNames) {
          const sheet = workbook.Sheets[sheetName];
          const sheetContent = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          for (const row of sheetContent) {
            for (const cell of row) {
              if (this.containsMaliciousContent(cell)) {
                return false;  // Found malicious content
              }
            }
          }
        }

        return true;
      } catch (error) {
        console.error('Error reading Excel content:', error);
        return false;
      }
    },

    containsMaliciousContent(content) {
      const maliciousPatterns = [
        /(\b(SELECT|INSERT|DELETE|UPDATE|DROP|ALTER|TRUNCATE|CREATE|EXEC)\b)/i,  // SQL keywords
    //    /--|\/\*|\*\/|;/g,  // SQL comment and query termination
        /<script[\s\S]*?>[\s\S]*?<\/script>/i,  // Script tags
        /(onload|onerror|onmouseover|onclick|onfocus)=/i,  // JavaScript events
        /['"](\s|\n)*?OR(\s|\n)+['"]/i,  // SQL injection via OR condition
        /['"](\s|\n)*?UNION(\s|\n)+SELECT/i,  // UNION-based SQL injection
   //     /[`~!#$%^&*(){}[\];<>,]/g  // Characters often associated with harmful input
      ];

      // Check if any pattern matches the content
      for (const pattern of maliciousPatterns) {
        if (pattern.test(content)) {
          console.log(`Invalid content : ${content}`)
          return true;
        }
      }

      return false;
    }


    /*
    selectTemplateFile(selectedEtlId){
      let that = this
      let entityId = that.entityStore.getCurrentEntity
      let uploadFileData
      Swal.fire({
        title: 'Upload new file',
        html: `<input class="inputFile" type="file" accept=".xlsx,.csv">
                           <label id="errorMessage" style="color: red"></label>`,
        width:600,
        showCancelButton: true,

        didOpen(popup) {
          let fileInput = document.getElementsByClassName('inputFile')[0]

          fileInput.addEventListener('change', async () => {
            that.storeLoad.setVisible(true)
            const file = fileInput.files[0]

            if(file){
              if(that.validateExcelFile(file)){
                const isValidExcel = await that.isRealExcelFile(file)
                if(isValidExcel){

                  readXlsxFile(file).then((rows) => {

                    const isValidExcelContent = that.validateExcelContentRows(rows)
                    if(isValidExcelContent){
                      let fileReader = new FileReader()
                      let base64

                      fileReader.onload = function(fileLoadedEvent) {

                        base64 = fileLoadedEvent.target.result;
                        base64 = base64.replace(`data:${file.type};base64,`,'')
                        uploadFileData = {
                          entityId: entityId,
                          fileName: file.name ,
                          fileType: file.type ,
                          fileData: base64
                        }

                      }
                      fileReader.readAsDataURL(file)
                      //console.log(rows);                    // `rows` is an array of rows                    // each row being an array of cells.
                    }else{
                      $("#errorMessage").html('Invalid file content')
                    }
                    that.storeLoad.setVisible(false)
                  })
                }
              }else if (that.validateCSVFile(file)){
                const isValidCsv = await that.isRealCSVFile(file)
                if(isValidCsv){
                  const isValidCsvContent = await that.validateCSVContent(file)
                  if(isValidCsvContent){
                    const reader = new FileReader();
                    reader.onload = function (e) {
                      const csv = e.target.result;
                      const jsonArray = that.csvToJson(csv);
                      console.log(jsonArray);
                      that.storeLoad.setVisible(false)
                    };
                    reader.readAsText(file)
                  }
                }
              }

              that.storeLoad.setVisible(false)
            }
          })
        },
        preConfirm(inputValue) {
          if(document.getElementsByClassName('inputFile')[0].value === ''){
            $("#errorMessage").html('Please select the file')
            return false
          }else{

            return uploadFileData
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          that.uploadAccountingDataFile(result.value, selectedEtlId)
        }
      })
    },
*/

  }
}
</script>

<style scoped>
.swal2-select {
  max-width: 80%;
}
</style>