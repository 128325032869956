<template>

  <section class="login-section">

    <figure class="login-layout">
      <div class="logo-and-title">
        <figure class="logo-area">
          <svg class="v-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path d="M72.83,1,32.46,59.42h21L95.22,1Zm-68,30.82L25.19,99l13-18-13-49.16Z"/>
          </svg>
          <svg class="viewz-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 100">
            <path :fill="svgFill" d="M90.19,31.92l-14,65.51H94.28l14-65.51Zm13.2-8.12a11.14,11.14,0,0,0,8.18-3.28A11,11,0,0,0,115,12.4a10.62,10.62,0,0,0-3.43-8A10.94,10.94,0,0,0,103.39,1a11.07,11.07,0,0,0-8,3.41,10.62,10.62,0,0,0-3.43,8,11,11,0,0,0,3.43,8.12A11.26,11.26,0,0,0,103.39,23.8ZM339.5,31.92H280.78l-3.43,16.25h27L262.83,97.43h61l3.43-16.25h-29.3Zm-85.47,0L233.57,62.71,223.28,30.48,199,62.71l-7.26-30.79H172.88l17.15,67,27.18-34.33,12.41,34.33,45.91-66.95ZM143.2,30.48q-16,0-25.74,10.35t-9.63,25.81q0,14.42,9.63,23.32t24.81,8.91q12.14,0,19.53-2.88l3.56-16.9a48.36,48.36,0,0,1-19.53,3.8,25.27,25.27,0,0,1-13.46-3.41A12.88,12.88,0,0,1,126.31,69h46.05q3-18-5.68-28.17T143.2,30.48Zm-16.1,26.6a16.62,16.62,0,0,1,5.67-9.18,15.63,15.63,0,0,1,9.9-3.4q5.67,0,9,3.4t3.17,9.18ZM.5,31.82,20.9,99l13-18-13-49.16ZM68.55,1,28.17,59.42h21L90.93,1Z"/>
          </svg>
        </figure>

        <h1 :class="welcomeClass">{{welcomeMessage}}</h1>
      </div>

      <div class="login-content">
        <div class="form-box">
          <div class="login-area field-area">
            <figure class="field-item">
              <label for="userName">User Name</label>
              <input type="email" id="userName" name="" placeholder="Email Address" tabindex="10" v-on:keyup.enter="doLogin" v-model="userName" required>
            </figure>
            <figure class="field-item">
              <label>Password</label>
              <input type="password" name="" placeholder="Password" v-model="password" v-on:keyup.enter="doLogin" required>
            </figure>

            <figure class="submit-area">
              <b-button variant="primary" class="login-btn" @click="doLogin">Login</b-button>
            </figure>
          </div>
        </div>

        <p class="no-account-link">Don't have an account?<br>
          <a class="register-link" @click="$emit('open-signup')">Register here</a>
        </p>
      </div>

    </figure>

    <div class="app-version-data">Version : {{appVersion}}</div>

  </section>
</template>

<script>

import {useAuthStore} from "@/stores/auth"
import {useLoadStore} from "@/stores/load"
import AuthDal from "@/dal/auth-dal"
import Swal from 'sweetalert2'

import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import env from "@/env"

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

export default {
    name: "Login",
    data(){
        return {
            userName : '',
            appVersion: env.version,
            password : '',
            storeAuth: useAuthStore(),
            storeLoad: useLoadStore(),
            welcomeMessage: env.environment === 'stage' ? '!!! STAGE ENVIRONMENT !!!' : 'Login to your account',
            svgFill :env.environment === 'stage' ? 'red' : '',
            welcomeClass: env.environment
        }
    },

  methods: {
         async doLogin() {
             this.storeLoad.setVisible(true)
             await AuthDal.login(this.userName, this.password).then(
                 loginResponse => {

                   sessionStorage.setItem('jwtToken', loginResponse.jwToken)
                   sessionStorage.setItem('appSessionId', loginResponse.appSessionId)
                     this.storeLoad.setVisible(false)
                     let that = this
                     if(loginResponse.result === 'SUCCESS'){
                       let response = loginResponse.data
                       if(response.otp_enabled){
                         Swal.fire({
                           title: 'OTP Code',
                           input:"text",
                           inputAttributes: {
                             style: 'text-align: center;' // Inline CSS to center align text
                           },
                           customClass: {
                             input: 'swal-input-center' // Add a custom class if needed
                           },
                           showDenyButton: false,
                           showCancelButton: true,
                           confirmButtonText: 'Send',

                         }).then(async (result) => {
                           if (result.isConfirmed) {

                             this.storeLoad.setVisible(true)
                             await AuthDal.validateOtp(response.userId, result.value)//document.getElementById('otpCode').value)
                                 .then(data => {
                                   if(data.result === 'SUCCESS'){
                                     AuthDal.getUserInfo(response.userId).then(
                                         userData => {
                                           that.storeAuth.setUserName(this.userName)
                                           that.storeAuth.setUserData({
                                             name: userData.data.user_description ,
                                             otpEnabled: userData.data.otp_enabled,
                                             phone: userData.data.phone_number,
                                             userGroupId: userData.data.user_group_id,
                                             profileId: userData.data.profile_id,
                                             userId: userData.data.user_id,
                                             userLevel: userData.data.user_level_id,
                                             userName: userData.data.user_name,
                                             userType: userData.data.user_level_name,
                                             accountId: userData.data.account_id,
                                             accountName: userData.data.account_name,
                                             firstName: userData.data.first_name,
                                             lastName: userData.data.last_name})
                                           that.storeAuth.setAuthorisation(userData.result === 'SUCCESS')
                                           this.storeLoad.setVisible(false)
                                         })

                                   }else{
                                     this.storeLoad.setVisible(false)
                                     Swal.fire(data.message)
                                   }
                                 })
                           }
                         })
                       }else{
                         this.storeLoad.setVisible(true)
                         AuthDal.getUserInfo(response.userId).then(
                             userData => {
                               that.storeAuth.setUserName(this.userName)
                               that.storeAuth.setUserData({
                                 name: userData.data.user_description ,
                                 otpEnabled: userData.data.otp_enabled,
                                 phone: userData.data.phone_number,
                                 userGroupId: userData.data.user_group_id,
                                 profileId: userData.data.profile_id,
                                 userId: userData.data.user_id,
                                 userLevel: userData.data.user_level_id,
                                 userName: userData.data.user_name,
                                 userType: userData.data.user_level_name,
                                 accountId: userData.data.account_id,
                                 accountName: userData.data.account_name,
                                 firstName: userData.data.first_name,
                                 lastName: userData.data.last_name})
                               that.storeAuth.setAuthorisation(true)
                               this.storeLoad.setVisible(false)
                             }
                         )
                       }
                     }else{
                         Swal.fire('Login','Invalid user or password','error')
                     }
                 }
             )
         },
    }
}
</script>

<style scoped>
.stage{
  color: red !important;
}

.swal-input-center {
  text-align: center;
}
</style>