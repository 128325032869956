import { defineStore } from "pinia"

export const useGlAccountStore = defineStore({
    id: 'glAccountStore',

    state: () => ({
        glAccountObject : null,
        allGlAccounts : null,
    }),

    getters:{
        getGlAccountObject:(state) => {
            return state.glAccountObject
        },

        getAllGlAccounts:(state) => {
            return state.allGlAccounts
        }
    },

    actions:{
        setAllGlAccounts(allGlAccounts){
            this.allGlAccounts = allGlAccounts
        },

        setGlAccountObject(glAccountObject){
            this.glAccountObject = glAccountObject
        },
    }
})