<template>
  <div>
    <p>{{docType}}</p>
    <button @click="goBack()" >
      <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
    </button>
  </div>
</template>

<script>
export default {
  props:{
    docType: String
  },
  name: "DocumentsManagement",

  data(){
    return {
    }
  },

  methods: {
    goBack() {
      this.$emit('showDocsMenu')
    },
  }
}
</script>

<style scoped>

</style>