<template>
  <pbi-report :user-group-id="userGroupId" :screen-name="screenName"></pbi-report>
</template>

<script>
import PbiReport from "@/screens/PbiReport.vue"

export default {
  name: 'HrDetails',
  props: {
    userGroupId: Number,
    screenName: String
  },
  components:{
    PbiReport
  },
}
</script>

<style scoped>

</style>