<template>
  <div>
    ACCOUNT CONFIG
    <div class="container" >
      <button @click="goBack()">
        <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
      </button>
      <div class="form-div">
        <b-form @submit.prevent="updateAccount">

          <div class="parent">
            <div class="div1">

              <b-form-group id="input-group-vat-number" label="Your Company VAT Number :" label-for="input-vat-number">
                <b-form-input id="input-vat-number" v-model="form.accountId" class="form-input" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-account-name" label="Account / Company Name :" label-for="input-account-name">
                <b-form-input id="input-account-name" v-model="form.accountName" class="form-input" placeholder="Enter Company Name" required></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-account-name-en" label="Account / Company Name :" label-for="input-account-name-en">
                <b-form-input id="input-account-name-en" v-model="form.accountNameEn" class="form-input" placeholder="Enter Company Name EN"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-account-description" label="Account / Company Name :" label-for="input-account-description">
                <b-form-input id="input-account-description" v-model="form.description" class="form-input" placeholder="Enter Company Description"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-email" label="Email address:" label-for="input-email"  >
                <b-form-input id="input-email" v-model="form.email" class="form-input" type="email" placeholder="Enter email" required></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-first-name" label="Your Name:" label-for="input-first-name">
                <b-form-input id="input-first-name" v-model="form.firstName" class="form-input" placeholder="Enter First Name" required ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-last-name" label="Your Last Name:" label-for="input-last-name">
                <b-form-input id="input-last-name" v-model="form.lastName" class="form-input" placeholder="Enter Last Name" required ></b-form-input>
              </b-form-group>

            </div>
            <div class="div2">
              <b-form-group id="input-group-city-name" label="City Name :" label-for="input-city-name">
                <b-form-input id="input-city-name" v-model="form.cityName" class="form-input" placeholder="Enter City Name" ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-street-name" label="Street Name :" label-for="input-street-name">
                <b-form-input id="input-street-name" v-model="form.streetName" class="form-input" placeholder="Enter Street Name" ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-house-number" label="House Number :" label-for="input-house-number">
                <b-form-input id="input-house-number" v-model="form.houseNumber" class="form-input" placeholder="Enter House Number" ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-zip-code" label="House ZIP Code :" label-for="input-zip-code">
                <b-form-input id="input-zip-code" v-model="form.zipCode" class="form-input" placeholder="Enter ZIP code" ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-po-box" label="House PO Box  :" label-for="input-po-box">
                <b-form-input id="input-po-box" v-model="form.poBox" class="form-input" placeholder="Enter PO Box" ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-phone-number" label="Phone Number :" label-for="input-phone-number"  >
                <b-form-input id="input-phone-number" v-model="form.phoneNumber" class="form-input" placeholder="Enter Phone Number" required></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-user-description" label="User Description :" label-for="input-user-description"  >
                <b-form-input id="input-user-description" v-model="form.userDescription" class="form-input" placeholder="Enter User Description" ></b-form-input>
              </b-form-group>

            </div>
          </div>

        <b-button type="submit" variant="primary">Update Data</b-button>
        <b-button variant="light" @click="goBack">Cancel</b-button>
        </b-form>

      </div>

    </div>
  </div>
</template>

<script>
import AccountDal from "@/dal/account_dal"
import { useAuthStore } from "@/stores/auth"
import {useLoadStore} from "@/stores/load"
import Swal from "sweetalert2";

export default {
  name: "AccountConfig",

  data(){
    return {
      storeLoad: useLoadStore(),
      storeAuth: useAuthStore(),
      form:{
        id: 0,
        accountId: '',
        accountName: '',
        accountNameEn: '',
        description: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        cityName: '',
        streetName: '',
        houseNumber: '',
        zipCode: '',
        poBox: '',
        userDescription: '',
        userId: 0
      }
    }
  },

  mounted() {
    this.storeLoad.setVisible(true)
    const accountId = this.storeAuth.getUserData.accountId
    const userId = this.storeAuth.getUserData.userId
    AccountDal.getAccountDetails(accountId, userId )
        .then(data => {
          this.form.id = data.account.data.id
          this.form.accountId = data.account.data.account_id
          this.form.accountName = data.account.data.account_name
          this.form.accountNameEn = data.account.data.account_name_en
          this.form.description = data.account.data.description
          this.form.email = data.account.data.email
          this.form.phoneNumber = data.account.data.phone_number
          this.form.firstName = data.account.data.first_name
          this.form.lastName = data.account.data.last_name
          this.form.cityName = data.account.data.city_name
          this.form.streetName = data.account.data.street_name
          this.form.houseNumber = data.account.data.house_number
          this.form.zipCode = data.account.data.zip_code
          this.form.poBox = data.account.data.po_box
          this.form.userDescription = data.account.data.user_description
          this.form.userId = userId

          this.storeLoad.setVisible(false)
        })
  },
  methods:{
    updateAccount(){
      Swal.fire({
        title: 'Account details update',
        html: 'Please approve to update the data',
        confirmButtonText: 'Approve',
        showCancelButton: true,
        showConfirmButton: true
      }).then((result) => {
        if(result){
          AccountDal.updateAccount(this.form)
              .then(data => {
                if(data.result === 'SUCCESS'){
                  Swal.fire(
                      'Account details update' ,
                      'Data updated successfully' ,
                      'success'
                  ).then(() => {
                    this.$emit('goBack')
                  })
                }else{
                  Swal.fire(
                      'Account details update' ,
                      'Failed to update the data . Please try again or cal your system administrator' ,
                      'error'
                  )
                }
              })
        }
      })

    },
    goBack(){
      this.$emit("goBack");
    }
  }
}

</script>

<style scoped>
.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }

.form-div{
  margin-top: 20px;
  width:80%;
  margin-left: 10%;
  border: 3px solid #f1f1f1;
  text-align: left;
  padding: 20px;
}

.form-input{
  color: blue;
}
</style>