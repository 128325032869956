
<template>
  <div class="report_wrapper">
    <div  v-if="isNoData">
      <p style="padding: 20px;">No report available for this entity</p>
    </div>
    <div id="report-container" class="report_container" style="visibility: hidden"></div>

  </div>
</template>

<script>

import PowerBiDal from '@/dal/powerbi_dal'
import {useLoadStore} from '@/stores/load'
import * as models from 'powerbi-models'
import $ from 'jquery'

export default {
  name: 'PbiReport',
  props: {
    userGroupId: Number,
    screenName: String,
    dashboardDetails: Object
  },
  data(){
    return {
      workspaceId: '',
      reportId: '',
      storeLoad: useLoadStore(),
      isNoData: false
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)

    if(this.dashboardDetails){
      this.workspaceId = this.dashboardDetails.workspaceId
      this.reportId = this.dashboardDetails.reportId
      this.embedPowerBIReport()
    }else{
      this.getDashboardDetails()
    }
  },
  methods: {

    async getDashboardDetails() {
      this.storeLoad.setVisible(true)
      let dashboardDetails = await PowerBiDal.getDashboardDetails(this.userGroupId, this.screenName)
      if(dashboardDetails.result === 'SUCCESS' && dashboardDetails.data[0] ) {
        this.workspaceId = dashboardDetails.data[0].workspace_id
        this.reportId = dashboardDetails.data[0].report_id
        await this.embedPowerBIReport()
      }else{
        this.isNoData = true
        this.storeLoad.setVisible(false)
      }
    },

    async embedPowerBIReport() {
      if(!this.dashboardDetails) {
        $("#report-container").css("visibility", "hidden")
      }
      const powerbi = window["powerbi"];
      let reportContainer = $("#report-container").get(0);

      // Initialize iframe for embedding report
      powerbi.bootstrap(reportContainer, { type: "report" });

      let connectionDetails= await PowerBiDal.getPbiReportConnectionDetails(this.workspaceId, this.reportId)
      if(connectionDetails.result === 'SUCCESS'){
        let embedData = connectionDetails.data
        // Create a config object with type of the object, Embed details and Token Type
        let reportLoadConfig = {
          type: "report",
          tokenType: models.TokenType.Embed,
          accessToken: embedData.accessToken,

          // Use other embed report config based on the requirement. We have used the first one for demo purpose
          embedUrl: embedData.embedUrl[0].embedUrl,

          // Enable this setting to remove gray shoulders from embedded report
          settings: {
              background: models.BackgroundType.Transparent,
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
              layoutType: models.LayoutType.Custom,
              customLayout: {
                // pageSize: {
                //   type: models.PageSizeType.Custom,
                //   width: 1600,
                //   height: 1200
                // },
                // displayOption: models.DisplayOption.FitToWidth
                displayOption: models.DisplayOption.FitToPage
              },
              localeSettings: {
                language: "en-us"
              }
          }
        };

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        let tokenExpiry = embedData.expiry;

        // Embed Power BI report when Access token and Embed URL are available
        let report = powerbi.embed(reportContainer, reportLoadConfig);


        let that = this
        // Triggers when a report schema is successfully loaded
        report.on("loaded", function () {


          // Clear any other loaded handler events
          report.off("loaded");

        });

        // Clear any other rendered handler events
        report.off("rendered");

        // Triggers when a report is successfully embedded in UI
        report.on("rendered", function () {
          that.storeLoad.setVisible(false)
          $("#report-container").css("visibility","visible")
        });

        // Clear any other error handler events
        report.off("error");

        // Handle embed errors
        report.on("error", function (event) {
          let errorMsg = event.detail;
          console.error(errorMsg);
          return;
        });


      }else{
        reportContainer.innerHTML = connectionDetails.message
        this.storeLoad.setVisible(false)
      }

    },

  }
}

</script>


<style scoped>
.report_wrapper{
  height: 100%;
}

.report_container{
  width: 100%;
  height: 100%
}
</style>