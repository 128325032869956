import {ApiDal} from './api_dal'

class AccountingDal extends ApiDal{

    async getAccountingEtlTemplates(subMenuId){
        return this.callApi('post', '/api/accounting/getAccountingEtlTemplates', null, {subMenuId})
    }

    async getAccountingUploadedFiles(entityId, subMenuId){
        return this.callApi('post', '/api/accounting/getAccountingUploadedFiles', null, {entityId, subMenuId})
    }

    async addAccountingUploadedFile(entityId, bankEntityId, fileName, fileType, fileData, moduleName, selectedEtlId){
        return this.callApi('post', '/api/accounting/addAccountingUploadedFile', null, {entityId, bankEntityId, fileName, fileType, fileData, moduleName, selectedEtlId})
    }

    async deleteAccountingUploadedFile(fileId , tableName){
        return this.callApi('post', '/api/accounting/deleteAccountingUploadedFile', null, {fileId , tableName})
    }
}

export default new AccountingDal()