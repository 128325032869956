import {ApiDal} from './api_dal'

class AccountDal extends ApiDal{
    async getAllAccounts(){
        return this.callApi('get', '/api/accounts/getAllAccounts', 'accounts', null)
    }

    async getAccountDetails(accountId, userId){
        return this.callApi('post', '/api/accounts/getAccountDetails', 'accounts', {accountId, userId})
    }

    async updateAccount(accountData){
        return this.callApi('post', '/api/accounts/updateAccount', 'accounts', {accountData})
    }

    async updateAccountName(accountName , accountNameEn, accountId){
        return this.callApi('post', '/api/accounts/updateAccountName', null, {accountName , accountNameEn, accountId})
    }

    async getLedgerUploadedFiles(entityId){
        return this.callApi('post', '/api/accounts/getLedgerUploadedFiles', null, {entityId})
    }

}

export default new AccountDal()