<template>
  <div class="docz-component">
    <div class="table-area">
      <div class="datatable"></div>
      <new-entity
          v-if="showNewEntity"
          @newEntityAdded="newEntityAdded"
          @closeNewEntityModal="showNewEntity = false"
          :company-vat-number="selectedVatNumber"
          :user-group-id="null"
          :parent-entity-id="entityId"
          :entity-type="'supplier'"
      ></new-entity>
    </div>

  </div>
</template>

<script>
import EntityDal from "@/dal/entity_dal"
import SuppliersDal from "@/dal/suppliers_dal"
import { useLoadStore } from "@/stores/load"
import { useAuthStore } from "@/stores/auth"
import NewEntity from "@/components/entities/NewEntity.vue"
import { useEntityStore} from "@/stores/entity"

import {entityManagerClass} from "@/components/entities/entityManager"

import $ from "jquery";
import Swal from "sweetalert2";
import CustomerDal from "@/dal/customer_dal";

export default {
  name: "Suppliers",
  props: {
    entityId: Number,
    userType: String
  },
  components: {
    NewEntity
  },
  watch: {
    entityId: function (newVal, oldVal) {
      this.storeLoad.setVisible(true)
      this.getSuppliers(0)    }
  },
  data(){
    return {
      showNewEntity: false,
      selectedVatNumber: '',
      authStore: useAuthStore(),
      storeLoad: useLoadStore(),
      entityStore: useEntityStore(),
      allExistingSuppliers: null,
      currentEntityId: this.entityId,
      entityManager: new entityManagerClass()
    }
  },
  // created() {
  //   eventBus.$on('selectedEntityChanged',(args) =>{
  //     this.storeLoad.setVisible(true)
  //     this.getSuppliers(0)
  //     console.log(this.$attrs['screen-name'])
  //   })
  // },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getSuppliers(0)
  },

  methods:{
    getSuppliers(mode){
      const currentEntityId = this.entityStore.getCurrentEntity
      EntityDal.getEntitySuppliers(this.authStore.getUserData.userId, currentEntityId).then( data => {
        this.createSuppliersGrid(data, mode)
      })
    },

    createSuppliersGrid(data, mode){
      this.allExistingSuppliers = data.entities.data
      let dataColumns = []
      let dataRows = []
      data.entities.columnList.forEach((title) => {
        dataColumns.push({
          title: title
              .split("_")
              .join(" ")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
              ),
        });
      });
      //dataColumns.push({ title: "" }); // add column for buttons
      data.entities.data.forEach((row) => {
        let dataRow = [];
        data.entities.columnList.forEach((value) => {
          dataRow.push(row[value]);
        });
        dataRows.push(dataRow);
      });
      let that = this
      $(".datatable").html("")
      $(".datatable").append('<table id="datatable" class="table table-hover table-striped non-editable"></table>')
      $("#datatable").DataTable({
        data: dataRows,
        columns: dataColumns,
        pageLength: 10,
        columnDefs: [
          {
            targets: -1,
            width: "100px",
            render: function (data, type, row) {
              return `<button class="icon-btn grid-btn" value="edit"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                            ${that.entityId ? '' : "<button class='icon-btn grid-btn' value='link'><i class='fa fa-link' style='font-size: 16px; color: #334249'></i></button>" }
                          <button class="icon-btn grid-btn" value="delete"><i class='fa fa-trash' style='font-size: 16px; color: #334249'></i></button>  `;
            },
          },
          {
            targets: [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
            visible: false,
            searchable: false,
          }
        ],
        drawCallback: function (settings) {
          that.initButtons()
        },
        dom: "Blfrtip",
        buttons: [
          "copy",
          "csv",
          "print",
          "excel",
          {
            text: "New Supplier",
            action: function (e, dt, node, config) {
              that.setSupplierType();
            },
          }
        ],
        bDestroy: true,
      })
      this.storeLoad.setVisible(false)
    },

    setSupplierType(){
      let that = this
      let inputOptions = new Promise(function (resolve){
        resolve({
          'api': "By API",
          'manually': 'Manually Input'
        })
      })

      Swal.fire({
        title: "Add New Supplier",
        input: "radio",
        inputOptions: inputOptions,
        showCancelButton: true,
        inputValue: 'api',
        inputValidator: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              resolve();
            } else {
              reject('You need to select something!');
            }
          }).catch(function(rej) {
            console.log(rej);
          });
        }
      }).then(function(result) {
        if(result.value === 'api'){
          that.addSupplierByApi()
        }else{
          that.addSupplierManually()
        }
      })
    },

    addSupplierManually(){
      let that = this
      Swal.fire({
        title: 'Add New Supplier',
        html: this.entityManager.getNewEntityModalHtml(),
        showCancelButton: true,
        preConfirm: function() {return that.entityManager.preConfirmNewEntity()}
      }).then(function (result) {
        if(result.isConfirmed){
          let entityDetails = [result.value[0],result.value[1],'',result.value[7],'active','',null,null,null,null,null,null,result.value[2],result.value[3],result.value[4],result.value[5],null,result.value[6],null,null,null,null,that.authStore.getUserData.userId,null]

          that.storeLoad.setVisible(true)
          EntityDal.addEntity(entityDetails)
              .then(data => {
                SuppliersDal.connectSuppliersToEntity(data.entities.data.id, that.authStore.getUserData.accountId, that.entityId )
                    .then(data => {
                      that.storeLoad.setVisible(false)
                      if(data.result === 'SUCCESS'){
                        Swal.fire('Supplier added successfully')
                            .then(() => {
                              that.storeLoad.setVisible(true)
                              that.getSuppliers(0)
                            })
                      }else{
                        Swal.fire('Failed to add new supplier . Please contact your system administrator')
                      }
                    })
              })
        }

      })
    },

    addSupplierByApi() {
      Swal.fire({
        title: 'Enter company registration number',
        input: 'text',
        inputLabel: 'Company registration number :',
        inputValue: '',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            const existingSupplier = this.allExistingSuppliers.find( supplier => supplier.entity_id === result.value)
            if(existingSupplier){
              Swal.fire({title : 'Add Supplier', html :  'This supplier already exists in your entity !', icon:  'warning', showCancelButton: false})
            }else{
              this.storeLoad.setVisible(true)
              EntityDal.getAllEntities()
                  .then(data => {
                    this.storeLoad.setVisible(false)
                    let existingEntity = data.entities.data.find( entity => entity.entity_id === result.value)
                    if(existingEntity){
                      Swal.fire({title : 'Add Supplier', html :  'The supplier already exists in the platform . Do you want to connect it ?', icon:  'question', showCancelButton: true})
                          .then(result => {
                            if(result.isConfirmed){
                              this.storeLoad.setVisible(true)

                              SuppliersDal.connectSuppliersToEntity(existingEntity.id.toString(), this.entityId, this.entityId)
                                  .then(data=> {
                                    this.supplierAdded(data)
                                  })
                            }
                          })
                    }else{
                      this.storeLoad.setVisible(true)
                      this.showNewEntity = true
                      this.selectedVatNumber = result.value
                    }
                  })

            }
          }
        }
      })
    },

    supplierAdded(data){
      this.storeLoad.setVisible(false)
      if(data.result === 'SUCCESS'){
        Swal.fire('New supplier', 'The supplier connected successfully', 'success')
            .then(result => {
              this.getSuppliers(1)
            })
      }else {
        Swal.fire('New supplier', "Can't connect the supplier . Please contact system administrator .", 'error')
      }
    },

    initButtons() {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(
              element.value,
              event.target.closest("tr").cells[0].innerText
          );
        });
      });
    },

    proceedAction(actionType,supplierId){
      let selectedSupplier = this.allExistingSuppliers.find( supplier => supplier.id == supplierId)
      switch (actionType){
        case 'edit':
          this.editSupplier(selectedSupplier)
          break;
        default:
          break;
      }
    },

    editSupplier(selectedSupplier){
      Swal.fire({
        title: 'Edit Supplier',
        width: '500px',
        html: `<input type="text" id="supplier-name" style="width:80%" class="swal2-input" placeholder="Supplier Name" value="${selectedSupplier.entity_name}" >
               <input type="text" id="supplier-name-en" style="width:80%"  class="swal2-input" placeholder="Supplier Name ( EN )"  value="${selectedSupplier.entity_name_en || ''}">`,
        showCancelButton: true,
        confirmButtonText: 'Update',
        preConfirm: () => {
          const supplier_name = Swal.getPopup().querySelector('#supplier-name').value
          const supplier_name_en = Swal.getPopup().querySelector('#supplier-name-en').value

          return { supplier_name , supplier_name_en }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.storeLoad.setVisible(true)
          EntityDal.updateEntityName(selectedSupplier.id, result.value.supplier_name, result.value.supplier_name_en)
              .then(data => {
                if(data.entities.data.length === 1){
                  Swal.fire('Update Supplier', 'Supplier Updated Successfully', 'success').then(
                      data => {
                        this.getSuppliers(1)
                      }
                  )
                }else{
                  Swal.fire('Update Supplier', 'Failed to Updated Supplier', 'error')
                }

              })
        }
      })
    },

    newEntityAdded(args){
      if(args.entityId){
        Swal.fire('Supplier added successfully !').then(() => {this.getSuppliers(1)})
        this.showNewEntity = false
        //
        // const addedSupplierId = args.entityId
        // const currentEntityId = this.entityStore.getCurrentEntity
        //
        // SuppliersDal.connectSupplierToEntity(addedSupplierId , currentEntityId)
        //     .then(data => {
        //       if(data.result === 'SUCCESS'){
        //         Swal.fire('Supplier added successfully !').then(() => {this.getSuppliers(1)})
        //       }
        //     })
      }

    },

  }
}

</script>

<style scoped>

</style>