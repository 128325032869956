<template>

</template>

<script>
import { useLoadStore } from "@/stores/load"
import customerDal from "@/dal/customer_dal"
import Swal from "sweetalert2"

export default {
  props: {
    accountId: Number,
    customerName: String,
    customerId: String
  },
  data(){
    return {
      storeLoad: useLoadStore(),
    }
  },
  name: "CustomerToUserGroup",
  mounted() {
    this.storeLoad.setVisible(true)
    customerDal.getCustomerEntities(this.customerId, this.accountId)
        .then(data => {
          let confirmHtml = '<div class="form-container">'

          data.entities.data.rows.forEach( entity => {
            confirmHtml += `<div class="form-check">
                <input class="form-check-input screen-checkbox" ${entity.is_connected ? 'checked': ''} type="checkbox" value="${entity.id}" id="checkbox-${entity.id}">
                <label class="form-check-label" for="checkbox-${entity.id}" style="display:flex" >${entity.entity_id} : ${entity.entity_name}</label>
            </div>`
          })
          confirmHtml += `</div>`

          Swal.fire({
            title: `${this.customerName} : connect to entities`,
            showCancelButton: true,
            confirmButtonText: 'Save',
            html: confirmHtml,
            focusConfirm: true,
            preConfirm: () => {
              const divContainer = Swal.getPopup().querySelector('.form-container') //('.form-check-input')
              const checkBoxes = divContainer.getElementsByClassName('form-check-input')
              const connectedEntities = []
              Array.from(checkBoxes).forEach(checkbox => {
                if(checkbox.checked){
                  connectedEntities.push(checkbox.value)
                }
              })
              return { connectedEntities: connectedEntities.join('#') }
            }
          }).then((result) => {
            if (result.isConfirmed) {
              customerDal.connectCustomersToEntity(this.customerId, this.accountId, result.value.connectedEntities)
                  .then(data => {
                    if (data.result === 'SUCCESS') {
                      Swal.fire({title: `Entities connected successfully to customer ${this.customerName}`, icon: 'success'})
                          .then(() => {
                            this.$emit('entitiesConnected')
                          })
                    } else {
                      Swal.fire({title: `Failed to connect entities`, icon: 'error'})
                          .then(() => {
                            this.$emit('closeConnectEntitiesModal')
                          })
                    }
                  })
              this.$emit('closeConnectEntitiesModal')
            }else{
              this.$emit('closeConnectEntitiesModal')
            }
          })

          this.storeLoad.setVisible(false)
        })
  },
  methods: {

  }
}
</script>

<style scoped>

</style>