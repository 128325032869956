<template>
<div>
  <div class="docz-component">
    <div class="table-area datatable" ></div>
  </div>
</div>
</template>

<script>
import { useLoadStore } from "@/stores/load"
import { useAuthStore } from "@/stores/auth"
import GlDal from "@/dal/gl_dal"
import $ from "jquery";
import Swal from "sweetalert2"
import SettingsGLUserLevels from "@/screens/Settings/SettingsGLUserLevels.vue"
import Vue from "vue";

export default {
  name: "GLAccountsV2",
  props: {
    entityId: Number
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      authStore: useAuthStore(),
      dataColumns: [],
      GlAccounts: [],
      GlAccountsConfigData: [],
      newRowAdded: false,
      financialReportNameOptions: [],
      groupNameOptions: [],
      accountNameOptions: [],
      ebitdaNameOptions: [],
      cashFlowNameOptions: [],
      userSubGroup1Options: [{id:0 , name: ''}],
      userSubGroup2Options: [{id:0 , name: ''}],
      table: null,
    }
  },
  mounted(){
    this.getAllAccounts()
  },
  methods: {
    getAllAccounts() {
      this.buttonsInitiated = false
      this.storeLoad.setVisible(true)
      GlDal.getGlAccountsConfigDataV2(this.entityId)
          .then((data) => {
            this.GlAccountsConfigData = data.data
            this.generateConfigOptions()

            GlDal.getGlAccountsV2(this.entityId)
                .then(data => {
                  this.GlAccounts = data.data

                  this.dataColumns = [
                    {title: 'GL ID', type: 'readonly', index: 0},
                    {title: 'GL Account Name', type: 'text',  alias: 'gl_account_name', index: 1},
                    {title: 'Financial Report Name', type: 'select', options: this.financialReportNameOptions, alias: 'financial_report_name', index: 2},
                    {title: 'Group Name', type: 'select', options: this.groupNameOptions, alias: 'group_name',  index: 3},
                    {title: 'Account Name', type: 'select',options: this.accountNameOptions, alias: 'account_name', index: 4},
                    {title: 'EBITDA Name', type: 'select', options: this.ebitdaNameOptions, alias: 'ebitda_name', index: 5},
                    {title: 'Cash Flow Name', type: 'select', options: this.cashFlowNameOptions, alias: 'cash_flow_name', index: 6},
                    {title: 'InterCo.', type: 'checkbox', alias: 'inter_company', index: 7},
                    {title: 'Level 1', type: 'select', options: this.userSubGroup1Options, alias: 'user_sub_group_1', index: 8},
                    {title: 'Level 2', type: 'select', options: this.userSubGroup2Options, alias: 'user_sub_group_2', index: 9},
                    {title: 'gl_account_id'},
                    {title: 'erp_account_type_id'},
                    {title: 'general_account_type_id'},
                    {title: 'financial_report_id'},
                    {title: 'ebitda_type_id'},
                    {title: 'cash_flow_type_id'},
                    {title: 'user_sub_group_1_type_id'},
                    {title: 'user_sub_group_2_type_id'},
                    {title: ''}
                  ]

                  let dataRows = []
                  this.GlAccounts.forEach(row => {
                    dataRows.push([row.gl_id, row.gl_account_name, row.financial_report_name, row.group_name, row.erp_account_name, row.ebitda_name, row.cash_flow_name,row.inter_company, row.user_sub_group_1_name, row.user_sub_group_2_name,
                      row.gl_account_id, row.erp_account_type_id, row.general_account_type_id, row.financial_report_id, row.ebitda_type_id, row.cash_flow_type_id, row.user_sub_group_1_type_id, row.user_sub_group_2_type_id])
                  })

                  let that = this
                  $(".datatable").html("")
                  $(".datatable").append('<table id="datatable" class="table table-hover table-striped non-editable" style="font-family: Arial"></table>')

                  this.table = $('#datatable').DataTable({
                    data: dataRows,
                    columns: this.dataColumns,
                    paging: false,
                    scrollCollapse: true,
                    scrollY: '600px',
                    pageLength: 40,
                    "order": [],
                    columnDefs: [
                      {
                        targets: -1,
                        width: "80px",
                        render: function (data, type, row) {
                          return `<button class="icon-btn grid-btn edit_btn" value="edit_account" mode="read" status_id="${row[11]}"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                        <button class="icon-btn grid-btn cancel_btn" style="visibility: hidden"><i class='fa fa-close' style='font-size: 16px; color: #dc0707' ></i></button>`;
                        },
                      },
                      {
                        targets: 7,
                        render: function (data, type, row) {

                          return data == '1' ? `<i class="fa fa-check" aria-hidden="true"></i>`: `<i class="fa fa-minus" aria-hidden="true"></i>`
                        }
                      },
                      {
                        targets: [ 10, 11, 12, 13, 14, 15, 16, 17],
                        visible: false,
                        searchable: false,
                      }
                    ],

                    drawCallback: function (settings) {
                      that.storeLoad.setVisible(false)
                      if (!that.buttonsInitiated) {
                        that.buttonsInitiated = true
                        that.initButtons(that.dataColumns)
                        that.addUnclassifiedCheckBox()
                      }
                    },

                    dom: "Blfrtip",
                    buttons: [
                      {
                        text: "Refresh",
                        action: function (e, dt, node, config) {
                          that.table.columns.adjust().draw()
                          // debugger

                        },
                      },
                      "copy",
                      "csv",
                      "print",
                      "excel",
                      {
                        text: "New GL Account",
                        action: function (e, dt, node, config) {
                          if (that.newRowAdded) {
                            Swal.fire("Please save the added row before you add a new one")
                          } else {
                            that.addGlAccount();
                          }
                        }
                      },
                      // {
                      //   text: "GL User Levels",
                      //   action: function (e, dt, node, config) {
                      //     that.manageUserLevels()
                      //   }
                      // }
                    ],
                    bDestroy: true,
                  })

                  this.storeLoad.setVisible(false)
                })
          })
    },

    initButtons(dataColumns) {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
        });
      });

      $(".datatable tbody").on("click", '.cancel_btn', function (event) {
        that.cancelChanges(this)
      })

      $(".datatable tbody").on('click', '.edit_btn', function () {
        let row = $(this).closest('tr')
        if ($(this).attr('mode') === 'read') {
          that.readTheData(row, dataColumns, this)
        } else {
          that.updateTheData(row, this)
        }
      })
    },

    readTheData(row, dataColumns, btn) {
      let that = this;
      row.find('td').each(function (index, cell) {

        let column = dataColumns.find(x => x.index == index)
        if (typeof column !== 'undefined' && column.type === 'text') {
          $(cell).attr('original_value', $(cell).html());
          that.addTextInput(cell, column.alias)
        }else if(typeof column !== 'undefined' && column.type === 'checkbox'){
          $(cell).attr('original_value', $(cell).html())
          that.addCheckBox(cell, column.alias)
        } else if (typeof column !== 'undefined' && column.type === 'select') {
          $(cell).attr('original_value', $(cell).html());
          that.addSelect(cell, column.options, column.alias)
        }
      });

      $(btn).html(`<i class='fa fa-check' style='font-size: 16px; color: #334249'></i>`)
      $(btn).attr('mode', 'edit')
      $(btn.nextElementSibling).css("opacity", "1")
      btn.nextElementSibling.style.visibility = 'visible'
      const buttons = document.querySelectorAll('button[mode="read"]')
      buttons.forEach(button => {
        button.disabled = true
        button.style.opacity = "0.5"
      })
      document.getElementsByClassName('dt-buttons')[0].getElementsByTagName('button')[4].style.display = 'none'

    },

    cancelChanges(btn) {
      let that = this;
      if (that.newRowAdded) {
        Swal.fire({
          title: 'Cancel new row',
          html: '<p>Are you sure? This will cancel all changes?</p>',
          icon: 'question',
          showCancelButton: true
        }).then(result => {
          if (result.isConfirmed) {
            that.table.row(':last').remove().draw(false)
            that.newRowAdded = false
            const buttons = document.querySelectorAll('button[mode="read"]')
            buttons.forEach(button => {
              button.disabled = false
              button.style.opacity = "1"
            })
            $('#datatable_filter').css("opacity", "1")
            $('#datatable_filter').css('visibility', 'visible')
            $("#dataFilterCb").prop('disabled', false)
          }
        })
      } else {
        Swal.fire({
          title: 'Cancel all changes',
          html: '<p>Are you sure? This will cancel all changes?</p>',
          icon: 'question',
          showCancelButton: true
        }).then(result => {
          if (result.isConfirmed) {
            let row = $(btn).closest('tr')

            row.find('td').each(function (index, cell) {
              let original_value = $(this).attr('original_value');
              if (typeof original_value !== 'undefined' && original_value !== false) {
                $(cell).html(original_value)
              }
            })

            $(btn.previousElementSibling).html(`<i class='fa fa-edit' style='font-size: 16px; color: #334249'></i>`)
            $(btn.previousElementSibling).attr('mode', 'read')
            $(btn).css("opacity", "0")
            $(btn).css("transition", "none")
            $(btn).css("visibility", "hidden")

            const buttons = document.querySelectorAll('button[mode="read"]')
            buttons.forEach(button => {
              button.disabled = false
              button.style.opacity = "1"
            })

            document.getElementsByClassName('dt-buttons')[0].getElementsByTagName('button')[4].style.display = ''

          }
        })
      }
    },

    updateTheData(row, btn) {
      let that = this
      const validation = that.validateGlAccount()
      if (validation.validationResult === '') {
        let id = row[0].cells[0].innerText
        Swal.fire({
          title: id === '' ? 'Add the new account' : 'Update the account',
          html: '<p>Please approve to save the changes</p>',
          icon: 'question',
          showCancelButton: true
        }).then(result => {
          if (result.isConfirmed) {
            that.storeLoad.setVisible(true)
            if(id === ''){
              GlDal.addUserDefinedGlAccountV2(that.authStore.getUserData.userId, that.entityId,4, 1, validation.account_name ,   validation.gl_account_name, validation.ebitda_name, validation.cash_flow_name, validation.user_sub_group_1_name == 0 ? null : validation.user_sub_group_1_name  , validation.user_sub_group_2_name == 0 ? null : validation.user_sub_group_2_name, validation.inter_company)
                  .then(data => {
                    that.manageGlAccount(data, id, row , btn)
                  })
            }else{
              GlDal.updateUserDefinedGlAccountV2(id, that.authStore.getUserData.userId, that.entityId,4, 1, validation.account_name ,   validation.gl_account_name, validation.ebitda_name, validation.cash_flow_name, validation.user_sub_group_1_name == 0 ? null : validation.user_sub_group_1_name  , validation.user_sub_group_2_name == 0 ? null : validation.user_sub_group_2_name, validation.inter_company)
                  .then(data => {
                    that.manageGlAccount(data, id, row , btn)
                  })
            }
          }
        })
      } else {
        Swal.fire({
          title: 'Validation Failed',
          html: `<p>${validation.validationResult}</p>`,
          icon: 'error'
        })
      }
    },

    manageGlAccount(data, id, row , btn){
      this.storeLoad.setVisible(false)
      this.newRowAdded = false

      if (data.result === 'SUCCESS') {
        Swal.fire(`${id === '' ? 'Add' : 'Update'} GL Account`, `The GL Account ${id === '' ? 'added' : 'updated'} successfully`, 'success')
            .then(() => {
              this.storeLoad.setVisible(false)
              this.updateGlRow(data , row , btn, id)
              this.$emit('newGlAccountAdded')
            })
      } else {
        Swal.fire(`${id === '' ? 'Add' : 'Update'} GL Account`, 'Action failed . Please contact your system administrator', 'error')
      }
    },

    updateGlRow(data , row , btn, id){
      row.find('td').each(function (index, cell) {
        if(id === '' && index === 0){
          $(cell).html('VI'+data.data[0].id)
        }
        if($(this).children().length > 0){
          let element = $(this).children()[0]

          if (element.tagName === 'INPUT' || element.tagName === 'SELECT'){
            if(element.type === 'checkbox'){
              $(cell).html(element.checked ?  `<i class="fa fa-check" aria-hidden="true"></i>`: `<i class="fa fa-minus" aria-hidden="true"></i>`)
            }else{
              let current_value  = element.tagName === 'INPUT' ? element.value : element.options[element.selectedIndex].text
              $(cell).html(current_value)
              $(cell).attr('original_value', current_value)
            }
          }
        }

      })

      $(btn).html(`<i class='fa fa-edit' style='font-size: 16px; color: #334249'></i>`)
      $(btn).attr('mode', 'read')
      $(btn.nextElementSibling).css("opacity", "0")
      $(btn.nextElementSibling).css("transition", "none")
      $(btn.nextElementSibling).css("visibility", "hidden")

      const buttons = document.querySelectorAll('button[mode="read"]')
      buttons.forEach(button => {
        button.disabled = false
        button.style.opacity = "1"
      })
      document.getElementsByClassName('dt-buttons')[0].getElementsByTagName('button')[4].style.display = ''

      row.parent()[0].removeChild(row[0])
    },

    addTextInput(cell, text_alias) {
      let textInput = document.createElement('input')
      textInput.type = 'text'
      textInput.id = text_alias
      textInput.value = cell.innerText
      $(cell).empty().append(textInput);
    },

    addSelect(cell, options, select_alias) {
      let that = this
      let select = document.createElement('select')
      $(select).css('height', '38px').css('border', '1px solid #cccccc')
      select.id = select_alias

      this.addOptionsToSelect(select, options, cell)

      $(cell).empty().append(select);
      select.addEventListener("change", function () {
        that.glSelectionChanged(this, select_alias)
      })
    },

    addCheckBox(cell,check_box_alias){
      let checkBox = document.createElement('input')
      checkBox.type = 'checkbox'
      checkBox.style.height = '38px'
      checkBox.style.marginTop = '5px'
      checkBox.id = check_box_alias
      checkBox.checked = $(cell).attr('original_value').includes('check')
      $(cell).empty().append(checkBox);
    },

    addOptionsToSelect(select, options, cell) {
      $(select).empty()
      $(select).append($('<option>', { value: 0, text: '' }))
      $.each(options, function (index, value) {
        let option = document.createElement("option")
        option.value = value.id
        option.text = value.name
        if(value.parent_object_id){
          select.setAttribute('parent_select_id',value.parent_object_id)
          option.setAttribute('parent_object_id',value.parent_object_id)
          option.setAttribute('parent_id', value.parent_id)
          option.setAttribute('parent_name', value.parent_name)
        }
        option.selected = option.text === cell.innerText
        if(value.parent_object_id){
          if($(`#${value.parent_object_id}`).val() == value.parent_id){
            $(select).append(option)
          }
        }else{
          $(select).append(option)
        }
      })
    },

    glSelectionChanged(select, select_alias){

      let childSelect = $.find(`[parent_select_id='${select_alias}']`)
      if(childSelect && childSelect.length === 1 && childSelect[0].id){
        let options = this.dataColumns.find(x => x.alias === childSelect[0].id).options
        this.addOptionsToSelect(childSelect[0], options, select.parentElement)
      }

      if(select.id === 'account_name'){
        let selectedAccount = this.findAccountByErpAccountTypeId(select.value)
        console.log(selectedAccount)
        if(selectedAccount){
          $("#ebitda_name").val(selectedAccount.ebitda_type_id)
          $("#ebitda_name").prop('disabled',!selectedAccount.ebitda_is_editable)
        }
      }
    },

    findAccountByErpAccountTypeId(erpAccountTypeId) {
      for(let classificationData of this.GlAccountsConfigData){
        for (let generalAccount of classificationData.classification_data.financial_report_data) {
          for (let account of generalAccount.general_account_data) {
            if (account.erp_account_type_id == erpAccountTypeId) {
              return account;
            }
          }
        }
      }
      return null;
    },

    generateConfigOptions(){
      this.groupNameOptions = []
      this.accountNameOptions = []
      this.ebitdaNameOptions =  []
      this.userSubGroup1Options = []
      this.userSubGroup2Options = []

      this.cashFlowNameOptions = this.GlAccountsConfigData[0].classification_data.cash_flow_types.map(x => {return {id: x.id , name: x.cash_flow_name, parent_object_id: null}})
      if(this.GlAccountsConfigData[0].classification_data.sub_group_1_types){
        this.userSubGroup1Options = this.userSubGroup1Options.concat(this.GlAccountsConfigData[0].classification_data.sub_group_1_types.map( x=> {return {id: x.id , name: x.user_sub_group_1_name, parent_object_id: null}}))
      }
      if(this.GlAccountsConfigData[0].classification_data.sub_group_2_types){
        this.userSubGroup2Options = this.userSubGroup2Options.concat(this.GlAccountsConfigData[0].classification_data.sub_group_2_types.map( x=> {return {id: x.id , name: x.user_sub_group_2_name, parent_object_id: null}}))
      }

      this.financialReportNameOptions = this.GlAccountsConfigData.map(x => {
        return {
          id: x.classification_data.financial_report_id,
          name: x.classification_data.financial_report_name,
          parent_object_id: null
        }
      })

      this.GlAccountsConfigData.forEach(report => {
        report.classification_data.financial_report_data.forEach(account => {
          this.groupNameOptions.push({
            parent_id: report.classification_data.financial_report_id,
            parent_name: report.classification_data.financial_report_name,
            id: account.general_account_type_id,
            name: account.general_account_name,
            parent_object_id: 'financial_report_name'
          });
        });
      });

      this.GlAccountsConfigData.forEach(report => {
        report.classification_data.financial_report_data.forEach(account => {
          account.general_account_data.forEach(data => {
            this.accountNameOptions.push({
              parent_id: account.general_account_type_id,
              parent_name: account.general_account_name,
              id: data.erp_account_type_id,
              name: data.erp_account_name,
              parent_object_id: 'group_name'
            });

            this.ebitdaNameOptions.push({
              parent_id: account.general_account_type_id,
              parent_name: account.general_account_name,
              id: data.ebitda_type_id,
              name: data.ebitda_name,
              parent_object_id: null //'account_name'
            })
          })

        });
      });
      this.ebitdaNameOptions = this.removeDuplicates(this.ebitdaNameOptions)
    },

    removeDuplicates(arr){
      const seen = new Set();
      return arr.filter((obj) => {
        const stringified = JSON.stringify({id: obj.id , name: obj.name})
        if (seen.has(stringified)) {
          return false
        }
        seen.add(stringified)
        return true
      })
    },

    addGlAccount(){
      this.newRowAdded = true

      $('#datatable_filter').css("opacity","0")
      $('#datatable_filter').css("transition","none")
      $('#datatable_filter').css('visibility', 'hidden')

      $("#dataFilterCb").prop('disabled', true)

      this.table.row.add([
        '', '' , '', '', '', '', '', '', '', '', '', '' , '', '', '', '' , '' , ''
      ]).draw(false)

      this.table.page('last').draw('page')

      let lastRow = this.table.rows().nodes().to$().last(); // Get the last row
      let lastCell = lastRow.find('td').last(); // Get the last cell of the last row
      let button = lastCell.find('button'); // Find the button in the last cell

      button[0].click()

      let $scrollBody = $(this.table.table().node()).parent();
      $scrollBody.scrollTop($scrollBody.get(0).scrollHeight);
    },

    manageUserLevels(){
      let that = this
      Swal.fire({
        title: 'GL User Levels Management',
        html: `<div id="user-levels-container"></div>`,
        customClass: 'swal-popup-wide',
        showCancelButton: true,
        didOpen(popup) {
          let userLevelsContainer = document.getElementById('user-levels-container')
          let UserLevelSetting = Vue.extend(
              Vue.component('user-level-setting', SettingsGLUserLevels)
          )
          let userLevelSettingInstance = new UserLevelSetting({propsData: {entityId: that.entityId} })
          userLevelSettingInstance.$mount()
          userLevelsContainer.appendChild(userLevelSettingInstance.$el)
        }
      })
    },


    validateGlAccount(){
      let validationResult = ''

      let gl_account_name = $('#gl_account_name').val()
      let financial_report_name = $('#financial_report_name').val()
      let group_name = $('#group_name').val()
      let account_name = $('#account_name').val()
      let ebitda_name = $('#ebitda_name').val()
      let cash_flow_name = $('#cash_flow_name').val()
      let user_sub_group_1_name = $("#user_sub_group_1").val()
      let user_sub_group_2_name = $("#user_sub_group_2").val()
      let inter_company = $("#inter_company").is(':checked') ? 1 : 0

      if(gl_account_name == ''){
        validationResult = "Please set GL account name"
      }

      if(financial_report_name == '0'){
        validationResult = "Please set financial report name"
      }

      if(group_name == '0'){
        validationResult = "Please select group name"
      }

      if(account_name == '0'){
        validationResult = "Please select account name"
      }

      if(ebitda_name == '0'){
        validationResult = "Please select EBITDA"
      }

      if(cash_flow_name == '0'){
        validationResult = "Please select cash flow name"
      }

      return {validationResult , gl_account_name, financial_report_name, group_name, account_name, ebitda_name, cash_flow_name, user_sub_group_1_name, user_sub_group_2_name, inter_company}
    },

    addUnclassifiedCheckBox(){
      let dtButtonsDiv = document.querySelector('.dt-buttons')
      let children = dtButtonsDiv.childNodes
      let cbAlreadyAdded = false

      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeName.toLowerCase() === 'input' && children[i].type.toLowerCase() === 'checkbox') {
          cbAlreadyAdded = true
        }
      }

      if(!cbAlreadyAdded){
        let checkbox = document.createElement('input');
        checkbox.type = 'checkbox'
        checkbox.style.width="25px"
        checkbox.id = "dataFilterCb"
        checkbox.checked = true

        let label = document.createElement('label')
        label.htmlFor = 'dataFilterCb'
        label.style.color = '#8d76ef'
        label.appendChild(document.createTextNode('Unclassified accounts'))

        dtButtonsDiv.appendChild(checkbox)
        dtButtonsDiv.appendChild(label)

        let table = $('#datatable').DataTable();
        table.column(3).search('^$', true, false ).draw(false);

        checkbox.addEventListener('click', function() {
          if (this.checked) {
            table.column(3).search('^$', true, false ).draw();
          } else {
            table.search('').columns().search('').draw();
          }
        })
      }
    },

    toggleMenu: function () {
      //this.table.draw()
      //this.table.buttons()[0].node.click()
      //this.table.columns.adjust().draw()

      // this.table.row.add([
      //   '', '' , '', '', '', '', '', '', '', '', '', '' , '', '', '', '' , ''
      // ]).draw(false)
      //
      // this.table.page('last').draw('page')
      //
      // let lastRow = this.table.rows().nodes().to$().last(); // Get the last row
      // let lastCell = lastRow.find('td').last(); // Get the last cell of the last row
      // let button = lastCell.find('button'); // Find the button in the last cell
      //
      // button[0].click()
    }
  }
}
</script>


<style scoped>

</style>