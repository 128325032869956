import {ApiDal} from './api_dal'

class BanksDal extends ApiDal{

    async getBankEtlTemplates(entityId , subMenuId){
        return this.callApi('post', '/api/banks/getBankEtlTemplates', null, {entityId , subMenuId})
    }

    async getBankUploadedFiles(entityId){
        return this.callApi('post', '/api/banks/getBankUploadedFiles', null, {entityId})
    }

    async getEntityBankAccounts(entityId){
        return this.callApi('post', '/api/banks/getEntityBankAccounts', null, {entityId})
    }

    async addEntityBankAccounts(entityId, bankId, branchId, currencyId, bankAccountId, accountAlias){
        return this.callApi('post', '/api/banks/addEntityBankAccounts', null, {entityId, bankId, branchId, currencyId, bankAccountId, accountAlias})
    }

    async deleteEntityBankAccounts(rowId){
        return this.callApi('post', '/api/banks/deleteEntityBankAccounts', null, {rowId})
    }

    async addBankUploadedFile(entityId, fileName, fileType, fileData, moduleName, selectedEtlId, selectedBankAccountId){
        return this.callApi('post', '/api/banks/addBankUploadedFile', null, {entityId, fileName, fileType, fileData, moduleName, selectedEtlId, selectedBankAccountId})
    }

    async uploadBankFiles(entityId, uploadFileData, moduleName, selectedEtlId, selectedBankAccountId){
        return this.callApi('post', '/api/banks/uploadBankFiles', null, {entityId, uploadFileData, moduleName, selectedEtlId, selectedBankAccountId})
    }

    async deleteBankUploadedFile(fileId , tableName){
        return this.callApi('post', '/api/banks/deleteBankUploadedFile', null, {fileId , tableName})
    }

    async getBankTransactionsData(entityId , bankAccountId, fromDate, toDate){
        return this.callApi('post', '/api/banks/getBankTransactionsData', null, {entityId , bankAccountId, fromDate, toDate})
    }

    async updateBankTransactionGlAccountId(transactionId, glAccountId){
        return this.callApi('post', '/api/banks/updateBankTransactionGlAccountId', null, {transactionId, glAccountId})
    }

    async updateBankTransactionStatusId(transactionId, statusId){
        return this.callApi('post', '/api/banks/updateBankTransactionStatusId', null, {transactionId, statusId})
    }

    async getBankBranches(){
        return this.callApi('post', '/api/banks/getBankBranches', null, {})
    }

}

export default new BanksDal()