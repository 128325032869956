<template>
  <div class="thePage">
<!--    {{subMenuItems}}-->
    <top-page
        :navigation-menu-is-toggled="navigationMenuIsToggled"
        :page-name=" subMenuItems ? subMenuItems.menu_name : 'Home'"
        :bread-crumbs-data="breadCrumbsData"
        @breadCrumbMenuElementClicked="breadCrumbMenuElementClicked"
        @breadCrumbSubMenuElementClicked="subMenuItemClicked"
        ref="topPage"
    ></top-page>
    <inner-nav ref="innerNav" :sub-menu-items="subMenuItems" @subMenuItemClicked="subMenuItemClicked"></inner-nav>

    <page-content
        ref="pageContent"
        :selected-page="selectedPage"
        :page-name=" subMenuItems ? subMenuItems.menu_name : 'Home'"
        @addBreadCrumb="addBreadCrumb"
        @newGlAccountAdded="recalculateGlAccounts"
    ></page-content>
  </div>
</template>

<script>
import InnerNav from "@/components/page/InnerNav.vue"
import PageContent from "@/components/page/PageContent.vue"
import TopPage from "@/components/page/TopPage.vue"
export default {
  name: 'MainPage',
  props: {
    subMenuItems: Object,
  },
  components: {
    TopPage,
    PageContent,
    InnerNav
  },
  data(){
    return {
      selectedPage: {text: '' , data: {name: ''}},
      breadCrumbsData: [{text : this.subMenuItems ? this.subMenuItems.menu_name : 'Home'}],
      navigationMenuIsToggled: false
    }
  },
  methods: {

    breadCrumbMenuElementClicked(args){
      this.$emit('breadCrumbMenuElementClicked', args)
      this.selectedPage = {text: '' , data: {name: ''}}
      this.breadCrumbsData = [{text : this.subMenuItems ? this.subMenuItems.menu_name : 'Home'}]
      this.$refs.innerNav.resetSelection()
    },

    subMenuItemClicked(args){
      let mainMenu = this.subMenuItems ? this.subMenuItems.menu_name : 'Home'

      if(args.sub_menu_id){
        this.selectedPage = args
        if( args.text !== '' && args.sub_menu_text !== ''){
          this.breadCrumbsData = [{ text: mainMenu , value: mainMenu} , {text: args.sub_menu_text , value: args.sub_menu_name}]
        }
      }else{
        this.selectedPage = this.subMenuItems.sub_menus.find(x => x.sub_menu_name === args)
        if (this.selectedPage){
          this.breadCrumbsData = [{ text: mainMenu , value: mainMenu} , {text: this.selectedPage.sub_menu_text , value: this.selectedPage.sub_menu_name}]
        }else{
          this.breadCrumbsData = [{ text: mainMenu , value: mainMenu}]
        }

        this.$refs.pageContent.resetPage(this.selectedPage)
      }
    },

    addBreadCrumb(args){
      this.breadCrumbsData.push({text: args , value: args})
    },

    toggleMenu(args){
      this.navigationMenuIsToggled = args
      this.$refs.pageContent.toggleMenu()
    },

    recalculateGlAccounts(){
      this.$refs.topPage.recalculateGlAccounts()
    }
  },
  watch:{
    'subMenuItems': function ( newVal, oldVal) {
      this.selectedPage = {text: '' , data: {name: ''}}
    }
  }
}
</script>

<style scoped>

</style>