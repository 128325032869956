
<template>
  <div id="swagger"></div>
</template>

<script>
// import SwaggerUI from 'swagger-ui'
// import 'swagger-ui/dist/swagger-ui.css';

export default {
  data() {
    return {

    }
  },
  mounted() {
    // const spec = require('../public/openapi.yaml');
    //
    // const ui = SwaggerUI({
    //   spec,
    //   dom_id: '#swagger',
    // });
    //
    // ui.initOAuth({
    //   appName: "Swagger UI Webpack Demo",
    //   // See https://demo.identityserver.io/ for configuration details.
    //   clientId: 'implicit'
    // });
  }
}
</script>

<style scoped>

</style>
