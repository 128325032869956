<template>

</template>

<script>
import Swal from "sweetalert2"
import EntityDal from "@/dal/entity_dal"
import CustomerDal from "@/dal/customer_dal"
import SuppliersDal from "@/dal/suppliers_dal";
import {useLoadStore} from "@/stores/load"
import {useAuthStore} from "@/stores/auth"

export default {
  name: "NewEntity",
  props: {
    companyVatNumber: String,
    userGroupId: Number,
    parentEntityId: Number,
    entityType: String
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      authStore: useAuthStore(),
      fetchedEntity: []
    }
  },
  mounted() {

    this.storeLoad.setVisible(true)
    EntityDal.getEntityDetails(this.companyVatNumber)
        .then(data => {

          if(data.entities.data.result.records.length === 0 ){
            Swal.fire(`No company found`)
            this.storeLoad.setVisible(false)
          }else{
            let fields = data.entities.data.result.fields
            let values = data.entities.data.result.records[0]
            let labels = ''
            fields.forEach((field , i)=>{
              //if(i >0){
                labels += `<label style="width: 100%; direction: rtl; text-align: right">${fields[i].id} : ${values[fields[i].id]}</label>`
                this.fetchedEntity.push(values[fields[i].id])
              // }

            })

            this.fetchedEntity.push(null) // accountId
            this.fetchedEntity.push(this.authStore.getUserData.userId)
            this.fetchedEntity.push(this.userGroupId)

            this.storeLoad.setVisible(false)
            Swal.fire({
              title: 'Company found',
              width: 650,
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Save',
              cancelButtonText: "Cancel",
              html : labels
            }).then( (result) => {
              if(result.isConfirmed){
                this.storeLoad.setVisible(true)
                this.fetchedEntity.shift()

                EntityDal.addEntity(this.fetchedEntity)
                    .then(data => {
                      if(this.parentEntityId){
                        if(this.entityType === 'customer'){
                          CustomerDal.connectCustomersToEntity(data.entities.data.id, this.authStore.getUserData.accountId, this.parentEntityId )
                              .then(data => {
                                this.storeLoad.setVisible(false)
                                if(data.result === 'SUCCESS'){
                                  this.$emit('newEntityAdded', {entityId:  data.data.rows[0] ? data.data.rows[0].id : null})
                                }else{
                                  Swal.fire('Failed to add new entity')
                                  this.$emit('closeNewEntityModal')
                                }
                              })
                        }else{
                          SuppliersDal.connectSuppliersToEntity(data.entities.data.id, this.parentEntityId, this.parentEntityId )
                              .then(data => {
                                this.storeLoad.setVisible(false)
                                if(data.result === 'SUCCESS'){
                                  this.$emit('newEntityAdded', {entityId: data.data.rows[0] ? data.data.rows[0].id : null})
                                }else{
                                  Swal.fire('Failed to add new entity')
                                  this.$emit('closeNewEntityModal')
                                }
                              })
                        }
                      }else{
                        this.storeLoad.setVisible(false)

                        if(data.result === 'SUCCESS'){
                          this.$emit('newEntityAdded', {entityId: data.entities.data.id})
                        }else{
                          Swal.fire('Failed to add new entity')
                          this.$emit('closeNewEntityModal')
                        }
                      }
                    })
              }
            })
          }
        })
  }
}
</script>

<style scoped>

</style>