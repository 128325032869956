
class Env {
    constructor() {
        this.serverName =  process.env.VUE_APP_ROOT_API
        this.port = process.env.VUE_APP_API_PORT
        this.protocol = process.env.VUE_APP_PROTOCOL
        this.version = require('../package.json').version
        this.openAiKey = process.env.VUE_APP_OPEN_AI_KEY
        this.environment = process.env.VUE_APP_ENV
    }

    getPortalUrl(){
        return `${this.protocol}://${this.serverName}:${this.port}`
    }

    getOpenAiKey(){
        return this.openAiKey
    }
}

export default new Env()

