import { defineStore } from "pinia"

export const useAuthStore = defineStore({
    id: 'authStore',
    state: () => ({
        authorised: null,
        userName: null,
        userData: null,
        appMode: null
    }),
    getters:{
        isAuthorised:(state) => state.authorised,
        getUserName:(state) => state.userName,
        getUserData:(state) => state.userData,
        getAppMode:(state) => state.appMode
    },
    actions:{
        setAuthorisation(loginStatus){
            this.authorised = loginStatus
        },
        setUserName(userName){
            this.userName = userName
        },
        setUserData(userData){
            this.userData = userData
        },
        setAppMode(appMode){
            this.appMode = appMode
        }
    }
})