<template>
  <div class="screens-list-div">
    <screen-to-dashboard ref="screen-to-dashboard" v-for="(screen,i) in screens" :screen="screen" :key="i" @reopen-dashboards-list="reopenDashboardsList"></screen-to-dashboard>
  </div>
</template>

<script>

import ScreenToDashboard from "@/components/usergroups/ScreenToDashboard.vue"
export default {
  name: 'UserGroupDashboards',
  props: {
    screens: Array
  },
  components: {
    ScreenToDashboard
  },
  methods:{
    saveConfiguration(){
      let screens = this.$refs["screen-to-dashboard"]
      let records = ''
      screens.forEach(screen => {
        let record = screen.getRecord()

        if(record.reportId && record.workspaceId){
          records += (records ==='' ? '': '#') + `${record.screenId}|${record.workspaceId}|${record.reportId}`
        }

      })
      return records
    },
    reopenDashboardsList(){
      this.$emit('reopen-dashboards-list')
    }
  }
}
</script>

<style scoped>
.screens-list-div{
  max-height: 600px;
  overflow: scroll;

}
</style>