<template>
  <div>
    <div class="docz-component">
      <div class="table-area">
        <table id="datatable" class="table table-hover non-editable"
        ></table>
      </div>
    </div>
  </div>
</template>

<script>
import BanksDal from "@/dal/banks_dal"
import {useLoadStore} from "@/stores/load"
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "BanksAccounts",
  props: {
    entityId: Number,
  },
  data() {
    return {
      storeLoad: useLoadStore(),
      allBankAccounts: [],
      allBanksAndBranches: []
    }
  },
  mounted() {
    this.getEntityBankAccounts()
  },

  methods: {
    getEntityBankAccounts() {
      let that = this;
      this.storeLoad.setVisible(true)
      BanksDal.getEntityBankAccounts(this.entityId)
          .then(data => {
            this.storeLoad.setVisible(false)
            this.allBankAccounts = data.data
            let dataColumns = [];
            let dataRows = [];

            data.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            })

            dataColumns.push({ title: "" })

            this.allBankAccounts.forEach((row) => {
              let dataRow = [];
              data.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            })

            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 10,
              //stateSave: true,
              "aaSorting": [],
              columnDefs: [
                {
                  targets: -1,
                  width: "80px",
                  render: function (data, type, row) {
                    return `<button class="icon-btn" value="edit"><i class='fa fa-edit' ></i></button>
                          <button class="icon-btn" value="delete"><i class='fa fa-trash' ></i></button>`;
                  },
                },
              ],
              drawCallback: function (settings) {
                that.initButtons()
              },
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: "New Account",
                  action: function (e, dt, node, config) {
                    that.proceedAction("add", 0);
                  },
                },
              ],
              bDestroy: true,
            });
          })
    },

    initButtons(){
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(event.target.tagName === "I" ? event.target.parentElement.value : event.target.value , event.target.closest("tr").cells[0].innerText)
          //that.proceedAction('delete', event.target.closest("tr").cells[0].innerText)
        });
      });
    },

    proceedAction(actionType, rowId){
      let that = this;

      if(actionType === "delete"){
        Swal.fire({
          title: "Delete bank account",
          html: "Are you sure you want to delete this account?",
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.storeLoad.setVisible(true)
            BanksDal.deleteEntityBankAccounts(rowId)
                .then(data => {
                  that.storeLoad.setVisible(false)
                  if(data.result === 'SUCCESS'){
                    if (data.result === 'SUCCESS') {
                      Swal.fire('Delete bank account', 'The bank account deleted successfully.', 'success')
                          .then(() => {
                            that.getEntityBankAccounts()
                          })
                    }else{
                      Swal.fire('Delete bank account', 'The bank account deletion failed', 'error')
                    }
                  }
                })
          }
        })
      }

      if(actionType === "add"){
        this.storeLoad.setVisible(true)
        BanksDal.getBankBranches().then(data => {
          this.allBanksAndBranches = data.data

          let countryOptions = '<option value="" disabled selected>--- Select a country name ---</option>'
          let bankOptions = '<option value="" disabled selected>--- Select a bank name ---</option>'
          let branchOptions = '<option value="" disabled selected>--- Select a branch ---</option>'
          let currencyOptions = '<option value="" disabled selected>--- Select a currency ---</option>'
          this.allBanksAndBranches.forEach(account => {
            countryOptions += '<option value="' + account.country_id + '">' + account.country_name + '</option>'
          })
          this.storeLoad.setVisible(false)

          Swal.fire({
            title: 'New Bank Account',
            html: `
                    <select id="countrySelect" class="swal2-select" style="width:80%; border: 1px solid #d9d9d9; border-radius: 3px; height: 52px;">
                      ${countryOptions}
                    </select>
                    <select disabled id="bankSelect" class="swal2-select" style="width:80%; border: 1px solid #d9d9d9; border-radius: 3px; height: 52px;">
                      ${bankOptions}
                    </select>
                    <input disabled list="branchSelect" type="text" id="branchInput" class="swal2-input" style="width:80%" placeholder="Branch Number ...">
                    <datalist  id="branchSelect" >
                      ${branchOptions}
                    </datalist>
                    <select disabled id="currencySelect" class="swal2-select" style="width:80%; border: 1px solid #d9d9d9; border-radius: 3px; height: 52px;">
                      ${currencyOptions}
                    </select>
                    <input  type="text" id="accountNumber" class="swal2-input" style="width:80%" placeholder="Account Number ...">
                  `,
            focusConfirm: false,
            showCancelButton: true,
            didOpen(popup) {
              let countrySelect = document.getElementById('countrySelect')
              countrySelect.addEventListener('change', e1 => {
                let bankSelect = document.getElementById('bankSelect')
                let selectedBanks = that.allBanksAndBranches.find(row => row.country_name === e1.target.options[e1.target.selectedIndex].text)
                $(bankSelect).find('option').not(':first').remove()
                selectedBanks.banks.forEach(bank => {
                  let opt = document.createElement('option')
                  opt.value = bank.bank_id
                  opt.text = `${bank.bank_name} (${bank.bank_code})`
                  bankSelect.appendChild(opt)
                })
                bankSelect.disabled = false

                bankSelect.addEventListener('change', e2 => {
                  let branchSelect = document.getElementById('branchSelect')
                  let currencySelect = document.getElementById('currencySelect')
                  const branchInput = document.getElementById('branchInput')
                  let selectedBranches = selectedBanks.banks.find(bank => bank.bank_id == e2.target.value )
                  $(branchSelect).find('option').not(':first').remove()

                  selectedBranches.branches.forEach(branch => {
                    let opt = document.createElement('option')
                    //opt.value = branch.branch_id
                    opt.setAttribute('branch_id', branch.branch_id)
                    opt.text = `${branch.branch_name} (${branch.branch_code})`
                    branchSelect.appendChild(opt)
                  })

                  selectedBranches.all_currencies.forEach(currency => {
                    let opt = document.createElement('option')
                    opt.text = currency.currency_iso_code
                    opt.value = currency.currency_id

                    currencySelect.appendChild(opt)
                  })

                  branchSelect.disabled = false
                  branchInput.disabled = false
                  currencySelect.disabled = false
                })

              })
            },
            preConfirm: () => {
              // Retrieve values from inputs

              const countrySelect = document.getElementById('countrySelect').value
              const bankSelect = document.getElementById('bankSelect').value
              const branchInput = document.getElementById('branchInput').value
              const branchSelect = document.getElementById('branchSelect')
              const selectedBranchOption = Array.from(branchSelect.options).find(option => option.value === branchInput);
              const selectedBranch = selectedBranchOption.getAttribute('branch_id')


              const currencySelect = document.getElementById('currencySelect').value
              const accountNumber = document.getElementById('accountNumber').value

              // Validate inputs
              if ( !countrySelect || !bankSelect || !selectedBranch || !currencySelect || !accountNumber ) {
                Swal.showValidationMessage('Please fill in all fields');
                return false;
              }

              // Return collected data
              return { bankId: bankSelect,branchId: selectedBranch, currencyId: currencySelect, bankAccountId: accountNumber , accountAlias: branchInput  };
            },
          }).then((result) => {
            if (result.isConfirmed) {
              console.log('Collected data:', result.value, this.entityId);
              that.storeLoad.setVisible(true)
              BanksDal.addEntityBankAccounts(this.entityId, result.value.bankId, result.value.branchId, result.value.currencyId, result.value.bankAccountId, result.value.accountAlias)
                  .then((data) => {
                    that.storeLoad.setVisible(false)
                    if (data.result === 'SUCCESS') {
                      Swal.fire('New bank account', 'The new bank account added successfully.', 'success')
                          .then(() => {
                            that.getEntityBankAccounts()
                          })
                    }else{
                      Swal.fire('New bank account', 'The new bank account adding failed', 'error')
                    }
                  })
            }
          });
        })
      }
    }
  }

}
</script>


<style scoped>

</style>