import {ApiDal} from './api_dal'

class WizardDal extends ApiDal{
    async getLineTypes(){
        return this.callApi('post', '/api/wizard/getLineTypes', null, {})
    }

    async getWizardTabs(){
        return this.callApi('post', '/api/wizard/getWizardTabs', null, {})
    }

    async GetWizardComponent(documentTypeId , componentId, componentValue ){
        return this.callApi('post', '/api/wizard/getWizardComponent', null, {documentTypeId , componentId, componentValue})
    }
}


export default new WizardDal()